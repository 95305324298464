import React from 'react';


function UserReportTable({ data, title }: UserReporTableProps) {
    return (
        <div className="row">
            {title && <h2>{title}</h2>}
            <div className="table-responsive">
                <table className="table table-view">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Bet ID</th>
                            <th>Particular</th>
                            <th>Credited</th>
                            <th>Debited</th>
                            <th>Commison</th>
                            <th>Name</th>
                            <th>Last Balance</th>
                            <th>Account Type</th>
                            <th>Created On</th>
                            <th>Updated On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {data.pendingWithdrawals.map(Pending_Withdrawal =>
													<tr key={Pending_Withdrawal._id}>
														<td>{Pending_Withdrawal._id}</td>
														<td>{Pending_Withdrawal.dp_id}</td>
														<td>{Pending_Withdrawal.amount}</td>
														<td>{Pending_Withdrawal.ifscCode}</td>
														<td>{Pending_Withdrawal.mobile}</td>
														<td>{Pending_Withdrawal.accountNo}</td>
														<td>{Pending_Withdrawal.accountHolderName}</td>
														<td>{Pending_Withdrawal.transactionId}</td>
														<td>{Pending_Withdrawal.status}</td>
														<td>{formatDate(Pending_Withdrawal.createdOn)}</td>
														<td>{formatDate(Pending_Withdrawal.updatedOn)}</td>
													</tr>)} */}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default UserReportTable

interface UserReporTableProps {
    data?: any,
    title?: String
}
