import { API_Handler } from "./API_Handler";
import { API_Response } from "../Types/API_Response";
import { encryptData } from "../utils/encryptData";
import { User_Authentication_Details } from "../Types/User";

export const User_API = 
{
    
    Get_Users: async () => await API_Handler.request ({
        url:'/User/getAll',
        method: 'get',
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
    }) as API_Response,

    Log_In: async (Request: User_Authentication_Details) => await API_Handler.post ('/SubAdmin/log-in', {token: encryptData (Request)}) as API_Response
}