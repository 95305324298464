import React, { ChangeEvent, ReactNode } from 'react';
import Select from '@material-ui/core/Select';
import { MenuItem, FormControl } from "@material-ui/core";

interface CustomSelectProps {
    label: string;
    value: number;
    onChange: (value: number) => void;
    options: { value: number; label: string }[];
}

const CustomSelect: React.FC<CustomSelectProps> = ({ label, value, onChange, options }) => {
    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        const selectedValue = event.target.value as number;
        onChange(selectedValue);
    };

    return (
        <FormControl variant="outlined" fullWidth size="small">
            <Select className='mt-1' label={label} value={value} onChange={handleChange}>
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CustomSelect;
