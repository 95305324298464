import React from 'react'
import "./FundRequest.css"
import { Card, CardContent, Grid } from "@mui/material";
import { dateTime } from '../../utils/utility';
import { useNavigate } from "react-router-dom";


function FundReqestCard({ id, startDate, endDate, allDataBtn, depositRejectedCountIn, depositRejectedAmountIn, sattaMatkaDebitCount, sattaMatkaDebit, sattaMatkaCreditCount, sattaMatkaCredit, qtechDebitCount, qtechDebit, qtechCreditCount, qtechCredit, manualDebitCount, manualDebit, manualCredit, manualCreditCount, otherDebitCount, otherCreditCount, falconDebitCount, falconCreditCount, depositFailureDebitCount, depositFailureCreditCount, scannerDepositDebitCount, scannerDepositCreditCount, bonusDebitCount, bonusCreditCount, wacDebitCount, wacCreditCount, casinoDebitCount, casinoCreditCount, exchangeDebitCount, exchangeCreditCount, otherDebit, scannerDepositDebit, depositFailureDebit, otherCredit, falconCredit, wacDebit, wacCredit, casinoDebit, casinoCredit, exchangeDebit, exchangeCredit, depositFailureCredit, bonusCredit, bonusDebit, falconDebit, name, credited, debited, totalApprovedAmount, totalApprovedAmoun, totalApprovedCount, scannerDeposit, totalOnholdAmount, totalOnholdCount, totalRejectedAmount, totalRejectedCount, totalReversedAmount, totalReversedCount }: any) {
    const Navigate = useNavigate();

    const handleClick = (e?: any, Reason?: string, Tag?: string) => {
        let data = {}
        if (startDate && endDate) {
            data = {
                startDate: startDate,
                endDate: endDate,
                reason: Reason,
                tag: Tag,
                userId: id
            };
        } else if (allDataBtn === true) {
            data = {
                reason: Reason,
                tag: Tag,
                userId: id
            }
        } else {
            // const currentDate = new Date().toISOString().split('T')[0];
            const currentDate = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0];
            data = {
                startDate: dateTime(currentDate),
                endDate: dateTime(currentDate),
                reason: Reason,
                tag: Tag,
                userId: id
            };
        }
        Navigate("/fundreq-table", { state: data });
    };

    const handleClickCoins = (e?: any, type?: string, status?: string, includeUpdatedById?: boolean) => {
        let data = {}
        const filter: any = {
            status: status
        };
        if (includeUpdatedById) {
            filter["updatedBy._id"] = id;
        }
        if (startDate && endDate) {
            data = {
                startDate: startDate,
                endDate: endDate,
                type: type,
                itemsPerPage: 10,
                filter: filter
            };
        } else if (allDataBtn === true) {
            data = {
                type: type,
                itemsPerPage: 10,
                filter: filter
            }
        } else {
            // const currentDate = new Date().toISOString().split('T')[0];
            const currentDate = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0];
            data = {
                startDate: dateTime(currentDate),
                endDate: dateTime(currentDate),
                type: type,
                itemsPerPage: 10,
                filter: filter
            };
        }
        Navigate("/fundreq-coin", { state: data });
    };
    return (
        <Grid xs={12} sm={6}>
            <Card className='card-fr'>
                <CardContent className="content-bet">
                    <div className="bethis-card mt-1">
                        <b className="text-bethis">{name}</b>
                    </div>
                    <div className="bet-content-container">
                        {totalApprovedAmount ? totalApprovedAmount && (
                            <div className='mt-1' onClick={(e) => handleClickCoins(e, "withdrawal", "Approved", true)}>
                                <p className='bet-market-name'>{`Withdrawal Approved (${totalApprovedCount}) : ${totalApprovedAmount}`}</p>
                            </div>
                        ) : ''}
                        {totalOnholdAmount ? totalOnholdAmount && (
                            <div className='mt-1' onClick={(e) => handleClickCoins(e, "withdrawal", "on hold", true)}>
                                <p className='bet-market-name'>{`Withdrawal On Hold (${totalOnholdCount}) : ${totalOnholdAmount}`}</p>
                            </div>
                        ) : ''}
                        {totalRejectedAmount ? totalRejectedAmount && (
                            <div className='mt-1' onClick={(e) => handleClickCoins(e, "withdrawal", "Rejected", true)}>
                                <p className='bet-market-name'>{`Withdrawal Rejected  (${totalRejectedCount}) : ${totalRejectedAmount} `}</p>
                            </div>
                        ) : ''}
                        {totalReversedAmount ? totalReversedAmount && (

                            <div className='mt-1' onClick={(e) => handleClickCoins(e, "withdrawal", "Reverse", true)}>
                                <p className='bet-market-name'>{`Withdrawal Reversed (${totalReversedCount}) : ${totalReversedAmount}`}</p>
                            </div>
                        ) : ''}
                        {depositRejectedAmountIn ? depositRejectedAmountIn && (
                            <div className='mt-1' onClick={(e) => handleClickCoins(e, "deposit", "Rejected", true)}>
                                <p className='bet-market-name'>{`Deposit Rejected (${depositRejectedCountIn}) : ${depositRejectedAmountIn}`}</p>
                            </div>
                        ) : ''}
                        {scannerDeposit ? scannerDeposit && (

                            <div className='mt-1' onClick={(e) => handleClick(e, "Scanner Deposit", "credit")}>
                                <p className='bet-market-name'>{`Scanner Deposit (${scannerDepositCreditCount}) : ${scannerDeposit}`}</p>
                            </div>
                        ) : ''}
                        {scannerDepositDebit ? scannerDepositDebit && (

                            <div className='mt-1' onClick={(e) => handleClick(e, "Scanner Deposit", "debit")}>
                                <p className='bet-market-name'>{`Scanner Pull (${scannerDepositDebitCount}) : ${scannerDepositDebit}`}</p>
                            </div>
                        ) : ''}
                        {falconCredit ? falconCredit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Falcon", "credit")}>
                                <p className='bet-market-name'>{`Falcon Deposit (${falconCreditCount}) : ${falconCredit}`}</p>
                            </div>
                        ) : ''}
                        {falconDebit ? falconDebit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Falcon", "debit")}>
                                <p className='bet-market-name'>{`Falcon Pull (${falconDebitCount}) : ${falconDebit}`}</p>
                            </div>
                        ) : ''}
                        {wacCredit ? wacCredit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Wac", "credit")}>
                                <p className='bet-market-name'>{`Wac Deposit (${wacCreditCount}) : ${wacCredit}`}</p>
                            </div>
                        ) : ''}
                        {wacDebit ? wacDebit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Wac", "debit")}>
                                <p className='bet-market-name'>{`Wac Pull (${wacDebitCount}) : ${wacDebit}`}</p>
                            </div>
                        ) : ''}
                        {casinoCredit ? casinoCredit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Casino", "credit")}>
                                <p className='bet-market-name'>{`Casino Deposit (${casinoCreditCount}) : ${casinoCredit}`}</p>
                            </div>
                        ) : ''}
                        {casinoDebit ? casinoDebit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Casino", "debit")}>
                                <p className='bet-market-name'>{`Casino Pull (${casinoDebitCount}) : ${casinoDebit}`}</p>
                            </div>
                        ) : ''}
                        {exchangeCredit ? exchangeCredit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Exchange", "credit")}>
                                <p className='bet-market-name'>{`Jetfair Deposit (${exchangeCreditCount}) : ${exchangeCredit}`}</p>
                            </div>
                        ) : ''}
                        {exchangeDebit ? exchangeDebit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Exchange", "debit")}>
                                <p className='bet-market-name'>{`Jetfair Pull (${exchangeDebitCount}) : ${exchangeDebit}`}</p>
                            </div>
                        ) : ''}
                        {sattaMatkaCredit ? sattaMatkaCredit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Satta Matka", "credit")}>
                                <p className='bet-market-name'>{`Satta Matka Deposit (${sattaMatkaCreditCount}) : ${sattaMatkaCredit}`}</p>
                            </div>
                        ) : ''}
                        {sattaMatkaDebit ? sattaMatkaDebit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Satta Matka", "debit")}>
                                <p className='bet-market-name'>{`Satta Matka Pull (${sattaMatkaDebitCount}) : ${sattaMatkaDebit}`}</p>
                            </div>
                        ) : ''}
                        {depositFailureCredit ? depositFailureCredit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Deposit Failure", "credit")}>
                                <p className='bet-market-name'>{`Deposit Approved (${depositFailureCreditCount}) : ${depositFailureCredit}`}</p>
                            </div>
                        ) : ''}
                        {/* {depositFailureDebit ? depositFailureDebit && (
                            <div className='mt-1'>
                                <p className='bet-market-name'>{`Deposit Failure Debit (${depositFailureDebitCount}) : ${depositFailureDebit}`}</p>
                            </div>
                        ) : ''} */}
                        {/* {credited ? credited && (
                            <div className='mt-1 d-flex'>
                                <p className='bet-market-name'>{`Total Bonus Credited : ${credited}`}</p>
                            </div>
                        ) : ''}
                        {debited ? debited && (
                            <div className='mt-1'>
                                <p className='bet-market-name'>{`Total Bonus Debited : ${debited}`}</p>
                            </div>
                        ) : ''} */}
                        {bonusCredit ? bonusCredit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Bonus", "credit")}>
                                <p className='bet-market-name'>{`Bonus Given (${bonusCreditCount}) : ${bonusCredit}`}</p>
                            </div>
                        ) : ''}
                        {bonusDebit ? bonusDebit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Bonus", "debit")}>
                                <p className='bet-market-name'>{`Bonus Pull (${bonusDebitCount}) : ${bonusDebit}`}</p>
                            </div>
                        ) : ''}
                        {manualCredit ? manualCredit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Manual", "credit")}>
                                <p className='bet-market-name'>{`Manual Deposit (${manualCreditCount}) : ${manualCredit}`}</p>
                            </div>
                        ) : ''}
                        {manualDebit ? manualDebit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Manual", "debit")}>
                                <p className='bet-market-name'>{`Manual Pull (${manualDebitCount}) : ${manualDebit}`}</p>
                            </div>
                        ) : ''}
                        {qtechCredit ? qtechCredit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Qtech", "credit")}>
                                <p className='bet-market-name'>{`Qtech Deposit (${qtechCreditCount}) : ${qtechCredit}`}</p>
                            </div>
                        ) : ''}
                        {qtechDebit ? qtechDebit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Qtech", "debit")}>
                                <p className='bet-market-name'>{`Qtech Pull (${qtechDebitCount}) : ${qtechDebit}`}</p>
                            </div>
                        ) : ''}
                        {otherCredit ? otherCredit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Other", "credit")}>
                                <p className='bet-market-name'>{`Other Deposit (${otherCreditCount}) : ${otherCredit}`}</p>
                            </div>
                        ) : ''}
                        {otherDebit ? otherDebit && (
                            <div className='mt-1' onClick={(e) => handleClick(e, "Other", "debit")}>
                                <p className='bet-market-name'>{`Other Pull (${otherDebitCount}) : ${otherDebit}`}</p>
                            </div>
                        ) : ''}
                    </div>
                </CardContent>
            </Card >
        </Grid>

    )
}

export default FundReqestCard