import React, { useState, useEffect, useContext } from 'react'
import Sidenav from '../SideNavigation/SideNavigation'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import { API_Endpoint } from '../../Configuration/Settings'
import { encryptData } from '../../utils/encryptData'
import { User_Context } from '../../Contexts/User'
import axios from 'axios'
import { decryptData } from '../../utils/decryptData'
import { useLocation } from 'react-router-dom';
import { formatedTime } from '../../utils/utility'
import { formatDate } from '../../utils/utility'
import { Pagination, Stack } from "@mui/material";
import Stateful_Select from '../Dropdown/Dropdown'



interface Deposit {
    userName: string;
    _id: string;
    balance: string;
    utr: string;
    reason: string;
    remark: string;
    tag: string;
    createdOn: string;
    email: string;
    mobile: string;
    updatedBy: {
        name: string
    }
}

function FundRequestTable() {
    const { User } = useContext(User_Context)
    const location = useLocation();
    const customProps = location.state;
    const [fundReqData, setFundReqData] = useState<Deposit[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState<string>("10");

    const handlePerPage = (newValue: any) => {
        setItemsPerPage(newValue)
    }

    useEffect(() => {
        let filters: { reason?: string, tag?: string, startDate?: string, endDate?: string, userId?: string } = {};

        if (customProps.userId) {
            filters.userId = customProps.userId;
        }
        if (customProps.reason) {
            filters.reason = customProps.reason;
        }
        if (customProps.tag) {
            filters.tag = customProps.tag;
        }
        if (customProps.startDate) {
            filters.startDate = customProps.startDate;
        }
        if (customProps.endDate) {
            filters.endDate = customProps.endDate;
        }

        let data = {
            pageNo: currentPage,
            itemPerPage: parseInt(itemsPerPage),
            filters: filters
        };
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/coin/detail`,
            headers: {
                Authorization: `Bearer ${User.token}`,
            },
            data: { token: encryptData(data) },
        };
        axios
            .request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data)
                setTotalPages(response.data.data.payload.totalPages)
                setFundReqData(response.data.data.payload.items)
            })
            .catch((error: any) => {
                console.log(error.message);
            });
    }, [currentPage, itemsPerPage])

    return (
        <div className="g-sidenav-show  bg-gray-100">
            <Sidenav />
            <main className="main-content position-relative">
                <div style={{ background: "#f8f9fa" }}>
                    <Breadcrumbs tab={"User Data"} />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="row tp-form">
                                <div className="col-6 col-xl-2 col-sm-4 pdrt mb-2">
                                    <Stateful_Select
                                        label="Items Per Page"
                                        value={itemsPerPage.toString()}
                                        onChange={(newValue: any) => handlePerPage(newValue)}
                                        options={["10", "25", "50", "75", "100"]}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table className="table table-view">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>User Name</th>
                                                <th>Email</th>
                                                <th>Mobile No</th>
                                                <th>Amount</th>
                                                <th>UTR</th>
                                                <th>Reason</th>
                                                <th>Remark</th>
                                                <th>Tag</th>
                                                <th>Given By</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fundReqData?.map((item, index) => (
                                                <tr id={item._id}>
                                                    <td>{index + 1 + (currentPage && itemsPerPage  ? (currentPage - 1)  * parseInt(itemsPerPage) : 0)}</td>
                                                    <td onClick={() => {
                                                        const url = `/user-report/${item._id}/${item.userName}`;
                                                        window.open(url, "_blank");
                                                    }} style={{ cursor: 'pointer' }}>{item?.userName}</td>
                                                    <td>{item?.email}</td>
                                                    <td>{item?.mobile}</td>
                                                    <td>{item.balance}</td>
                                                    <td>{item.utr}</td>
                                                    <td>{item.reason}</td>
                                                    <td>{item.remark}</td>
                                                    <td>{item.tag === "credit" ? "Deposit" : "Debit"}</td>
                                                    <td>{item.updatedBy.name}</td>
                                                    <td>{`${formatDate(item.createdOn)} ${formatedTime(item.createdOn)}`}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                                <ul className="pagination d-flex justify-content-center">
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={totalPages}
                                            color="secondary"
                                            page={currentPage}
                                            onChange={(Event, New_Page) =>
                                                setCurrentPage(New_Page)
                                            }
                                        />
                                    </Stack>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default FundRequestTable