import React, { useContext, useEffect, useState } from 'react'
import Loader from '../../../../Components/Loader/Loader'
import { ToastContainer, toast } from 'react-toastify'
import { API_Endpoint } from '../../../../Configuration/Settings';
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TableFilterDatePicker from '../../../../Components/TableFilters/TableFilterDatePicker';
import TableFiltersSelectBox from '../../../../Components/TableFilters/TableFiltersSelectBox';
import { Pagination, Stack } from '@mui/material';
import { dateTime, formatDate, formatedTime } from '../../../../utils/utility';
import axios from 'axios';
import { User_Context } from '../../../../Contexts/User';
import { useParams } from 'react-router-dom';
import { decryptData } from '../../../../utils/decryptData';
import { encryptData } from '../../../../utils/encryptData';
import { filter } from 'lodash';
import { Console } from 'console';
import "./FundRequestHistory.css"
const FundRequestHistory = () => {
  const [selectedType, setSelectedType] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const [historyData, setHistoryData] = useState([]);
  const typeArray = [{ id: 'P', value: 'Pending' }, { id: 'W', value: 'Win' }, { id: 'L', value: 'Loss' }]
  const { User_ID, userName } = useParams();


  const [searchType, setSearchType] = useState("deposit");

  const [date, setDate] = useState("");

  const [adminAllot, setAdminAllot] = useState("");
  const [paymentMedium, setPaymentMedium] = useState("");
  const [lastBalance, setLastBalance] = useState("");
  const { User } = useContext(User_Context)
  const [fundReqHistoryData, setFundReqHistoryData] = useState([])

  // declare states for the cois filter
  const [coinFilterBalance, setCoinFilterBalance] = useState("");
  const [coinFilterUserId, setCointFilterUserId] = useState("");
  const [coinFilterByReason, setCoinFilterByReason] = useState("");
  const [coinFilterByTag, setCoinFilterByTag] = useState("");
  const [coinFilterUpdatedByName, setCoinFilterUpdatedByName] = useState("");

  // search input states
  const [depositId, setDepositId] = useState("");
  const [dpid, setDpId] = useState("");
  const [depositFilterAmount, setDepositFilterAmount] = useState("");
  const [withdrawalFilterAmount, setWithdrawaltFilterAmount] = useState("");
  const [action, setAction] = useState("");
  const [orderId, setOrderId] = useState("");
  const [orderKeyId, setOrderKeyId] = useState("");
  const [paymentGatewayName, setPaymentGatewayName] = useState("");
  const [mid, setMid] = useState("");
  const [commissionAmount, setCommissionAmount] = useState("");
  const [mobile, setMobile] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [acountNo, setAcountNo] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [coinUserid, setCoinUserid] = useState<string>("");
  const [coinBalance, setCoinBalance] = useState<string>("");
  const [coinUpdateByName, setCoinUpdatedByName] = useState<string>("");
  const [coinReason, setCoinReason] = useState<string>("");
  const [coinTag, setCoinTag] = useState<string>("");
  const [coinTime, setCoinTime] = useState<string>("");

  type payload = {
    customer_id?: string, amount?: number, status?: string,
    transaction_id?: string, bazar_name?: string, result_date?: any,
    game_name?: string, point?: string, game?: string, transactionId?: string,
    game_id?: string, time?: string, itemsPerPage?: number, mobile?: string,
    dp_id?: string, orderId?: string, acountNo?: string,
    winning_point?: string, bazar_id?: string, pageNo?: number, coinUserid?: string, coinBalance?: string,
    coinUpdateByName?: string, coinRemark?: string, tag?: string, coinDate?: string, coinTime?: string
    filterDeposit: { [key: string]: string | number }, type?: string, filterWithdrawal: { [key: string]: string | number },
    filterCoin: { [key: string]: string | number }
  }

  let Payload: payload;


  const onSearch = (e?: any) => {
  
    setLoading(true)


    if (searchType == "deposit") {
      Payload = {
        itemsPerPage: itemsPerPage,
        pageNo: currentPage,
        filterDeposit: {
          userId: User_ID?.toString() ?? '',

        },
        filterWithdrawal: { dp_id: User_ID?.toString() ?? "" },
        filterCoin: {
          userId: User_ID?.toString() ?? ""
        },
        type: "deposit",
      }

    }
    else if (searchType == "withdrawal") {
      Payload = {
        itemsPerPage: itemsPerPage,
        pageNo: currentPage,

        type: "withdrawal",
        filterWithdrawal: {
          dp_id: User_ID?.toString() ?? "",
        },
        filterDeposit: { userId: User_ID?.toString() ?? '' },
        filterCoin: {
          userId: User_ID?.toString() ?? ""
        }
      }

    } else if (searchType === "coins") {
      Payload = {
        itemsPerPage: itemsPerPage,
        pageNo: currentPage,

        type: "coin",
        filterWithdrawal: {
          dp_id: User_ID?.toString() ?? "",
        },
        filterDeposit: { userId: User_ID?.toString() ?? '' },
        filterCoin: {
          userId: User_ID?.toString() ?? ""
        },
      }
    } else {
      Payload = {
        itemsPerPage: itemsPerPage,
        pageNo: currentPage,
        filterDeposit: {
          userId: User_ID?.toString() ?? ''
        },
        type: "deposit",
        filterWithdrawal: { dp_id: User.data.DP_ID?.toString() ?? '' },
        filterCoin: {
          userId: User_ID?.toString() ?? ""
        }
      }
    }

    // set filter properties for the payment type deposit
    if (depositId) {
      Payload.filterDeposit["userId"] = depositId
    }

    if (orderId) {
      Payload.filterDeposit["orderId"] = orderId;
    }

    if (depositFilterAmount) {
      Payload.filterDeposit["amount"] = depositFilterAmount;
    }
    if (orderKeyId) {
      Payload.filterDeposit["orderKeyId"] = orderKeyId
    }

    if (paymentGatewayName) {
      Payload.filterDeposit['paymentGatewayName'] = paymentGatewayName;
    }

    if (mid) {
      Payload.filterDeposit["mid"] = mid;
    }

    // set filter properties for the payment type withdraw
    if (dpid) {
      Payload.filterWithdrawal["dp_id"] = dpid;
    }
    if (withdrawalFilterAmount) {
      Payload.filterWithdrawal["amount"] = withdrawalFilterAmount;
    }
    if (selectedStatus) {
      Payload.filterWithdrawal["status"] = selectedStatus;
    }

    if (transactionId) {
      Payload.filterWithdrawal["transactionId"] = transactionId;
    }

   
    if( coinFilterBalance){
      Payload.filterCoin["balance"] = coinFilterBalance;
    }

    if( coinFilterByReason){
      Payload.filterCoin["reason"] = coinFilterByReason;
    }

    if( coinFilterByTag){
      Payload.filterCoin["tag"] = coinFilterByTag;
    }
    





    let config = {
      method: "post",
      url: `${API_Endpoint}/User/get-transaction-history`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${User.token}`,
      },
      data: {
        token: encryptData(Payload)
      },
    };

  
    axios.request(config)

      .then((response) => {
        response.data.data = decryptData(response.data.data);
        setFundReqHistoryData(response.data.data.payload.items)
        setLoading(false)
      }).catch((error) => {
        toast.error(error.response.data.message)
        setLoading(false)
      });
  }


  useEffect(() => {
    onSearch();
  }, [searchType, selectedStatus,coinFilterByTag])



  const renderTableHeader = (searchType: string) => {
    switch (searchType) {
      case "withdrawal":
        return (
          <>

            <th>Payment Type</th>
            <th>dp_id</th>
            <th>amount</th>
            <th>status</th>
            <th>TransactionId</th>
            <th>mobile</th>

            <th>accountNo</th>
            <th>accountHolderName</th>
            <th>orderId</th>
            <th>IfscCode</th>
            <th>userBankName</th>
            <th>bankName</th>
            <th>withdrewalProviderName</th>
            <th>CommissionAmount</th>
            <th>Date</th>
            <th>Time</th>

          </>
        )
      case "deposit":
        return (
          <>
            <th>Payment Type</th>
            <th>amount</th>
            <th>Order Id</th>
            <th>Order Key Id</th>
            <th>Payment Gateway Name</th>
            <th>Mid</th>
            <th>User Name</th>
            <th>Status</th>
            <th>User Email</th>
            <th>User Mobile</th>
            <th>User City</th>
            <th>User State</th>
            <th>Latitude</th>
            <th>Longitude</th>
            <th>Updated By</th>
            <th>Date</th>
            <th>Time</th>
          </>
        );
      case "coins":
        return (
          <>
            <th>Payment Type</th>
            <th>userId</th>
            <th>balance</th>
            <th>updatedBy Name</th>
            <th>reason</th>
            <th>tag</th>
            <th>remark</th>
            <th>date</th>
            <th>time</th>
          </>
        );
      default:
        return <th>Id</th>; // Default case if searchType does not match any case
    }
  };


  return <>

    {loading && (<Loader />)}
    <div className="g-sidenav-show bg-gray-100">
      <ToastContainer autoClose={2000} position="top-center" />
      <main>
        <div style={{ background: "#f8f9fa" }}>
          <div className="container-fluid-starline">
            <div className="row">
              <div className="col-12 mt-4">
                <div className="table-responsive">
                  <table className="table table-view">
                    <thead>
                      <tr>
                        {renderTableHeader(searchType)}
                      </tr>
                    </thead>
                    <thead>
                      <tr>

                        <th>
                          <div className='iconbtn-div'>
                            <select style={{ height: "27px" }} onChange={(e) => setSearchType(e.target.value)} name="type" id="">
                              {/* <option value={searchType}>Select</option> */}
                              <option value={"deposit"}>deposit</option>
                              <option value={"withdrawal"}>withdrawal</option>
                              <option value={"coins"}>coins</option>
                            </select>

                            <IconButton
                              onClick={onSearch}
                              type="button"
                              sx={{ p: '10px 0px' }}
                              aria-label="search"
                              className='icon-button-2'
                            >
                              <SearchIcon />
                            </IconButton>
                          </div>
                        </th>

                        {/* for payment type withdrawal filter fileds --------------------- */}


                        {searchType === "withdrawal" && <th>
                          <div className='iconbtn-div'>
                            <input value={dpid} onChange={(e) => setDpId(e.target.value)} placeholder="dp_id" />
                            <IconButton
                              onClick={onSearch}
                              type="button"
                              sx={{ p: '10px 0px' }}
                              aria-label="search"
                              className='icon-button-2'
                            >
                              <SearchIcon />
                            </IconButton>
                          </div>
                        </th>
                        }
                        {
                          searchType === "withdrawal" &&

                          <th>
                            <div className='iconbtn-div'>
                              <input value={withdrawalFilterAmount} onChange={(e) => setWithdrawaltFilterAmount(e.target.value)} placeholder="amount" />
                              <IconButton
                                onClick={onSearch}
                                type="button"
                                sx={{ p: '10px 0px' }}
                                aria-label="search"
                                className='icon-button-2'
                              >
                                <SearchIcon />
                              </IconButton>
                            </div>
                          </th>
                        }

                        {
                          searchType === "withdrawal" &&
                          <th>
                            <div className='iconbtn-div'>
                              <select style={{ height: "27px" }} onChange={(e) => setSelectedStatus(e.target.value)} name="type" id="">
                                <option value="">Select</option>
                                <option value={"pending"}>Pending</option>
                                <option value={"win"}>Win</option>
                                <option value={"loss"}>Loss</option>
                              </select>

                              <IconButton
                                onClick={onSearch}
                                type="button"
                                sx={{ p: '10px 0px' }}
                                aria-label="search"
                                className='icon-button-2'
                              >
                                <SearchIcon />
                              </IconButton>
                            </div>
                          </th>

                        }

                        {
                          searchType === "withdrawal" &&
                          <th>
                            <div className='iconbtn-div'>
                              <input value={transactionId} onChange={(e) => setTransactionId(e.target.value)} placeholder="transactionId" />
                              <IconButton
                                onClick={onSearch}
                                type="button"
                                sx={{ p: '10px 0px' }}
                                aria-label="search"
                                className='icon-button-2'
                              >
                                <SearchIcon />
                              </IconButton>
                            </div>
                          </th>

                        }


                        {
                          searchType === "withdrawal" &&
                          <th>
                            {/* <div className='iconbtn-div'>
                              <input value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder="Mobile" />
                              <IconButton
                                onClick={onSearch}
                                type="button"
                                sx={{ p: '10px 0px' }}
                                aria-label="search"
                                className='icon-button-2'
                              >
                                <SearchIcon />
                              </IconButton>
                            </div> */}
                          </th>
                        }

                        {
                          searchType === "withdrawal" &&
                          <th>
                            {/* <div className='iconbtn-div'>
                              <input value={acountNo} onChange={(e) => setAcountNo(e.target.value)} placeholder="AccountNo" />
                              <IconButton
                                onClick={onSearch}
                                type="button"
                                sx={{ p: '10px 0px' }}
                                aria-label="search"
                                className='icon-button-2'
                              >
                                <SearchIcon />
                              </IconButton>
                            </div> */}
                          </th>
                        }

                        {/* end of withdrawal filter fields ---------------- */}




                        {/* on payment type is deposit filter fields --------------- */}
                        {
                          searchType === "deposit" &&
                          <th>
                            <div className='iconbtn-div'>
                              <input value={depositFilterAmount} onChange={(e) => setDepositFilterAmount(e.target.value)} placeholder="amount" />
                              <IconButton
                                onClick={onSearch}
                                type="button"
                                sx={{ p: '10px 0px' }}
                                aria-label="search"
                                className='icon-button-2'
                              >
                                <SearchIcon />
                              </IconButton>
                            </div>
                          </th>
                        }



                        {
                          searchType === "deposit" &&
                          <th>
                            <div className='iconbtn-div'>
                              <input value={orderId} onChange={(e) => setOrderId(e.target.value)} placeholder="Order Id" />
                              <IconButton
                                onClick={onSearch}
                                type="button"
                                sx={{ p: '10px 0px' }}
                                aria-label="search"
                                className='icon-button-2'
                              >
                                <SearchIcon />
                              </IconButton>
                            </div>
                          </th>
                        }



                        {
                          searchType === "deposit" &&
                          <th>
                            <div className='iconbtn-div'>
                              <input value={orderKeyId} onChange={(e) => setOrderKeyId(e.target.value)} placeholder="Order Key Id" />
                              <IconButton
                                onClick={onSearch}
                                type="button"
                                sx={{ p: '10px 0px' }}
                                aria-label="search"
                                className='icon-button-2'
                              >
                                <SearchIcon />
                              </IconButton>
                            </div>
                          </th>
                        }


                        {
                          searchType === "deposit" &&
                          <th>
                            <div className='iconbtn-div'>
                              <input value={paymentGatewayName} onChange={(e) => setPaymentGatewayName(e.target.value)} placeholder="Payment Gateway Name" />
                              <IconButton
                                onClick={onSearch}
                                type="button"
                                sx={{ p: '10px 0px' }}
                                aria-label="search"
                                className='icon-button-2'
                              >
                                <SearchIcon />
                              </IconButton>
                            </div>
                          </th>
                        }


                        {
                          searchType === "deposit" &&
                          <th>
                            <div className='iconbtn-div'>
                              <input value={mid} onChange={(e) => setMid(e.target.value)} placeholder="Mid" />
                              <IconButton
                                onClick={onSearch}
                                type="button"
                                sx={{ p: '10px 0px' }}
                                aria-label="search"
                                className='icon-button-2'
                              >
                                <SearchIcon />
                              </IconButton>
                            </div>
                          </th>
                        }



                        {/* Filter fields for the coins payment type */}

                        {/* {searchType === "coins" && <th>
                          <div className='iconbtn-div'>
                            <input value={coinFilterUserId} onChange={(e) => setCointFilterUserId(e.target.value)} placeholder="userid" />
                            <IconButton
                              onClick={onSearch}
                              type="button"
                              sx={{ p: '10px 0px' }}
                              aria-label="search"
                              className='icon-button-2'
                            >
                              <SearchIcon />
                            </IconButton>
                          </div>
                        </th>
                        } */}
                        <th></th>

                        {searchType === "coins" && <th>
                          <div className='iconbtn-div'>
                            <input value={coinFilterBalance} onChange={(e) => setCoinFilterBalance(e.target.value)} placeholder="balance" />
                            <IconButton
                              onClick={onSearch}
                              type="button"
                              sx={{ p: '10px 0px' }}
                              aria-label="search"
                              className='icon-button-2'
                            >
                              <SearchIcon />
                            </IconButton>
                          </div>
                        </th>
                        }

                        <th></th>

                        {/* {searchType === "coins" && <th>
                          <div className='iconbtn-div'>
                            <input value={coinFilterUpdatedByName} onChange={(e) => setCoinFilterUpdatedByName(e.target.value)} placeholder="updatedby Name" />
                            <IconButton
                              onClick={onSearch}
                              type="button"
                              sx={{ p: '10px 0px' }}
                              aria-label="search"
                              className='icon-button-2'
                            >
                              <SearchIcon />
                            </IconButton>
                          </div>
                        </th>
                        } */}
                        <th></th>

                        {/* {searchType === "coins" && <th>
                          <div className='iconbtn-div'>
                            <input value={coinFilterByReason} onChange={(e) => setCoinFilterByReason(e.target.value)} placeholder="by Reason" />
                            <IconButton
                              onClick={onSearch}
                              type="button"
                              sx={{ p: '10px 0px' }}
                              aria-label="search"
                              className='icon-button-2'
                            >
                              <SearchIcon />
                            </IconButton>
                          </div>
                        </th>
                        } */}



                        {searchType === "coins" && <th>
                          <div className='iconbtn-div'>
                            <select style={{ height: "27px" }} onChange={(e) => setCoinFilterByTag(e.target.value)} name="type" id="">
                              {/* <option value={searchType}>Select</option> */}
                              <option value={"credit"}>credit</option>
                              <option value={"debit"}>debit</option>

                            </select>

                            <IconButton
                              onClick={onSearch}
                              type="button"
                              sx={{ p: '10px 0px' }}
                              aria-label="search"
                              className='icon-button-2'
                            >
                              <SearchIcon />
                            </IconButton>
                          </div>
                        </th>
                        }


                        {/* other extra empty field adjustment ---------------------- */}

                        {searchType === "withdrawal" && <>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </>}

                        {searchType === "deposit" &&
                          <>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </>
                        }

                        {
                          searchType === "coins" && <>
                            <th></th>
                            <th></th>
                            <th></th>

                          </>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {fundReqHistoryData.map((item: any, index: number) => {
                        return (
                          <tr>
                            {searchType === "deposit" && <td>{item?.paymentType}</td>}
                            {searchType === "deposit" && <td>{item?.amount}</td>}
                            {searchType === "deposit" && <td>{item.orderId}</td>}
                            {searchType === "deposit" && <td>{item?.orderKeyID}</td>}
                            {searchType === "deposit" && <td>{item?.paymentGatewayName}</td>}
                            {searchType === "deposit" && <td>{item?.mid}</td>}
                            {searchType === "deposit" && <td>{item.userName}</td>}
                            {searchType === "deposit" && <td>{item?.status}</td>}
                            {searchType === "deposit" && <td>{item?.userEmail}</td>}
                            {searchType === "deposit" && <td>{item?.userMobile}</td>}
                            {/* {searchType === "deposit" && <td>{item?.userBankName}</td>}
                            */}
                            {searchType === "deposit" && <td>{item?.userCity}</td>}
                            {searchType === "deposit" && <td>{item?.userState}</td>}
                            {searchType === "deposit" && <td>{item?.userLatitude}</td>}
                            {searchType === "deposit" && <td>{item?.userLongitude}</td>}
                            {searchType === "deposit" && <td>{formatDate(item?.updatedBy?.date)}</td>}
                            {searchType === "deposit" && <td>{formatDate(item?.createdOn)}</td>}
                            {searchType === "deposit" && <td>{formatedTime(item?.createdOn)}</td>}

                            {searchType === "withdrawal" && <td>{item?.paymentType ?? "withdrawal"}</td>}
                            {searchType === "withdrawal" && <td>{item?.dp_id}</td>}
                            {searchType === "withdrawal" && <td>{item?.amount}</td>}
                            {searchType === "withdrawal" && <td>{item?.status}</td>}
                            {searchType === "withdrawal" && <td>{item?.transactionId}</td>}
                            {searchType === "withdrawal" && <td>{item?.mobile}</td>}
                            {searchType === "withdrawal" && <td>{item?.accountNo}</td>}
                            {searchType === "withdrawal" && <td>{item?.accountHolderName}</td>}
                            {searchType === "withdrawal" && <td>{item?.orderId}</td>}
                            {searchType === "withdrawal" && <td>{item?.ifscCode}</td>}
                            {searchType === "withdrawal" && <td>{item?.userBankName}</td>}
                            {searchType === "withdrawal" && <td>{item?.bankName}</td>}
                            {searchType === "withdrawal" && <td>{item?.withdrewalProviderName}</td>}
                            {searchType === "withdrawal" && <td>{item?.commissionAmount}</td>}
                            {searchType === "withdrawal" && <td>{formatDate(item?.createdOn)}</td>}
                            {searchType === "withdrawal" && <td>{formatedTime(item?.createdOn)}</td>}
                            {searchType === "withdrawal" && ""}
                            {searchType === "withdrawal" && ""}
                            {searchType === "withdrawal" && ""}


                            {searchType === "coins" && <td>{item?.paymentType ?? "coins"}</td>}
                            {searchType === "coins" && <td>{item?.userId}</td>}
                            {searchType === "coins" && <td>{item?.balance}</td>}
                            {searchType === "coins" && <td>{item?.updatedBy?.name}</td>}
                            {searchType === "coins" && <td>{item?.reason}</td>}
                            {searchType === "coins" && <td>{item?.tag}</td>}
                            {searchType === "coins" && <td>{item?.remark}</td>}
                            {searchType === "coins" && <td>{formatDate(item?.createdOn)}</td>}
                            {searchType === "coins" && <td>{formatedTime(item?.createdOn)}</td>}



                          </tr>
                        )
                      })}

                    </tbody>
                  </table>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 20 }}>
                    <Stack spacing={2}>
                      <Pagination
                        count={totalPages}
                        color="secondary"
                        page={currentPage}
                        onChange={(Event, New_Page) =>
                          setCurrentPage(New_Page)
                        }
                      />
                    </Stack>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>


  </>
}

export default FundRequestHistory
