import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup
} from "@material-ui/core";
import axios from "axios";
import Loader from "../../../../Components/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { API_Endpoint } from "../../../../Configuration/Settings";
import { Responsibilities } from "../../../../Configuration/Enums";
import { User_Context } from "../../../../Contexts/User";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import Reusable_Input from "../../../../Components/InputField/InputField";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";

interface UpiData {
  _id: string;
  name: string;
  upiId: string;
  status: boolean;
}
function UpiList() {
  const [deletePopup, setDeletePopup] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [id, setId] = useState<string>("");
  const [pn, setPn] = useState("");
  const [switchState, setSwitchState] = useState(false);
  const [upiId, setUpiId] = useState("");
  const [upiData, setUpiData] = useState<Array<UpiData>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pnError, setPnError] = useState<boolean>(false);
  const [pnHelperText, setPnHelperText] = useState<string>("");
  const [upiIdError, setUpiIdError] = useState<boolean>(false);
  const [upiIdHelperText, setUpiIdHelperText] = useState<string>("");
  const { User } = useContext(User_Context);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");

    axios
      .post(`${API_Endpoint}/upiLists/getAll`, { token: encryptData({}) }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        response.data.data = decryptData(response.data.data);
        setUpiData(response.data.data.payload);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err.message);
        setLoading(false);
      });
  }, []);
  // const theme = useTheme();
  const openDeletePopup = (id: string) => {
    setDeletePopup(true);
    setId(id);
  };

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleUpiIdChange = (event) => {
  //   setUpiId(event.target.value);
  // };
  // const handlePnChange = (event) => {
  //   setPnChange(event.target.value);
  // };

  // const handleSwitchChange = (event) => {
  //   setSwitchState(event.target.checked);
  // };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!pn) {
      setPnError(true);
      setPnHelperText("Enter PN");
    } else if (!upiId) {
      setUpiIdError(true);
      setUpiIdHelperText("enter Upi id");
    } else {
      setOpenPopup(false);
      setLoading(true);
      const token = localStorage.getItem("token");

      axios
        .post(
          `${API_Endpoint}/upiLists/`,

          {
            token: encryptData({
              name: pn,
              upiId: upiId,
              status: switchState,
            })
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setPn("");
          setUpiId("");
          response.data.data = decryptData(response.data.data);
          setUpiData(response.data.data.payload);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          setLoading(false);
        });
    }
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const handleStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const checked = event.target.checked;
    const id = event.target.id;
    const apiUrl = `${API_Endpoint}/upiLists/update`;
    const postData = {
      _id: id,
      status: checked,
    };
    const token = localStorage.getItem("token");

    axios
      .post(apiUrl, { token: encryptData(postData) }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        const updatedImageStatus = upiData.map((item) => {
          if (item._id === id) {
            return {
              ...item,
              status: checked,
            };
          } else return item;
        });
        setUpiData(updatedImageStatus);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  };
  const deleteUpiId = () => {
    setLoading(true);
    setDeletePopup(false);
    const token = localStorage.getItem("token");

    axios
      .post(`${API_Endpoint}/upiLists/delete`, { token: encryptData({ _id: id }) }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        const updatedUpiData = upiData.filter((item) => item._id !== id);
        setUpiData(updatedUpiData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };
  const handlePn = (e: ChangeEvent<HTMLInputElement>) => {
    setPnError(false);
    setPn(e.target.value);
  };
  const handleUpiId = (e: ChangeEvent<HTMLInputElement>) => {
    setUpiIdError(false);
    setUpiId(e.target.value);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="g-sidenav-show  bg-gray-100">
          <Sidenav />
          <main className="main-content position-relative">
            <div style={{ background: "#f8f9fa" }}>
              <Breadcrumbs tab={"UPI Lists"} button={User.data.Responsibilities.includes(Responsibilities.Add_UPI) && <Button
                onClick={() => setOpenPopup(true)}
                className="btn-payment"
                variant="contained"
              >
                Add
              </Button>} />
              <div>
                <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
                  <DialogContent>
                    <form onSubmit={handleSubmit}>
                      <div>
                        <Reusable_Input type={"text"} label={"PN"} fullWidth={true} value={pn} error={pnError} helperText={pnHelperText} onChange={handlePn} />
                      </div>

                      <div className="mt-2">
                        <Reusable_Input type={"text"} label={"UPI Id"} fullWidth={true} value={upiId} error={upiIdError} helperText={upiIdHelperText} onChange={handleUpiId} />
                      </div>

                      <FormGroup className="mt-2">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={switchState}
                              onChange={(e) => setSwitchState(e.target.checked)}
                              color="primary"
                            />
                          }
                          label={switchState ? "Active" : "Inactive"}
                        />
                      </FormGroup>
                      <DialogActions>
                        <Button
                          className="btn-popup"
                          variant="outlined"
                          onClick={() => setOpenPopup(false)}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn-popup"
                          variant="outlined"
                          type="submit"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  </DialogContent>
                </Dialog>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-view">
                        <thead>
                          <tr>
                            <th>PN</th>
                            <th>UPI Id</th>
                            {User.data.Responsibilities.includes(Responsibilities.Toggle_UPI) && <th>Status</th>}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {upiData.map((item) => {
                            return (
                              <tr id={item._id}>
                                <td>{item.name}</td>
                                <td>{item.upiId}</td>
                                {User.data.Responsibilities.includes(Responsibilities.Toggle_UPI) && <td>
                                  <Switch
                                    {...label}
                                    id={item._id}
                                    checked={item.status}
                                    onChange={(e) => handleStatus(e)}
                                  />
                                </td>}
                                <td>
                                  {User.data.Responsibilities.includes(Responsibilities.Delete_UPI) && <span>
                                    <FontAwesomeIcon
                                      id={item._id}
                                      className="fa fa-pencil-square icon-home icon-trash "
                                      icon={faTrashCan}
                                      onClick={() => openDeletePopup(item._id)}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <footer className="footer">
                  <div className="container-fluid">
                    <div className="row align-items-center justify-content-lg-between">
                      <div className="col-lg-6 mb-lg-0  ">
                        <div className="copyright text-center text-sm text-muted text-lg-start">
                          © fairbets.co
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              About Us
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Blog
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link pe-0 text-muted"
                              target="_blank"
                            >
                              License
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </main>
          <div>
            <Dialog open={deletePopup} onClose={() => setDeletePopup(false)}>
              <DialogTitle>Are You Sure ?</DialogTitle>
              <DialogContent>
                <DialogActions className="mt-n3">
                  <Button
                    className="btn-popup"
                    variant="outlined"
                    onClick={() => setDeletePopup(false)}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-popup"
                    variant="outlined"
                    type="submit"
                    color="primary"
                    onClick={() => deleteUpiId()}
                  >
                    Delete
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      )}
    </>
  );
}

export default UpiList;