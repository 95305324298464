
export class SessionHelper {
    static activeEchangeProvider: string = '';

    public static getActiveExchangeProvider(): string { 
        return SessionHelper.activeEchangeProvider;
    }

    public static setActiveExchangeProvider(exchangeProvider: string = ''): void{
        SessionHelper.activeEchangeProvider = exchangeProvider;
    }
}