import React, { useState, useEffect, useContext, ChangeEvent } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { User_Context } from '../../../../Contexts/User';
import { API_Endpoint } from '../../../../Configuration/Settings';
import { encryptData } from '../../../../utils/encryptData';
import { decryptData } from '../../../../utils/decryptData';
import { useNavigate } from "react-router-dom";


// Components
import Loader from '../../../../Components/Loader/Loader';
import WalletHistoryTable from './WalletHistoryTable';

// Styles
import './WalletHistory.css'
import Stateful_Select from '../../../../Components/Dropdown/Dropdown';
import Reusable_Input from '../../../../Components/InputField/InputField';
import { Button } from '@mui/material';

interface BonusWalletData {
    userReferral: number;
    userReferralCount: number;
    userOwnEarning: number;
    userOwnEarningCount: number;
}
function WalletHistory({ type }: WalletHistoryProps) {


    const [loading, setLoading] = useState(false)
    const { User } = useContext(User_Context)
    const { User_ID} = useParams();
    const [historyData, setHistoryData] = useState([]);
    // const [pageNo, setPageNo] = useState(1)
    const [pageNo, setPageNo] = useState<number>(() => {
        const savedPageNo = localStorage.getItem('walletHistoryPageNo');
        return savedPageNo ? parseInt(savedPageNo, 10) : 1;
    });
    const [depositTotal, setDepositTotal] = useState(0);
    const [withdrawalTotal, setWithdrawalTotal] = useState(0);

    const [balanceTotal, setBalanceTotal] = useState(0);
    const [bonusBalanceTotal, setBonusBalanceTotal] = useState(0);
    const [totalPages, setTotalPages] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [marketIdSearchKey, setMarketIdSearchKey] = useState('');
    const [bonusData, setBonusData] = useState<BonusWalletData>()
    // const [userExposure, setUserExposure] = useState(null)
    const [userExposure, setUserExposure] = useState<number | null>(null);

    const Navigate = useNavigate();

    const getWalletHistory = async (page: number) => {
        setLoading(true);
        let payload: { itemsPerPage: number, pageNo: number, filter: { [key: string]: string | number } } = {
            itemsPerPage: Number(itemsPerPage),
            pageNo: page,
            filter: {
                userId: User_ID?.toString() ?? '',
            }
        }
        if (marketIdSearchKey) {
            payload.filter["description.marketId"] = Number(marketIdSearchKey);
        }
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/wallet-History`,
            headers: { Authorization: `Bearer ${User.token}` },
            data: { token: encryptData(payload) },
        };
        axios.request(config).then((response) => {
            response.data.data = decryptData(response.data.data);
            const dataArray = response.data.data.payload
            setHistoryData(dataArray.walletHistory);
            // if (Array.isArray(dataArray)) {
            //     let totalDepositAmount = 0;
            //     let totalWithdrawalAmount = 0;
            //     dataArray.forEach(item => {
            //         if (item.action === 'withdrawal request') {
            //             totalWithdrawalAmount = totalWithdrawalAmount + item.amount;
            //         } else if (item.action === 'Deposit') {
            //             totalDepositAmount = totalDepositAmount + item.amount;
            //         }
            //     })
            //     setDepositTotal(totalDepositAmount);
            //     setWithdrawalTotal(totalWithdrawalAmount)
            // }
            setDepositTotal(dataArray.totalDeposit);
            setWithdrawalTotal(dataArray.totalWithdrawal);
            setBalanceTotal(Math.floor(dataArray.balance));
            setBonusBalanceTotal(dataArray.bonusWalletBalance);
            setTotalPages(dataArray.totalPages);
            setLoading(false);
        }).catch((error) => { console.log(error); });
    }


    useEffect(() => {
        getWalletHistory(pageNo);
    }, [pageNo, itemsPerPage]);

    useEffect(() => {
        setLoading(true);
        const token = localStorage.getItem("token");
        let data = {
            userId: User_ID,
            itemsPerPage: 10,
            pageNo: 1
        }
        if (token) {
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${API_Endpoint}/bonus-wallet/user-total-earning`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: { token: encryptData(data) }
            };
            axios
                .request(config)
                .then((response) => {
                    response.data.data = decryptData(response.data.data)
                    setBonusData(response.data.data.payload)
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err.message);
                });
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        const token = localStorage.getItem("token");
        let data = {
            _id: User_ID,
        } 
        if (token) {
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${API_Endpoint}/User/user-exposer`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: { token: encryptData(data) }
            };
            axios
                .request(config)
                .then((response) => {
                    response.data.data = decryptData(response.data.data)
                    setUserExposure(response.data.data.payload)
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err.message);
                });
        }
    }, []);

    const handlePageChange = (newPage: number) => {
        setPageNo(newPage);
        localStorage.setItem('walletHistoryPageNo', newPage.toString());
    }   

    const handleSubmitClick = () => getWalletHistory(pageNo);

    const handleUserDeposit = (e?: any, Type?: string) => {
        let data = { User_ID, Type }
        Navigate("/bonus-wallet-referral-earning", { state: data });
    };

    const handleUserExposure = (e?: any, Type?: string) => {
        if (userExposure !== null && userExposure > 0) {
            Navigate("/user_exposure", { state: User_ID });
        }
    };

    return (
        <div>
            {loading && <Loader />}
            {!loading &&
                <div className='wallet-history-container'>

                    <div className='wallet-history-count-container'>
                        <div className='wallet-history-count-box'>

                            <div className='wallet-history-count-box-game'>
                                <div className='wallet-history-box-header'>
                                    <p className='wallet-history-text'>Bazar</p>
                                </div>

                                <p className='wallet-history-text'>Regular</p>

                                <p className='wallet-history-text'>Starline</p>

                                <p className='wallet-history-text'>King Bazar</p>

                                <p className='wallet-history-text'>Casino</p>

                                <div className='wallet-history-box-footer'>
                                    <p className='wallet-history-text'>Grand Total</p>
                                </div>



                            </div>

                            <div className='wallet-history-count-box-win-loss'>
                                <div className='wallet-history-box-header'>
                                    <p className='wallet-history-text'>(Win - Loss)</p>
                                </div>


                                <p className='wallet-history-text'>0 - 0</p>

                                <p className='wallet-history-text'>0 - 0</p>

                                <p className='wallet-history-text'>0 - 0</p>

                                <p className='wallet-history-text'>0 - 0</p>

                                <div className='wallet-history-box-footer'>
                                    <p className='wallet-history-text'>-</p>
                                </div>

                            </div>

                            <div className='wallet-history-count-box-total'>
                                <div className='wallet-history-box-header'>
                                    <p className='wallet-history-text'>Total</p>
                                </div>


                                <p className='wallet-history-text'>:0</p>

                                <p className='wallet-history-text'>:0</p>

                                <p className='wallet-history-text'>:0</p>

                                <p className='wallet-history-text'>:0</p>

                                <div className='wallet-history-box-footer'>
                                    <p className='wallet-history-text'>:0</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='wallet-history-deposit-withdrawal-container bg-white box-shd'>
                        <div>
                            <p className='wallet-history-text'>{`Deposit: ${depositTotal}`}</p>
                        </div>
                        <div>
                            <p className='wallet-history-text'>{`Withdraw: ${withdrawalTotal}`}</p>
                        </div>

                        <div>
                            <p className='wallet-history-text'>{`Balance: ${balanceTotal}`}</p>
                        </div>

                        <div>
                            <p className='wallet-history-text'>{`Bonus Wallet Balance: ${bonusBalanceTotal}`}</p>
                        </div>

                        <div className='wallet-history-box-footer'>
                            <p className='wallet-history-text' style={{ color: (depositTotal - withdrawalTotal) < 0 ? 'red' : 'green' }}>
                                {(depositTotal - withdrawalTotal) < 0 ? 'Loss' : 'Profit'}: {depositTotal - withdrawalTotal}
                            </p>
                        </div>

                        <div>
                            {bonusData?.userOwnEarning ? (
                                <div style={{ cursor: "pointer" }} onClick={(e) => handleUserDeposit(e, "bonus")}>
                                    <b className='wallet-history-text'>Bonus Earning ({bonusData?.userOwnEarningCount}) : {bonusData?.userOwnEarning}</b>
                                </div>
                            ) : (
                                <div>
                                    <b className='wallet-history-text'>Bonus Earning (0) : 0</b>
                                </div>
                            )}
                        </div>

                        <div>
                            {bonusData?.userReferral ? (
                                <div style={{ cursor: "pointer" }} onClick={handleUserDeposit}>
                                    <b className='wallet-history-text'>Bonus Referral Earning ({bonusData?.userReferralCount}) : {bonusData?.userReferral}</b>
                                </div>
                            ) : (
                                <div>
                                    <b className='wallet-history-text'>Bonus Referral Earning (0) : 0</b>
                                </div>
                            )}
                        </div>
                        <div>
                        {userExposure !== null && userExposure > 0 ? (
                <div style={{ cursor: "pointer" }} onClick={handleUserExposure}>
                    <b className="wallet-history-text">User Exposure Total Sum : {userExposure}</b>
                </div>
            ) : (
                <div>
                    <b className="wallet-history-text">User Exposure Total Sum : 0</b>
                </div>
            )}
                            
                        </div>


                    </div>
                </div>
            }
            {/* <div className="row main-div">
                <div className="col-6 col-xl-2 col-sm-4 btn-top role-mob role-web">
                    <label className="lbl">Items Per Page</label>
                    <Stateful_Select
                        value={itemsPerPage.toString()}
                        onChange={(newValue: any) => setItemsPerPage(newValue)}
                        options={["50", "75", "100", "125", "150", "175", "200","500"]}
                    />
                </div>
                <div className="col-6 col-xl-2 col-sm-4 btn-top role-mob role-web">
                    <label className="lbl m-1">Market ID</label>
                    <Reusable_Input
                        label={""}
                        value={marketIdSearchKey}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setMarketIdSearchKey(event.target.value);
                        }}
                        type={"text"}
                        fullWidth={true}
                        error={false}
                        helperText={""}
                        customInputTextStyles={{
                            padding: '6px',
                        }}
                    />
                </div>
                <div className="col-6 col-xl-2 col-sm-4 btn-top role-mob role-web d-flex align-items-end submit-wallet">
                    <Button
                        onClick={handleSubmitClick}
                        className="sech-button px-2 py-1"
                        variant="contained"
                        disabled={!marketIdSearchKey?.length}
                    >
                        Submit
                    </Button>
                </div>
            </div> */}

            {!loading &&
                <WalletHistoryTable
                />
            }
        </div>
    )
}

export default WalletHistory;

interface WalletHistoryProps {
    type?: String;
}