import { useContext, useEffect, useState } from "react";
import Loader from "../../../../Components/Loader/Loader";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import axios from "axios";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Stack,
  TextField,
} from "@mui/material";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "../../../../Css/users.css";
import "./Feedback.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { API_Endpoint } from "../../../../Configuration/Settings";
import { Responsibilities } from "../../../../Configuration/Enums";
import { User_Context } from "../../../../Contexts/User";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import Stateful_Select from "../../../../Components/Dropdown/Dropdown";
import { dateTime, formatedTime } from "../../../../utils/utility";

const topSpace = { marginTop: "1.5rem" };

type addFeedback = {
  name: string;
  mobile: string;
  message: string;
};
const COMPLETED_STATUS = "Completed";

const CompletedFeedback = () => {
    const [loading, setLoading] = useState<boolean>(true);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [userFeedback, setUserFeedback] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const { User } = useContext(User_Context);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<addFeedback>();
  const addFeedback: SubmitHandler<addFeedback> = (payload: addFeedback) => {
    const add = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          const url = `${API_Endpoint}/feedBack`;
          const response = await axios.post(url, { token: encryptData(payload) }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          response.data.data = decryptData(response.data.data);
          response.data.data = response.data.data.payload;
          const { success, data: feedbackAdded } = response.data;
          if (success) {
            setShowAddModal(false);
            setData([...data, feedbackAdded]);
          }
        }
      } catch (error) {
        console.error("Error while adding feedback", error);
      }
    };
    add();
  };


const filterTransaction = () => {
  const token = localStorage.getItem("token");
  if (token) {
    const feedbackList = async () => {
      try {
        let data: any = {
          filter: {
            feedBackStatus: COMPLETED_STATUS
          },
          pageNo: currentPage,
          itemsPerPage: itemsPerPage,
          
        };
          if (startDate && endDate) {
            data = {
              ...data,
              startDate: dateTime(startDate),
              endDate: dateTime(endDate),
             
            };
          }
        const url = `${API_Endpoint}/feedBack/getAll`;
        const config = {
          method: "post",
          url: url,
          data: { token: encryptData(data) }, 
          headers: {
            Authorization: `Bearer ${token}`
          }
        };
        const response = await axios(config);
        response.data.data = decryptData(response.data.data); 
        response.data.data = response.data.data.payload;
        const {
          data: { items: feedbackList, totalPages },
          success
        } = response.data;

        if (success) {
          setLoading(false);
          setData(feedbackList);
          setTotalPages(totalPages);
        }
      } catch (error) {
        console.error("fetch feedback list", error);
      }
    };
    feedbackList();
  }
};


  const clearDate = () => {
    setStartDate('')
    setEndDate('')
  }

  useEffect(() => {
    filterTransaction()
  }, [currentPage, itemsPerPage]);

  const handlePerPage = (newValue: any) => {
    setItemsPerPage(newValue)
  }

  return (
    <>
    {loading ? (
      <Loader />
    ) : (
      <div className="g-sidenav-show  bg-gray-100">
        <Sidenav />
        <main className="main-content position-relative">
          <div style={{ background: "#f8f9fa" }}>
            <Breadcrumbs tab={"Completed Feedback List"} />
            <Dialog
              open={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
            >
              <DialogTitle>Do you want to Delete!</DialogTitle>
              <DialogContent
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <Button
                  variant="contained"
                  className="btn-popup"
                  color="primary"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Cancel
                </Button>
                {/* <Button
                  variant="contained"
                  className="btn-popup"
                  color="primary"
                  onClick={deleteFeedback}
                >
                  Delete
                </Button> */}
              </DialogContent>
            </Dialog>
            {/* Edit Feedback Modal Popup */}
            <div>
              <Dialog
                open={showEditModal}
                onClose={() => setShowEditModal(false)}
                fullWidth
              >
                <DialogContent>
                  <form>
                    <div>
                      <TextField
                        onChange={(e) => setUserFeedback(e.target.value)}
                        value={userFeedback}
                        id="outlined-basic"
                        type="text"
                        fullWidth
                        label="Feedback Message"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          style: {
                            fontFamily: "Roboto",
                            fontSize: "14px",
                          },
                        }}
                      />
                    </div>

                    <DialogActions>
                      <Button
                        className="btn-popup"
                        variant="outlined"
                        color="primary"
                        onClick={() => setShowEditModal(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn-popup"
                        variant="outlined"
                        type="submit"
                        color="primary"
                      >
                        Update
                      </Button>
                    </DialogActions>
                  </form>
                </DialogContent>
              </Dialog>
            </div>
            {/* Edit form end */}

            <div className="container-fluid">
              <div className="row">
                <div className="col-6 col-xl-2 col-sm-4 pdrt">
                  <label className="lbl">From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="From Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div className="col-6 col-xl-2 col-sm-4 pdrt">
                  <label className="lbl">To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="To Date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <div className="col-6 col-xl-2 col-sm-4 pdrt mt-1">
                  <label className="lbl"></label>
                  <a onClick={filterTransaction} className="sechBtn mt-1">
                    Apply
                  </a>
                </div>
                <div className="col-6 col-xl-2 col-sm-4 pdrt mt-1">
                    <label className="lbl"></label>
                    <a onClick={clearDate} className="sechBtn mt-1">
                      Clear Dates
                    </a>
                  </div>
                <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-1">
                  <label>Items Per Page</label>
                  <Stateful_Select
                    // label="Items Per Page"
                    value={itemsPerPage.toString()}
                    onChange={(newValue: any) => handlePerPage(newValue)}
                    options={["10", "25", "50", "75", "100"]}
                  />
                </div>
                <div className="col-12 mt-2">
                  <div className="table-responsive">
                    <table className="table table-view">
                      <thead>
                        <tr>
                          <th></th>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Mobile</th>
                          <th>Message</th>
                          <th>Reply</th>
                          <th>Date</th>
                          <th>Updated Time</th>
                          <th>Responded By</th>
                        </tr>
                      </thead>

                      <tbody>
                        {data.map((item: any, index:number) => {
                          return (
                            <tr key={item._id}>
                              <td>{index + 1 + (currentPage && itemsPerPage  ? (currentPage - 1)  * itemsPerPage : 0)}</td>
                              <td>{item._id}</td>
                              <td>{item.name.slice(0, 15)}</td>
                              <td>{item.mobile}</td>
                              <td className="message-cell">{item.message}</td>
                              <td>{item.feedbackResponse}</td>
                              <td>{`${dateTime(item.createdOn)} ${formatedTime(item.createdOn)}`}</td>
                              <td> {`${dateTime(item.updatedBy.date)} ${formatedTime(item.updatedBy.date)}`}
                              </td>
                              <td>{item.updatedBy.name}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <ul className="pagination d-flex justify-content-center">
                    <Stack spacing={2}>
                      <Pagination
                        count={totalPages}
                        color="secondary"
                        page={currentPage}
                        onChange={(event, newPage) => setCurrentPage(newPage)}
                      />
                    </Stack>
                  </ul>
                </div>
              </div>
              <footer className="footer">
                <div className="container-fluid">
                  <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6 mb-lg-0  ">
                      <div className="copyright text-center text-sm text-muted text-lg-start">
                        © fairbets.co
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                        <li className="nav-item">
                          <a className="nav-link text-muted" target="_blank">
                            Home
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link text-muted" target="_blank">
                            About Us
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link text-muted" target="_blank">
                            Blog
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link pe-0 text-muted"
                            target="_blank"
                          >
                            License
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </main>
      </div>
    )}
  </>
  )
}

export default CompletedFeedback
