import React, { ChangeEvent } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

type SearchProps = {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSearch: () => void;
  placeholder?: string;
};

const SearchBar: React.FC<SearchProps> = ({
  value,
  onChange,
  onSearch,
  placeholder = "Search",
}) => {
  return (
    <Paper
      className="paper"
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: { xs: 120, md: 210 },
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1, fontSize: 15 }}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === "Enter") {
            onSearch()
          }
        }}
      />
      <IconButton
        onClick={onSearch}
        type="button"
        sx={{ p: '10px 0px' }}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
