import { useContext, useState, useEffect, ChangeEvent } from "react";
import { ToastContainer } from "react-toastify";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import { Pagination, Stack } from "@mui/material";
import ExcelExport from "../../../../Excel/ExcelExport";
import axios from "axios";
import { API_Endpoint } from "../../../../Configuration/Settings";
import Loader from "../../../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import { User_Context } from "../../../../Contexts/User";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "../../../../Css/users.css";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import SearchBar from "../../../../Components/SearchBox/Search";
import Stateful_Select from "../../../../Components/Dropdown/Dropdown";

interface PLData {
  _id: string;
  name: string;
  balance: number;
  betAmount: number;
  totalProfit: number;
  totalLoss: number;
  withdrawl: number;
  bonus: number;
  deposite: number;
  mobile: number;
}
export default function ProfitandLoss() {
  const [plData, setPlData] = useState<Array<PLData>>([]);
  const [searchUserId, setSearchUserId] = useState<string>("");
  const [searchUserName, setSearchUserName] = useState<string>("");
  const [searchUserMob, setSearchUserMob] = useState<string>("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const Navigate = useNavigate();
  const { User } = useContext(User_Context);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);



  const handleSearchUserId = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUserId(event.target.value);
  };
  const handleSearchUserName = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUserName(event.target.value);
  };
  const handleSearchUserMob = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchUserMob(event.target.value);
  };

  const filterUser = () => {
    setLoading(true);
    const filter: { _id?: string, name?: string, mobile?: string } = {};
    if (searchUserId) {
      filter._id = searchUserId
    }
    if (searchUserName) {
      filter.name = searchUserName
    }
    if (searchUserMob) {
      filter.mobile = searchUserMob
    }
    const token = localStorage.getItem("token");
    let data = {
      pageSize: 10,
      pageNumber: currentPage,
      filter: filter
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_Endpoint}/User/getProfitLoss`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { token: encryptData(data) },
    };

    axios
      .request(config)
      .then((response) => {
        response.data.data = decryptData(response.data.data)
        const pages = response.data.data.payload.count / 10;
        setTotalPages(Math.ceil(pages));
        setPlData(response.data.data.payload.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      });
  }
  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let data = {
      pageSize: Number (itemsPerPage),
      pageNumber: currentPage,
      filter: {}
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_Endpoint}/User/getProfitLoss`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { token: encryptData(data) },
    };

    axios
      .request(config)
      .then((response) => {
        response.data.data = decryptData(response.data.data)
        const pages = response.data.data.payload.count / 10;
        setTotalPages(Math.ceil(pages));
        setPlData(response.data.data.payload.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      });
  }, [currentPage, itemsPerPage]);

  const handlePerPage = (newValue: any) => {
    setItemsPerPage(newValue)
  }

  const headings = [
    [
      "User Id",
      "User Name",
      "Balance",
      "Bet Amount",
      "Total Profit",
      "Total Loss",
      "Withdrawal",
      "Deposit",
      "Final Profit",
      "Final Loss",
    ],
  ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="g-sidenav-show  bg-gray-100">
          <ToastContainer autoClose={2000} position="top-center" />
          <Sidenav />
          <main className="main-content position-relative">
            <div style={{ background: "#f8f9fa" }}>
              <Breadcrumbs tab={"Profit & Loss"} />
              <div className="container-fluid">
                <div className="row">
                  <div className="row tp-form">
                    <div className="d-flex align-items-center mb-1">
                      <div className="col-6 col-xl-1 col-sm-4 search-4">
                        <ExcelExport
                          inputData={plData}
                          headings={headings}
                          fileName={"Profit & Loss"}
                        />
                      </div>
                      <div>
                        <span className="ms-1">Download Data</span>
                      </div>
                    </div>
                    <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-2">
                        <Stateful_Select
                          label="Items Per Page"
                          value={itemsPerPage.toString()}
                          onChange={(newValue: any) => handlePerPage(newValue)}
                          options={["10", "25", "50", "75", "100"]}
                        />
                      </div>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="table-responsive">
                      <table className="table table-view">
                        <thead>
                          <tr>
                            <th></th>
                            <th>User ID</th>
                            <th>User Name</th>
                            <th>Mobile No</th>
                            <th>Start Balance</th>
                            <th>Deposite</th>
                            <th>Bet Amount</th>
                            <th>Win Amount</th>
                            <th>Withdraw</th>
                            <th>Bonus</th>
                            <th>End Balance</th>
                          </tr>
                        </thead>
                        <thead>
                          <tr className="bg-table">
                            <th className="thdr">
                            </th>
                            <th className="thdr">
                              <div className="d-flex justify-content-center">
                                <SearchBar value={searchUserId} onChange={handleSearchUserId} onSearch={filterUser} placeholder="Search by user id" />
                              </div>
                            </th>
                            <th className="thdr">
                              <div className="d-flex justify-content-center">
                                <SearchBar value={searchUserName} onChange={handleSearchUserName} onSearch={filterUser} placeholder="Search by user name" />
                              </div>
                            </th>
                            <th className="thdr">
                              <div className="d-flex justify-content-center">
                                <SearchBar value={searchUserMob} onChange={handleSearchUserMob} onSearch={filterUser} placeholder="Search by user mobile" />
                              </div>
                            </th>
                            <th className="thdr"></th>
                            <th className="thdr"></th>
                            <th className="thdr"></th>
                            <th className="thdr"></th>
                            <th className="thdr"></th>
                            <th className="thdr"></th>
                            <th className="thdr"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {plData.map((user,index) => (
                            <tr>
                              <td>{index + 1 + (currentPage && itemsPerPage  ? (currentPage - 1)  * itemsPerPage : 0)}</td>
                              <td>{user._id}</td>
                              <td key={user._id} onClick={() => {
                              const url = `/user-report/${user._id}/${user.name}`;
                              window.open(url, "_blank");
                            }} style={{ cursor: 'pointer' }}>{user.name}</td>
                              <td>{user.mobile}</td>
                              <td>{user.balance}</td>
                              <td>{user.deposite}</td>
                              <td>{user.betAmount}</td>
                              <td>{user.totalProfit}</td>
                              <td>{user.withdrawl}</td>
                              <td>0</td>
                              <td>{user.balance}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <ul className="pagination d-flex justify-content-center">
                      <Stack spacing={2}>
                        <Pagination
                          count={totalPages}
                          color="secondary"
                          page={currentPage}
                          onChange={(event, newPage) => setCurrentPage(newPage)}
                        />
                      </Stack>
                    </ul>
                  </div>
                </div>
                <footer className="footer">
                  <div className="container-fluid">
                    <div className="row align-items-center justify-content-lg-between">
                      <div className="col-lg-6 mb-lg-0  ">
                        <div className="copyright text-center text-sm text-muted text-lg-start">
                          © fairbets.co
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              About Us
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Blog
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link pe-0 text-muted"
                              target="_blank"
                            >
                              License
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
}