import { FC, ReactNode, createContext, useState } from 'react';

interface User_Type
{
	token: string;
	data:
	{
		_id: string;
		name: string;
		mobile: string;
		email: string;
		balance?: Number;
		profitLoss?: Number;
		createdOn: string;
		updatedOn: string;
		Role_ID: string;
		Responsibilities: string [];
		allotedCustomer?: string [];
		__v?: Number;
		permission?:boolean
		DP_ID?:string,
		chatName?:string
	}
}

interface User_Context_Type
{
	User: User_Type;
	Set_User: (User: User_Type) => void;
}

const Initial_User = 
{
	User:
	{
		token: localStorage.getItem ('token') || '',
		data: localStorage.getItem ('user') ? JSON.parse (localStorage.getItem ('user') || '') :
		{ 
			_id: '',
			name: '',
			mobile: '',
			email: '',
			balance: 0,
			profitLoss: 0,
			createdOn: '',
			updatedOn: '',
			Role_ID: '',
			Responsibilities: [],
			allotedCustomer: [],
			permission: false,
			DP_ID:"",
			chatName:""
		}
	},
	Set_User: () => {}
}

export const User_Context = createContext <User_Context_Type> (Initial_User);

const User_Provider: FC <ReactNode> = ({ children }) =>
{
	const [User, Set_User] = useState <User_Type> (Initial_User.User);
	return <User_Context.Provider value={{ User, Set_User }}>{children}</User_Context.Provider>;
}

export default User_Provider;