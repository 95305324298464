import React, { useContext, useEffect, useState } from "react";
import "../../Css/style.css";
import Ctdark from "../../Images/logo-ct-dark.png";
import { useNavigate } from "react-router-dom";
import { mobileRegex, passwordRegex } from "../../utils/utility";
import { TextField, Button } from "@mui/material";
import Loader from "../../Components/Loader/Loader";
import { User_API } from "../../API/User";
import { User_Context } from "../../Contexts/User";
import { decryptData } from "../../utils/decryptData";
import { toast } from "react-toastify";

function Authentication() {
  const [mobile, setMobile] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [mobileError, setMobileError] = useState<string | undefined>(undefined);
  const [passwordError, setPasswordError] = useState<string | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [isMobileValid, setIsMobileValid] = useState<boolean>(false);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const [isLoginButtonEnabled, setLoginButton] = useState<boolean>(false);
  const { User, Set_User } = useContext(User_Context);
  const navigate = useNavigate();

  useEffect(() => {
    const storedMobile = localStorage.getItem("mobile");
    const storedPassword = localStorage.getItem("password");
    if (storedMobile && storedPassword) {
      // setLoginButton(true)
      setIsMobileValid(true);
      setIsPasswordValid(true);
      setMobile(storedMobile);
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, []);
  useEffect(() => {
    if (isMobileValid && isPasswordValid) {
      setLoginButton(true);
    } else {
      setLoginButton(false);
    }
  }, [isMobileValid, isPasswordValid]);

  const navigateTo = () => {
    // e.preventDefault()
    setLoading(true);
    if (isLoginButtonEnabled) {
      User_API.Log_In({ mobile, password })
        .then((response: any) => {
          setLoading(false);
          response.data.data = decryptData(response.data.data);
          response.data.data = response.data.data.payload;
          if (response.data.data.block) {
            toast.error("Cannot login due user is block")
          } else {
            if (rememberMe) {
              localStorage.setItem("mobile", mobile);
              localStorage.setItem("password", password);
            } else {
              localStorage.clear();
            }
            localStorage.setItem('user', JSON.stringify(response.data.data));
            localStorage.setItem('token', response.data.token);
            Set_User(response.data);
            navigate("/welcome");
          }
        })
        .catch((error: any) => {
          setLoading(false);
          if (error.message == "wrong password") {
            setPasswordError(error.response.data.message);
          } else {
            setMobileError(error.response.data.message);
          }
        });
    }
  };

  const handleMobile = (value: string) => {
    setMobile(value);
    if (!mobileRegex.test(value)) {
      setMobileError("Mobile number not valid");
      setIsMobileValid(false);
    } else {
      setMobileError(undefined);
      setIsMobileValid(true); // No error
    }
  };
  const handlePassword = (value: string) => {
    setPassword(value);
    if (!passwordRegex.test(value)) {
      setPasswordError("Password not valid");
      setIsPasswordValid(false);
    } else {
      setPasswordError(undefined);
      setIsPasswordValid(true);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <main className="main-content  mt-0">
            <section>
              <div className="page-header min-vh-75">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
                      <div className="card card-plain mt-4">
                        <div className="card-header pb-0 text-center bg-transparent">
                          <img src={Ctdark} className="dash-logo" />
                        </div>
                        <div className="card-body">
                          <form role="form">
                            <div className="mb-3">
                              <TextField
                                value={mobile}
                                onChange={(e) => handleMobile(e.target.value)}
                                id="outlined-basic"
                                type="text"
                                fullWidth
                                label="Mobile"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                  style: {
                                    fontFamily: "Roboto",
                                    fontSize: "14px",
                                  },
                                }}
                                error={!!mobileError}
                                helperText={mobileError}
                              />
                            </div>
                            <div className="mb-3">
                              <TextField
                                value={password}
                                onChange={(e) => handlePassword(e.target.value)}
                                id="outlined-password-input"
                                label="Password"
                                autoComplete="current-password"
                                fullWidth
                                size="small"
                                InputLabelProps={{
                                  style: {
                                    fontFamily: "Roboto",
                                    fontSize: "14px",
                                  },
                                }}
                                error={!!passwordError}
                                helperText={passwordError}
                                type="password"
                              />
                            </div>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="rememberMe"
                                checked={rememberMe}
                                onChange={(e) =>
                                  setRememberMe(e.target.checked)
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="rememberMe"
                              >
                                Remember me
                              </label>
                            </div>
                            <div className="text-center">
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={(e) => {
                                  e.preventDefault()
                                  navigateTo()
                                }}
                                disabled={!isLoginButtonEnabled}
                                className="btn sign-grad w-100 mt-4 mb-0"
                              >
                                Sign in
                              </Button>
                            </div>
                          </form>
                        </div>
                        {/* <div className="card-footer text-center pt-0 px-lg-2 px-1">
                        <p className="mb-4 text-sm mx-auto">
                          Don't have an account? {""}
                          <a
                            className="text-info text-gradient font-weight-bold"
                            onClick={navigateTo}
                            style={{ cursor: "pointer" }}
                          >
                            Sign up
                          </a>
                        </p>
                      </div> */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8">
                        <div className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      )}{" "}
    </>
  );
}

export default Authentication;