import React, { useState, useEffect, ChangeEvent, useContext } from "react";
import { formatDate, formatedTime, dateTime } from "../../../../utils/utility";
import { Button, IconButton, Pagination, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { User_Context } from "../../../../Contexts/User";
import { API_Endpoint } from "../../../../Configuration/Settings";
import { encryptData } from "../../../../utils/encryptData";
import { decryptData } from "../../../../utils/decryptData";
import { useParams } from "react-router-dom";
import Loader from "../../../../Components/Loader/Loader";
// Css

import "./WalletHistory.css";

// Components
import TableFiltersSearchBox from "../../../../Components/TableFilters/TableFiltersSearchBox";
import TableFiltersSelectBox from "../../../../Components/TableFilters/TableFiltersSelectBox";
import TableFilterDatePicker from "../../../../Components/TableFilters/TableFilterDatePicker";
import { values } from "lodash";
import { log } from "console";
import Stateful_Select from "../../../../Components/Dropdown/Dropdown";
import Reusable_Input from "../../../../Components/InputField/InputField";
interface Description {
  eventName?: string;
  marketName?: string;
  transactionId?: string;
  transactionType?: string;
  marketId?: string;
  rate?: string;
  betType?: string;
  game?: string;
  paymentGatewayName?: string;
  paymentType?: string;
  reason?: string;
  remark?: string;
  stake?: string;
  runnerName?: string;
  run?: string;
  updatedBy?: { name?: string };
  gameReference?: string;
  settlementId?: string;
  category?: string;
  amount?: number;
  [key: string]: any;
}
function WalletHistoryTable() {
  const [tableData, setTableData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<string>("10");
  const [totalUser, setTotalUser] = useState<number>(0);
  const { User_ID } = useParams();
  const { User } = useContext(User_Context);
  const [loading, setLoading] = useState(false);
  const typeArray = [
    { id: "CR", value: "Credited" },
    { id: "DR", value: "Debited" },
  ];
  const [selectedType, setSelectedType] = useState("");
  const [actionType, setActionType] = useState("");
  const [actionSearchKey, setActionSearchKey] = useState("");
  const [transactionTypeSearchKey, setTransactionTypeSearchKey] = useState("");
  const [settlementIdSearchKey, setSettlementIdSearchKey] = useState("");
  const [marketIdSearchKey, setMarketIdSearchKey] = useState('');
  const [marketNameSearchKey, setMarketNameSearchKey] = useState("");
  const [providerNameSearchKey, setProviderNameSearchKey] = useState("");
  const [amountSearchKey, setAmountSearchKey] = useState("");
  const [descriptionSearchkey, setDescriptionSearchKey] = useState<string>("");
  const [descriptionAction, setDescriptionAction] = useState<string>("");
  const [isSelectBoxUsed, setIsSelectBoxUsed] = useState(false);
  const [isSelectBox, setIsSelectBox] = useState(true);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const descriptionActionOption: { id: any; value: string }[] = [
    { id: "select", value: "Select" },
    { id: "transactionId", value: "Transaction ID" },
    { id: "settlementId", value: "settlement ID" },
    { id: "description.marketId", value: "Market ID" },
    { id: "description.BetType", value: "Bet Type" },
    { id: "description.marketName", value: "Market Name" },
    { id: "description.gameReference", value: "Game Reference" },
    { id: "description.game", value: "Game" },
    { id: "description.rounde", value: "Rounde" },
  ];

const onSearch = (isChecked?:any) => {
    setLoading(true);
    let filter: any = {};

    if (marketIdSearchKey) {
        filter["description.marketId"] = Number(marketIdSearchKey);
    }
    if (marketNameSearchKey) {
      filter.marketName = marketNameSearchKey;
    }
    if (providerNameSearchKey) {
      filter.providerName = providerNameSearchKey;
    }
    if (actionSearchKey) {
      filter.action = actionSearchKey;
    }
    if (amountSearchKey) {
      filter.amount = amountSearchKey;
    }
    if (selectedType) {
      filter.transactionType =
        selectedType.toLowerCase() === "credited" ? "CR" : selectedType;
    }
    if (isChecked !== "alldata" && descriptionSearchkey && descriptionAction) {
      filter[descriptionAction] = descriptionSearchkey;  
    }
    const filtersApplied = Object.keys(filter).length > 0;
    let pageNo = filtersApplied ? 1 : currentPage;
    let data: any = {};
    if (startDate && endDate) {
      data = {
        startDate: dateTime(startDate),
        endDate: dateTime(endDate)
      };
    } else if (!filtersApplied) {
      const currentDate = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0];
      data = {
        startDate: dateTime(currentDate),
        endDate: dateTime(currentDate)
      };
    }
    let payload: {
      itemsPerPage: number;
      pageNo: number;
      startDate:any;
      endDate:any;
      filter: { [key: string]: string | number };
    } = {
      itemsPerPage: Number(itemsPerPage),
      pageNo: pageNo,
      ...data,
      filter: {
        userId: User_ID?.toString() ?? "",
        ...filter,
      },
    };
    const createConfig = (endpoint: any) => ({
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_Endpoint}/${endpoint}`,
      headers: { Authorization: `Bearer ${User.token}` },
      data: { token: encryptData(payload) },
    });
    const config1 = createConfig("wallet-History");
    const config2 = createConfig("wallet-History/customer");
    Promise.all([axios.request(config1), axios.request(config2)])
      .then(([response1, response2]) => {
        const data1 = decryptData(response1.data.data);
        const data2 = decryptData(response2.data.data);
        const filtersApplied = Object.keys(filter).length > 0;
        if (filtersApplied) {
          setTableData(data2.payload.items);
          setTotalPages(data2.payload.totalPages);
          setTotalUser(data2.payload.total);
        } else {
          setTableData(data1.payload.walletHistory);
          setTotalPages(data1.payload.totalPages);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handleSubmitClick = () => {
    onSearch();
  }
  const handleItemsPerPageChange = (newValue: any) => {
    setItemsPerPage(newValue);
    onSearch(); 
  };
  const handleProviderNameSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setProviderNameSearchKey(event.target.value);
  };
  const handleActionSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setActionSearchKey(event.target.value);
  };
  const handleAmountSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setAmountSearchKey(event.target.value);
  };
  const handleTransactionTypeSearch = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedType(event.target.value);
  };
  const onChangeActionDecription = (value: string) => {
    setDescriptionAction(value);
    setIsSelectBoxUsed(true);
    setIsSelectBox(false);
  };

  const handleDescriptionDetailSearch = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setDescriptionSearchKey(value);
    if (value === "") {
      setIsSelectBoxUsed(false);
      setDescriptionAction("select");
      onSearch("alldata")
    }
  };
  const clearDate = () => {
    setStartDate('')
    setEndDate('')
  }
  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    localStorage.setItem('currentPage', page.toString());
  };
  useEffect(() => {
    const savedPage = localStorage.getItem('currentPage');
    if (savedPage) {
      setCurrentPage(parseInt(savedPage, 10)); // Convert the saved page to number
    }
  }, []);
  useEffect(() => {
    onSearch();
  }, [selectedType, currentPage, itemsPerPage]);

  const getBackgroundStyle = (action: any) => {
    
    switch (action) {
      case "Win":
        return "rgb(255 0 149 / 22%)";
      case "Bonus Transfer":
        return "rgba(255, 255, 0, 0.36)";
      case "Roll Back":
        return "#ff7d00d6";
      case "Deposit":
        return "#84d184";
      case "Settled":
        return "rgb(255 0 149 / 22%)";
      case "withdrawal request":
        return "rgb(0 225 21 / 31%)";
      default:
        return "";
    }
  };
  const getFontSizeStyle = (action: any) => {
    switch (action) {
      case "Win":
        return "15px";
      case "Deposit":
        return "15px";
      case "Settled":
        return "15px";
      case "withdrawal request":
        return "15px";
      default:
        return "14px";
    }
  };
  const getFontWeightStyle = (action: any) => {
    switch (action) {
      case "Win":
        return "700";
      case "Deposit":
        return "700";
      case "Settled":
        return "700";
      case "withdrawal request":
        return "700";
      default:
        return "500";
    }
  };

  return (
    <>
    {loading && (<Loader />)}
      <div className="row">
      {/* {title && <h2>{title}</h2>} */}
      <div className="row main-div">
                <div className="col-6 col-xl-2 col-sm-4 btn-top role-mob role-web">
                    <label className="lbl">Items Per Page</label>
                    <Stateful_Select
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                        options={["75","150", "200","500"]}
                    />
                </div>
                <div className="col-6 col-xl-2 col-sm-4 btn-top role-mob role-web">
                    <label className="lbl m-1">Market ID</label>
                    <Reusable_Input
                        label={""}
                        value={marketIdSearchKey}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setMarketIdSearchKey(event.target.value);
                        }}
                        type={"text"}
                        fullWidth={true}
                        error={false}
                        helperText={""}
                        customInputTextStyles={{
                            padding: '6px',
                        }}
                    />
                </div>
                <div className="col-6 col-xl-2 col-sm-4 btn-top role-mob role-web d-flex align-items-end submit-wallet">
                    <Button
                        onClick={handleSubmitClick}
                        className="sech-button px-2 py-1"
                        variant="contained"
                        disabled={!marketIdSearchKey?.length}
                    >
                        Submit
                    </Button>
                </div>
            </div>
      <div className="container-fluid">
                <div className="row tp-form">
                  <div className="col-6 col-xl-2 col-sm-3 pdrt">
                    <label className="lbl">From Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="From Date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="col-6 col-xl-2 col-sm-3 pdrt">
                    <label className="lbl">To Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="To Date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className="col-6 col-xl-2 col-sm-3 pdrt">
                    <label className="lbl"></label>
                    <a onClick={() => onSearch()} className="sechBtn mt-1" >
                      Apply
                    </a>
                  </div>
                  <div className="col-6 col-xl-2 col-sm-3 pdrt">
                    <label className="lbl"></label>
                    <a onClick={clearDate} className="sechBtn mt-1">
                      Clear Dates
                    </a>
                  </div>
                </div>
              </div>
      <div className="table-responsive">
        <table className="table table-view table-wallet">
          <thead>
            <tr>
              <th>#</th>
              <th>Provider In Page</th>
              <th>Action In Page</th>
              <th>Detail In Page</th>
              <th>Commission Amount In Page</th>
              <th>Type In Page</th>
              <th>Opening Balance In Page</th>
              <th>Amount In Page</th>
              <th>Closing Balance In Page</th>
              <th>Created On In Page</th>
              {/* <th>Updated On</th> */}
            </tr>
          </thead>
          <thead>
            <tr>
              <th></th>
              <th>
                <div>
                  <input
                    value={providerNameSearchKey}
                    onChange={handleProviderNameSearch}
                    placeholder=" Search provider name"
                  />
                  <IconButton
                    onClick={onSearch}
                    type="button"
                    sx={{ p: "10px 0px" }}
                    aria-label="search"
                    className="icon-button-wallet"
                  >
                    <SearchIcon />
                  </IconButton>
                </div>
              </th>

              <th>
                <div>
                  <input
                    value={actionSearchKey}
                    onChange={handleActionSearch}
                    placeholder=" Search action type"
                  />
                  <IconButton
                    onClick={onSearch}
                    type="button"
                    sx={{ p: "10px 0px" }}
                    aria-label="search"
                    className="icon-button-wallet"
                  >
                    <SearchIcon />
                  </IconButton>
                </div>
              </th>
              <th>
                {!isSelectBoxUsed ? (
                  <TableFiltersSelectBox
                    value={descriptionAction}
                    options={descriptionActionOption}
                    onChange={(e: any) => onChangeActionDecription(e.target.value)}
                  />
                ):(
                  <>
                  <input
                    type="text"
                    placeholder={`Search by ${descriptionAction}`}
                    value={descriptionSearchkey}
                    onChange={handleDescriptionDetailSearch}
                  />
                  <IconButton
                      onClick={onSearch}
                      type="button"
                      sx={{ p: "10px 0px" }}
                      aria-label="search"
                      className="icon-button-wallet"
                    >
                  <SearchIcon />
                  </IconButton>
                  </>
                  )}
              </th>
              <th></th>

              <th>
                <TableFiltersSelectBox
                  value={selectedType}
                  options={typeArray}
                  onChange={handleTransactionTypeSearch}
                />
              </th>
              <th>
              </th>
              <th>
                <div>
                  <input
                    value={amountSearchKey}
                    onChange={handleAmountSearch}
                    placeholder=" Search by amount"
                  />
                  <IconButton
                    onClick={onSearch}
                    type="button"
                    sx={{ p: "10px 0px" }}
                    aria-label="search"
                    className="icon-button-wallet"
                  >
                    <SearchIcon />
                  </IconButton>
                </div>
              </th>
              <th>
              </th>
              <th>
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((item: any, index: number) => (
              
              <tr
                style={{
                  backgroundColor: item.action==="withdrawal rejected"?"#FC2727":(item.action==="reverse withdrawal by user"?"#FF6833":getBackgroundStyle(item.action)),
                }}
                key={item._id}
              >
                <td>
                  {index +
                    1 +
                    (currentPage && itemsPerPage
                      ? (currentPage - 1) * parseInt(itemsPerPage)
                      : 0)}
                </td>
                <td>{item.providerName}</td>
                <td>{item.action}</td>
                <td>
                  {item.description?.eventName && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        EventName:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description.eventName} `}</span>
                      <br />
                    </>
                  )}
                   {item.description?.competitionName && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        Competition Name:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description.competitionName} `}</span>
                      <br />
                    </>
                  )}
                  
                  {item.description?.marketName && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        MarketName:{" "}
                      </span>
                      <span className="wallet-color">
                        {" "}
                        <b>{`${item.description.marketName} `}</b>
                      </span>
                      <br />
                    </>
                  )}

                  {item.description?.transactionId && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        TrasactionId:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description.transactionId} `}</span>
                    </>
                  )}

                  {item.description?.marketId && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        MarketId:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description.marketId} `}</span>
                      <br />
                    </>
                  )}

                  {item.description?.rate && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        Rate:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description.rate} `}</span>
                    </>
                  )}

                  {item.description?.betType && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        BetType:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description.betType} `}</span>
                    </>
                  )}

                  {item.description?.game && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        BetOn:{" "}
                      </span>
                      <span className="wallet-color">
                        <b
                          style={{ fontSize: "15px" }}
                        >{`${item.description.game} `}</b>
                      </span>
                      <br />
                    </>
                  )}

                  {item.description?.paymentGatewayName && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        GatewayName:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description.paymentGatewayName} `}</span>
                    </>
                  )}

                  {item.description?.paymentType && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        PaymentType:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description.paymentType} `}</span>
                      <br />
                    </>
                  )}

                  {item.description?.reason && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        Reason:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description.reason} `}</span>
                    </>
                  )}

                  {item.description?.remark && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        Remark:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description.remark} `}</span>
                    </>
                  )}
                  {item.description?.stake && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        Stake:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description.stake} `}</span>
                    </>
                  )}
                  {item.description?.runnerName && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        RunnerName:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description.runnerName} `}</span>
                    </>
                  )}
                  {item.description?.run && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        Run:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description.run} `}</span>
                    </>
                  )}
                  {item.description?.updatedBy?.name && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        UpdatedBy:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description.updatedBy.name} `}</span>
                    </>
                  )}
                  {item.description?.gameReference && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        GameName:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description?.gameReference} `}</span>
                    </>
                  )}
                  {item.description?.settlementId && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        SettlementId:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description?.settlementId} `}</span>
                    </>
                  )}
                  {item.description?.category && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        Category:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description?.category} `}</span>
                    </>
                  )}
                  {item.description?.Eventname && (
                    <>
                      <span
                        className="wallet-span"
                        style={{ fontWeight: "bold" }}
                      >
                        Event Name:{" "}
                      </span>
                      <span className="wallet-color">{`${item.description?.Eventname} `}</span>
                    </>
                  )}
                </td>
                {/* <td>{item?.description?.commissionAmount}</td> */}
                <td>
                  {item.providerName === "Qtech"
                    ? item?.description?.commission
                    : item?.description?.commissionAmount}
                </td>
                <td>{`${
                  item.transactionType === "CR" ? "Credited" : "Debited"
                }`}</td>
                <td>{Math.round(item.lastBalance)}</td>
                <td
                  style={{
                    fontSize: getFontSizeStyle(item.action),
                    fontWeight: getFontWeightStyle(item.action),
                  }}
                >
                  {item.amount}
                </td>
                <td>{Math.round(item.balance)}</td>
                <td>{`${formatDate(item.createdOn)} ${formatedTime(
                  item.createdOn
                )}`}</td>
                {/* <td>{`${formatDate(item.updatedOn)} ${formatedTime(item.updatedOn)}`}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: 20,
          }}
        >
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              color="secondary"
              page={currentPage}
              onChange={onPageChange}
            //   onChange={(Event, New_Page) => setCurrentPage(New_Page)}
            />
          </Stack>
        </div>
      </div>
    </div>
    </>
  );
}

export default WalletHistoryTable;