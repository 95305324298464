import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API_Endpoint } from '../../Configuration/Settings';
import { encryptData } from '../../utils/encryptData';
import { decryptData } from '../../utils/decryptData';
import StatCard from '../Statistics/StatCard';
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import "./CustomerAllotment.css";
import Loader from '../Loader/Loader';

type reportType = {
    feedBackCompleted: number,
    handleCustomer: number,
    handleCall: number,
    spentCallTime: number,
    incomingMissedCall: number,
    outgoingMissedCall: number,
}

const CallerReport = () => {
    let location = useLocation();
    let data = location.state.data;
    const token = localStorage.getItem("token");
    const [loader, setLoader] = useState(false);
    // Initialize report state with proper typing
    const [report, setReport] = useState<reportType>({
        feedBackCompleted: 0,
        handleCustomer: 0,
        handleCall: 0,
        spentCallTime: 0,
        incomingMissedCall: 0,
        outgoingMissedCall: 0,
    });

    type DepositItem = {
        count: number;
        totalAmount: number;
    };

    type DepositData = {
        depositData: DepositItem[];
        coinData: DepositItem[];
    };

    // Initialize the state with this type
    const [depositData, setDepositData] = useState<DepositData | null>(null);




    useEffect(() => {
        try {
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${API_Endpoint}/SubAdmin/caller-report`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: { token: encryptData(data) }
            };
            setLoader(true);
            axios.request(config)
                .then((response) => {
                    if (response.data.data) {
                        let decrypted = decryptData(response.data.data);
                        setReport(decrypted.payload);
                        setDepositData(decrypted.payload.depositData)
                        setLoader(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast.error(error);
                    setLoader(false);
                });

        } catch (error: any) {
            console.log(error);
            toast.error(error.message);
            setLoader(false)
        }
    }, [data]);

    return <>
        {loader && <Loader />}
        <div className="caller-report-section">
            <div className="count-and-amount-details" >
                <div className="col-12 col-xl-2 col-sm-4 pdrt d-flex align-items-center m-3">
                    <label className="lbl"></label>
                    <b>
                        automatic deposit : {`(${depositData?.depositData[0]?.count || 0}) ${depositData?.depositData[0]?.totalAmount || 0}`}
                    </b>
                </div>
                <div className="col-12 col-xl-2 col-sm-4 pdrt d-flex align-items-center m-3">
                    <label className="lbl"></label>
                    <b>
                        scanner deposit : {`(${depositData?.coinData[0]?.count || 0}) ${depositData?.coinData[0]?.totalAmount || 0}`}
                    </b>
                </div>

            </div>
            <div className="caller-report-section-container">
                <StatCard
                    title={"Handle Customer"}
                    icon={faUserCircle}
                    value={report.handleCustomer}
                    className={"fa fa-shopping-cart mid-icon  report-card"}
                />
                <StatCard
                    title={"FeedBack Completed"}
                    icon={faUserCircle}
                    value={report.feedBackCompleted}
                    className={"fa fa-shopping-cart mid-icon report-card"}
                />
                <StatCard
                    title={"Handle Call"}
                    icon={faUserCircle}
                    value={report.handleCall}
                    className={"fa fa-shopping-cart mid-icon report-card"}
                />
                <StatCard
                    title={"Incoming MissedCall"}
                    icon={faUserCircle}
                    value={report.incomingMissedCall}
                    className={"fa fa-shopping-cart mid-icon report-card"}
                />
                <StatCard
                    title={"Outgoing MissedCall"}
                    icon={faUserCircle}
                    value={report.outgoingMissedCall}
                    className={"fa fa-shopping-cart mid-icon report-card"}
                />
                <StatCard
                    title={"Spent CallTime"}
                    icon={faUserCircle}
                    value={`${(report.spentCallTime / 60).toFixed(2)} minutes`}
                    className={"fa fa-shopping-cart mid-icon report-card"}
                />

            </div>
        </div>
    </>
}

export default CallerReport;
