import React, { useContext, useState, useEffect } from 'react';
import { encryptData } from '../../../../utils/encryptData';
import { decryptData } from '../../../../utils/decryptData';
import { API_Endpoint } from '../../../../Configuration/Settings';
import { useNavigate } from 'react-router-dom';
import { User_Context } from '../../../../Contexts/User';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import { dateTime } from '../../../../utils/utility';

// Components
import Loader from '../../../../Components/Loader/Loader';
import Sidenav from '../../../../Components/SideNavigation/SideNavigation';
import Breadcrumbs from '../../../../Components/Breadcrumbs/Breadcrumbs';
import BonusWalletFundRequestCard from '../../../../Components/BonusWalletFundRequestCard/BonusWalletFundRequestCard';

// Styles
import './FundRequestBonusWallet.css';

interface TotalWalletData {
    pendingCount: number
    totalAmountTransferToMainWallet: number
    totalBonusWallet: number
    totalBonusWalletCount: number
    totalCountTransferToMainWallet: number
    totalPendingAmount: number
}

function FundRequestBonusWallet() {
    const [loading, setLoading] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");

    const [totalAmount, setTotalAmount] = useState<TotalWalletData>();

    const [approvedData, setApprovedData] = useState([]);
    const [pendingData, setPendingData] = useState([]);
    const [totalBonusWalletData, setTotalBonusWalletData] = useState([]);

    const [isAllData, setIsAllData] = useState(false)

    const { User } = useContext(User_Context)
    const navigate = useNavigate();

    const getAllBonusWalletFundRequests = (getAll: boolean) => {
        setLoading(true)
        const token = localStorage.getItem("token");
        let data = {}
        if (startDate && endDate) {
            data = {
                startDate: dateTime(startDate),
                endDate: dateTime(endDate),
                allData: false
            };
        } else {
            // const currentDate = new Date().toISOString().split('T')[0];
            const currentDate = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0];
            data = {
                startDate: dateTime(currentDate),
                endDate: dateTime(currentDate),
                allData: false
            };
        }

        if (getAll) {
            setIsAllData(true)
            // const currentDate = new Date().toISOString().split('T')[0];
            const currentDate = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0];
            data = {
                allData: true
            }
        }

        if (token) {
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${API_Endpoint}/bonus-wallet/fund-request`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: { token: encryptData(data) }
            };

            axios
                .request(config)
                .then((response) => {
                    response.data.data = decryptData(response.data.data)
                    setTotalAmount(response.data.data.payload)
                    // setSubAdmin(response.data.data.payload.data)
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }



    const getRoles = () => {
        let config = {
            method: "post",
            url: `${API_Endpoint}/roles`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${User.token}`,
            },
            data: { token: encryptData({}) },
        };

        axios.request(config).then((response) => {
            response.data.data = decryptData(response.data.data);
            const roles = response.data.data.payload
            const foundRole: any = roles.find((item: any) => item._id as string === User.data.Role_ID)
            if (foundRole && foundRole.Name === 'Full Access') {
                User.data.permission = true
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        getAllBonusWalletFundRequests(false)
        getRoles()
    }, [])

    const clearDate = () => {
        setStartDate('')
        setEndDate('')
    }

    const onPendingNavigate = () => {
        navigate('/fund-request-bonus-wallet-table', { state: { startDate: startDate, endDate: endDate, type: 'pending', allData: isAllData } })
    }

    const onPendingApproved = () => {
        navigate('/fund-request-bonus-wallet-table', { state: { startDate: startDate, endDate: endDate, type: 'approved', allData: isAllData } })
    }

    const onTotalDataNavigate = () => {
        navigate('/fund-request-bonus-wallet-table', { state: { startDate: startDate, endDate: endDate, type: 'totalData', allData: isAllData } })
    }
    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className="g-sidenav-show  bg-gray-100">
                    <ToastContainer autoClose={2000} position="top-center" />
                    <Sidenav />
                    <main className="main-content position-relative">
                        <div style={{ background: "#fff" }}>
                            <Breadcrumbs tab={"Bonus Wallet Fund Request"} />
                            <div className="container-fluid mt-2">
                                <div className="row">
                                    <div className="row tp-form">
                                        <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                            <label className="lbl">From Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                placeholder="From Date"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                            <label className="lbl">To Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                placeholder="To Date"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                            <label className="lbl"></label>
                                            <a onClick={() => getAllBonusWalletFundRequests(false)} className="sechBtn mt-1">
                                                Apply
                                            </a>
                                        </div>
                                        <div className="col-6 col-xl-2 col-sm-3 pdrt">
                                            <label className="lbl"></label>
                                            <a id="alldata" onClick={() => getAllBonusWalletFundRequests(true)} className="sechBtn mt-1">
                                                All Data
                                            </a>
                                        </div>
                                        <div className="col-6 col-xl-2 col-sm-3 pdrt">
                                            <label className="lbl"></label>
                                            <a onClick={clearDate} className="sechBtn mt-1">
                                                Clear Dates
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid mt-3">
                                <div className="row">
                                    <div>
                                        <div className='col-xl-4 col-sm-6 user-deposit'>
                                            {totalAmount?.totalAmountTransferToMainWallet ? totalAmount?.totalAmountTransferToMainWallet && (
                                                <div style={{ cursor: 'pointer' }} className='spn-amt amt total-approved' onClick={() => onPendingApproved()}><b>Amount Transfer to Main Wallet {`(${totalAmount?.totalCountTransferToMainWallet}) - ${totalAmount?.totalAmountTransferToMainWallet}`}</b></div>
                                            ) : ""}
                                            {totalAmount?.totalPendingAmount ? totalAmount?.totalPendingAmount && (
                                                <div style={{ cursor: 'pointer' }} className='spn-amt amt total-pending' onClick={() => onPendingNavigate()}><b>Pending Requests for Main Wallet transfer {`(${totalAmount?.pendingCount}) - ${totalAmount?.totalPendingAmount}`}</b></div>
                                            ) : ""}
                                            {totalAmount?.totalBonusWallet ? totalAmount?.totalBonusWallet && (
                                                <div style={{ cursor: 'pointer' }} className='spn-amt amt total-reverse' onClick={() => onTotalDataNavigate()}><b>Total Bonus Wallet {`(${totalAmount?.totalBonusWalletCount}) - ${totalAmount?.totalBonusWallet}`}</b></div>
                                            ) : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid mt-3">
                                <div className='row'>
                                    {/* <div>
                                        {pendingData.length > 0 &&
                                            pendingData?.map((item: any) => (
                                                <div id={item._id} className='col-xl-4 col-sm-2  mt-3 mb-3'>
                                                    <BonusWalletFundRequestCard
                                                        id={item._id}
                                                        name={item.name}
                                                        amount={item.amount}
                                                        bonusWalletClosingBalance={item.bonusWalletClosingBalance}
                                                        bonusWalletOpenBalance={item.bonusWalletOpenBalance}
                                                        firstDepositPercentage={item.firstDepositPercentage}
                                                        status={item.status}
                                                        referralPercentage={item.referralPercentage}
                                                        createdOn={item.createdOn}
                                                        updatedOn={item.updatedOn}
                                                    />
                                                </div>
                                            ))
                                        }
                                        {approvedData.length > 0 &&
                                            approvedData?.map((item: any) => (
                                                <div id={item._id} className='col-xl-4 col-sm-2  mt-3 mb-3'>
                                                    <BonusWalletFundRequestCard
                                                        id={item._id}
                                                        name={item.name}
                                                        amount={item.amount}
                                                        bonusWalletClosingBalance={item.bonusWalletClosingBalance}
                                                        bonusWalletOpenBalance={item.bonusWalletOpenBalance}
                                                        firstDepositPercentage={item.firstDepositPercentage}
                                                        status={item.status}
                                                        referralPercentage={item.referralPercentage}
                                                        createdOn={item.createdOn}
                                                        updatedOn={item.updatedOn}
                                                    />
                                                </div>
                                            ))
                                        }
                                        {totalBonusWalletData.length > 0 &&
                                            totalBonusWalletData?.map((item: any) => (
                                                <div id={item._id} className='col-xl-4 col-sm-2  mt-3 mb-3'>
                                                    <BonusWalletFundRequestCard
                                                        id={item._id}
                                                        name={item.name}
                                                        amount={item.amount}
                                                        bonusWalletClosingBalance={item.bonusWalletClosingBalance}
                                                        bonusWalletOpenBalance={item.bonusWalletOpenBalance}
                                                        firstDepositPercentage={item.firstDepositPercentage}
                                                        status={item.status}
                                                        referralPercentage={item.referralPercentage}
                                                        createdOn={item.createdOn}
                                                        updatedOn={item.updatedOn}
                                                    />
                                                </div>
                                            ))
                                        }

                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </main >
                </div >)
            }
        </>
    )
}

export default FundRequestBonusWallet;