export const passwordRegex = /(?=.{8,})/;
// export const passwordReg = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
export const emailRegex =
  /^(([^-`\\\]!@#$%^&*()+=_,?><';:"{}/[|.\s]+(\.[^-`\\\]!@#$%^&*()+=_,?><';:"{}/[|.\s]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const mobileRegex = /^[6-9]\d{9}$/;

export const dateTime = (timestamp : string) => new Date(timestamp).toISOString().split('T')[0]

export const formatedTime = (timestamp: string) => {
  const date = new Date(timestamp);
  const istDate = new Date(date.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));
  const hours = istDate.getHours();
  const minutes = istDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}
;

export const formatDate = (date: string) => 
{
  const day = new Date (date).getDate().toString().padStart(2, "0");
  const month = (new Date (date).getMonth() + 1).toString().padStart(2, "0");
  const year = new Date (date).getFullYear().toString();

  return `${day}-${month}-${year}`;
};