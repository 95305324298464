import React, { useEffect, useState } from 'react'
import Sidenav from '../../../../Components/SideNavigation/SideNavigation'
import Breadcrumbs from '../../../../Components/Breadcrumbs/Breadcrumbs'
import { dateTime, formatDate, formatedTime } from '../../../../utils/utility';
import { API_Endpoint } from '../../../../Configuration/Settings';
import axios from 'axios';
import { encryptData } from '../../../../utils/encryptData';
import { decryptData } from '../../../../utils/decryptData';
import { useLocation } from 'react-router-dom';
import { Pagination, Stack } from '@mui/material';
import Loader from '../../../../Components/Loader/Loader';
import { useNavigate } from "react-router-dom";

function GlobalUsersList() {
    const [loading, setLoading] = useState<boolean>(false);
    const [globalUserData, setGlobalUserData] = useState([]);
    const location = useLocation();
    const customProps = location.state;
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState<string>("20");
    const Navigate = useNavigate();


    useEffect(() => {
        setLoading(true)
        const token = localStorage.getItem("token");
        let data = {}    
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/User/get-user-globals`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: { token: encryptData(data) }
        };
        axios
            .request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data)
                setGlobalUserData(response.data.data.payload.items)
                setTotalPages(response.data.data.payload.totalPages ?? 1)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }, [currentPage])

    return (
        <>
            {loading && (<Loader />)}
            <div className="g-sidenav-show  bg-gray-100">
                <Sidenav />
                <main className="main-content position-relative">
                    <div style={{ background: "#f8f9fa" }}>
                        <Breadcrumbs tab={"Fairbets Global Users List"} />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table table-view">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Name</th>
                                                    <th>Mobile Phone</th>
                                                    <th>Email</th>
                                                    <th>City</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {globalUserData.map((item: any, index: number) => {
                                                    return (
                                                        <tr>
                                                            <td>{index + 1 + (currentPage && itemsPerPage ? (currentPage - 1) * parseInt(itemsPerPage) : 0)}</td>
                                                            <td key={item._id} onClick={() => {
																	const url = `/user-report/${item._id}/${item.name}`;
																	Navigate(url);
																	// window.open(url, "_blank");
																}} style={{ cursor: 'pointer' }}>
																	{item.name}
																</td>
                                                            <td>{item.mobile}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.city}</td>
                                                            <td>{formatDate(item.createdOn)}</td>
                                                            <td>{formatedTime(item.createdOn)}</td>
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                        <div>
                                            <ul className="pagination d-flex justify-content-center">
                                                <Stack spacing={2}>
                                                    <Pagination
                                                        count={totalPages}
                                                        color="secondary"
                                                        page={currentPage}
                                                        onChange={(Event, New_Page) =>
                                                            setCurrentPage(New_Page)
                                                        }
                                                    />
                                                </Stack>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <footer className="footer">
                                <div className="container-fluid">
                                    <div className="row align-items-center justify-content-lg-between">
                                        <div className="col-lg-6 mb-lg-0  ">
                                            <div className="copyright text-center text-sm text-muted text-lg-start">
                                                © fairbets.co
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                                                <li className="nav-item">
                                                    <a className="nav-link text-muted" target="_blank">
                                                        Home
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link text-muted" target="_blank">
                                                        About Us
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link text-muted" target="_blank">
                                                        Blog
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link pe-0 text-muted"
                                                        target="_blank"
                                                    >
                                                        License
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default GlobalUsersList