import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "../../../../Css/users.css";
import './Users.css'
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import Button from "@mui/material/Button";
import { IconButton, Pagination, Stack } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import axios from "axios";
import Loader from "../../../../Components/Loader/Loader";
import { API_Endpoint } from "../../../../Configuration/Settings";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Responsibilities, User_Types } from "../../../../Configuration/Enums";
import { User_Context } from "../../../../Contexts/User";
import { useLocation, useNavigate } from "react-router-dom";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import Stateful_Select from "../../../../Components/Dropdown/Dropdown";
import Reusable_Input from "../../../../Components/InputField/InputField";
import SearchBar from "../../../../Components/SearchBox/Search";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import { dateTime, formatDate, formatedTime } from "../../../../utils/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilSquare } from "@fortawesome/free-solid-svg-icons";
import SearchIcon from "@mui/icons-material/Search";

const Users = () => {
	const [registerPopup, setRegisterPopup] = useState<boolean>(false);
	const [editRegisterPopup, setEditRegisterPopup] = useState<boolean>(false);
	const [searchName, setSearchName] = useState<string>("");
	const [searchActiveUserName, setSearchActiveUserName] = useState<string>("");
	const [inactiveName, setInactiveName] = useState<string>("");
	const [inactiveMobile, setInactiveMobile] = useState<string>("");
	const [inactiveState, setInactiveState] = useState<string>("");
	const [inactiveCity, setInactiveCity] = useState<string>("");
	// const [itemsPerPage, setItemsPerPage] = useState<string>("10");
	const [itemsPerPageNonperform, setItemsPerPageNonperform] = useState<string>("10");
	const [itemsPerPageActiveUser, setItemsPerPageActiveUser] = useState<string>("10");
	// const [itemsPerPage, setItemsPerPage] = useState<number>(10);
	const [itemsPerPage, setItemsPerPage] = useState<number>(() => {
		const savedItemsPerPage = localStorage.getItem('walletHistoryItemsPerPage');
		return savedItemsPerPage ? parseInt(savedItemsPerPage, 10) : 10;
	});
	const [searchMob, setSearchMob] = useState<string>("");
	const [searchActiveUserMob, setSearchActiveUserMob] = useState<string>("");
	const [searchAccNo, setSearchAccNo] = useState<string>("");
	const [searchActiveUserAccNo, setSearchActiveUserAccNo] = useState<string>("");
	const [searchAadharNo, setSearchAadharNo] = useState<string>("");
	const [searchActiveUserAadharNo, setSearchActiveUserAadharNo] = useState<string>("");
	const [searchEmail, setSearchEmail] = useState<string>("");
	const [searchActiveUserEmail, setSearchActiveUserEmail] = useState<string>("");
	const [searchAddress, setSearchAddress] = useState<string>("");
	const [searchActiveUserAddress, setSearchActiveUserAddress] = useState<string>("");
	const [searchState, setSearchState] = useState<string>("");
	const [searchActiveUserState, setSearchActiveUserState] = useState<string>("");
	const [subAdminId, setSubAdminId] = useState<string>("");
	const [name, setName] = useState<string>("");
	const [nameHint, setNameHint] = useState<string>("");
	const [nameError, setNameError] = useState<boolean>(false);
	const [mobile, setMobile] = useState<string>("");
	const [mobileHint, setMobileHint] = useState<string>("");
	const [mobileError, setMobileError] = useState<boolean>(false);
	const [email, setEmail] = useState<string>("");
	const [emailHint, setEmailHint] = useState<string>("");
	const [emailError, setEmailError] = useState<boolean>(false);
	const [password, setPassword] = useState<string>("");
	const [passwordHint, setPasswordHint] = useState<string>("");
	const [passwordError, setPasswordError] = useState<boolean>(false);
	const [selectedRoleHint, setSelectedRoleHint] = useState<string>("");
	const [state, setState] = useState<[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [activeUserLoading, setActiveUserLoading] = useState<boolean>(false);
	// const [currentPage, setCurrentPage] = useState(1);
	const [currentPageNonperform, setCurrentPageNonperform] = useState(1);
	const [currentPageActiveUser, setcurrentPageActiveUser] = useState(1);
	// const [currentPage, setCurrentPage] = useState(1);
	const [currentPage, setCurrentPage] = useState<number>(() => {
		const storedPage = localStorage.getItem('currentPage');
		const parsedPage = storedPage ? parseInt(storedPage, 10) : 1;
		return isNaN(parsedPage) ? 1 : parsedPage;
	});
	const [totalPagesInactive,setTotalPagesInactive]=useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [totalPagesNonperform, setTotalPagesNonperform] = useState(1);
	const [inActiveUserCount,setTotalInActiveUsersCount]=useState<number>(0)
	const [totalPagesActiveUserForm, setTotalPagesActiveUserForm] = useState(1);
	const [users, setusers] = useState([]);
	const [inActiveUsersData,setInActiveUsersData]=useState([])
	const [totalUser, setTotalUser] = useState<number>(0)
	const [totalUserCount, setTotalUserCount] = useState<number>(0)
	const [totalActiveUser, setTotalActiveUser] = useState<number>(0)
	const [totalNonPerformUser, setTotalNonPerformUser] = useState<number>(0)
	const { User } = useContext(User_Context);
	const Navigate = useNavigate();
	const [Roles, Set_Roles] = useState([]);
	const [Selected_User_Type, Set_Selected_User_Type] = useState('');
	const [Selected_Role, Set_Selected_Role] = useState('');
	const [roleId, setRoleId] = useState('');
	const [Select_User_Type_Error_Status, Set_Select_User_Type_Error_Status] = useState(false);
	const [selectUserEditError, setSelectUserEditError] = useState(false);
	const [Select_Role_Error_Status, Set_Select_Role_Error_Status] = useState(false);
	const [Sub_Admins, Set_Sub_Admins] = useState([]);
	const [activeUsers, setActiveUsers] = useState([]);
	const [newActiveUsers, setNewActiveUsers] = useState([]);
	const [inActiveUsers, setInActiveUsers] = useState([]);

	const [startDate, setStartDate] = useState<string>("");
	const [endDate, setEndDate] = useState<string>("");
	const [permissionButton, setPermissionButton] = useState<boolean>(false)
	const [globalUser, setGlobalUser] = useState<number>(0);
	const [actionPopup, setActionPopup] = useState<string>("");
	const [remarkError, setRemarkError] = useState<boolean>(false);
	const [remarkHelperText, setRemarkHelpertext] = useState<string>("");
	const Change_User_Type = (Event_Object: SelectChangeEvent<string>) => {
		Set_Selected_User_Type(Event_Object.target.value);
		Set_Select_User_Type_Error_Status(false);
	}
	const [previousGetUsersFilter, setPreviousGetUsersFilter] = useState({});
	const location=useLocation();

	let {selectActiveCustomers}=location.state??false;

	const [Current_User_Type, Set_Current_User_Type] = useState<string>(() => {
		if(selectActiveCustomers){
			return User_Types.Active_User
		}
        const storedUserType = localStorage.getItem("Current_User_Type");
        return storedUserType ?? User_Types.User;
    });

	// 
	
	

	const [searchInActiveDepositUserEmail, setSearchInActiveDepositUserEmail] = useState<string>("");
	const [searchInactiveDepositAccNo, setSearchInactiveDepositAccNo] = useState<string>("");
	const [searchInActiveDepositUserState, setSearchInActiveDepositUserState] = useState<string>("");
	const [searchInactiveDepositName,setSearchInactiveDepositName]=useState<string>("");
	const [searchInActiveDepositUserAddress, setSearchInActiveDepositUserAddress] = useState<string>("");
	const [searchInActiveDepositUserMob, setSearchInActiveDepositUserMob] = useState<string>("");
	const [searchInActiveDepositUserAadharNo, setSearchInActiveDepositUserAadharNo] = useState<string>("");
	const [inActiveUsersPage,setInactiveUsersPage]=useState<number>(1);
	const [previousFilter, setPreviousFilter] = useState({});
	const Change_Role = (Event_Object: SelectChangeEvent<string>) => {
		Set_Selected_Role(Event_Object.target.value);
		Set_Select_Role_Error_Status(false);
	}
	const ChangeEditRole = (Event_Object: SelectChangeEvent<string>) => {
		setRoleId(Event_Object.target.value);
		setSelectUserEditError(false);
	}

	const handleName = (event: ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
		setNameError(false);
		setNameHint("");
	};

	const handleSearchName = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchName(event.target.value);
	};
	const handleSearchActiveUserName = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchActiveUserName(event.target.value);
	};
	const handleInactive = (event: ChangeEvent<HTMLInputElement>) => {
		setInactiveName(event.target.value);
	};

	const handleInactiveMobile = (event: ChangeEvent<HTMLInputElement>) => {
		setInactiveMobile(event.target.value);
	};

	const handleInactiveState = (event: ChangeEvent<HTMLInputElement>) => {
		setInactiveState(event.target.value);
	};

	const handleInactiveCity = (event: ChangeEvent<HTMLInputElement>) => {
		setInactiveCity(event.target.value);
	};

	const handleSearchMob = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchMob((event.target.value));
	};
	const handleSearchAccNo = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchAccNo((event.target.value));
	};
	const handleSearchAadharNo = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchAadharNo((event.target.value));
	};
	const handleSearchEmail = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchEmail(event.target.value);
	};
	const handleSearchAddress = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchAddress(event.target.value);
	};
	const handleSearchState = (event: ChangeEvent<HTMLInputElement>) => {
		
		setSearchState(event.target.value);
	};
	const handleSearchActiveUserMob = (event: ChangeEvent<HTMLInputElement>) => {
		
		setSearchActiveUserMob((event.target.value));
	};
	const handleSearchActiveUserAccNo = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchActiveUserAccNo((event.target.value));
	};
	const handleSearchActiveUserAadharNo = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchActiveUserAadharNo((event.target.value));
	};
	const handleSearchActiveUserEmail = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchActiveUserEmail(event.target.value);
	};
	const handleSearchActiveUserAddress = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchActiveUserAddress(event.target.value);
	};
	const handleSearchActiveUserState = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchActiveUserState(event.target.value);
	};
	const handleMobile = (event: ChangeEvent<HTMLInputElement>) => {
		setMobile(event.target.value);
		setMobileError(false);
		setMobileHint("");
	};
	const handleEmail = (event: ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		setEmailError(false);
		setEmailHint("");
	};
	const handlePassword = (event: ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
		setPasswordError(false);
		setPasswordHint("");
	};

	const handlePerPage = (newValue: any) => {
		// setItemsPerPage(newValue)
		const perPage = parseInt(newValue, 10);
		setItemsPerPage(perPage);
		localStorage.setItem('walletHistoryItemsPerPage', perPage.toString());
	}

	useEffect(() => {
        localStorage.setItem("Current_User_Type", Current_User_Type);
    }, [Current_User_Type]);

	const getAllUsers = (event?: any) => {
		setLoading(true);
	
		const isEmptyObject = (obj = {}) => {
            return Object.keys(obj).length === 0;
        };

		// Declare filter as Partial of the defined type
		let filter: Partial<{
			email: string;
			mobile: string;
			name: string;
			city: string;
			state: string;
			accountNumber: string;
			aadhaarNumber: string;
			deviceType: string;
		}> = {
			email: searchEmail || undefined,
			mobile: searchMob || undefined,
			name: searchName || undefined,
			city: searchAddress || undefined,
			state: searchState || undefined,
			accountNumber: searchAccNo || undefined,
			aadhaarNumber: searchAadharNo || undefined,
			deviceType: undefined, 
		};
	
		filter = Object.fromEntries(Object.entries(filter).filter(([_, v]) => v !== undefined));

        // Determine if a new filter is applied
        const isNewFilter = JSON.stringify(filter) !== JSON.stringify(previousGetUsersFilter);
        const pageNo = isNewFilter ? 1 : currentPage;
	
		 // Update the previous filter state if a new filter is applied
		 if (isNewFilter) {
           setPreviousGetUsersFilter(filter);
            setCurrentPage(1);
        }

		let data = {};
		if (startDate && endDate) {
			data = {
				itemsPerPage: itemsPerPage,
				// When the filter is not empty, set pageNo to 1, otherwise use currentPage
				pageNo: pageNo,
				filter: filter,
				startDate: dateTime(startDate),
				endDate: dateTime(endDate)
			};
		} else {
			data = {
				itemsPerPage: itemsPerPage,
				// When the filter is not empty, set pageNo to 1, otherwise use currentPage
				pageNo: pageNo,
				
				filter: filter
			};
		}
	
		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${API_Endpoint}/User/getAll`,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${User.token}`,
			},
			data: { token: encryptData(data) },
		};
	
		axios.request(config).then((response) => {
			response.data.data = decryptData(response.data.data);
			let data = response.data.data.payload.items.length <= 0;
	
			setusers(response.data.data.payload.items);
			setTotalPages(response.data.data.payload.totalPages);
			setTotalUserCount(response.data.data.payload.total);
	
			setLoading(false);
		}).catch((error) => {
			console.log(error);
			setLoading(false);
			toast.error(error.response.data.message);
		});
	};
	
	const getAllSubAdmins = () => {
		let config = {
			method: "post",
			url: `${API_Endpoint}/SubAdmin/getAll`,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${User.token}`,
			},
			data: {
				token: encryptData({
					itemsPerPage: itemsPerPage,
					pageNo: 1,
					filter: {}
				})
			},
		};

		axios.request(config).then((response) => {
			response.data.data = decryptData(response.data.data);
			Set_Sub_Admins(response.data.data.payload);
			setTotalUser(response.data.data.payload.length)

		}).catch((error) => {
			console.log(error);
			toast.error(error.response.data.message)
		});
	}

	const getActiveUsers = () => {
		setLoading(true)
		let data = {}
		let filter: { state?: string, mobile?: string, name?: string, city?: string } = {};
		if (inactiveName) {
			filter.name = inactiveName
		}
		if (inactiveMobile) {
			filter.mobile = inactiveMobile
		}
		if (inactiveState) {
			filter.state = inactiveState
		}
		if (inactiveCity) {
			filter.city = inactiveCity
		}
		if (startDate && endDate) {
			data = {
				pageNo: currentPageNonperform,
				itemPerPage: itemsPerPage,
				startDate,
				endDate,
				filter: filter
			};
		}
		else {
			data = {
				pageNo: currentPageNonperform,
				itemPerPage: itemsPerPage,
				filter: filter
			};
		}
		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${API_Endpoint}/User/nonPerformingUser`,
			headers: {
				Authorization: `Bearer ${User.token}`,
			},
			data: { token: encryptData(data) },
		};
		axios
			.request(config)
			.then((response) => {
				response.data.data = decryptData(response.data.data)
				setInActiveUsers(response.data.data.payload.items);
				setTotalPagesNonperform(response.data.data.payload.totalPages)
				setTotalNonPerformUser(response.data.data.payload.total)
				setLoading(false)
			})
			.catch((error: any) => {
				console.log(error.message);
				setLoading(false)
				toast.error(error.response.data.message)
			});

	}
	const getNewActiveUsers = () => {
		setActiveUserLoading(true)
		let filter: { email?: string, mobile?: string, name?: string, city?: string, state?: string, accountNumber?: string, aadhaarNumber?: string, deviceType?: string } = {};
		if (searchActiveUserEmail) {
			filter.email = searchActiveUserEmail;
		}
		if (searchActiveUserMob) {
			filter.mobile = searchActiveUserMob;
		}
		if (searchActiveUserAccNo) {
			filter.accountNumber = searchActiveUserAccNo;
		}
		if (searchActiveUserAadharNo) {
			filter.aadhaarNumber = searchActiveUserAadharNo;
		}
		if (searchActiveUserName) {
			filter.name = searchActiveUserName;
		}
		if (searchActiveUserAddress) {
			filter.city = searchActiveUserAddress;
		}
		if (searchActiveUserState) {
			filter.state = searchActiveUserState;
		}
		let data = {}
		if (startDate && endDate) {
			data = {
				itemsPerPage: itemsPerPage,
				pageNo: currentPageActiveUser,
				filter: filter,
				startDate: dateTime(startDate),
				endDate: dateTime(endDate)
			};
		} else {
			data = {
				itemsPerPage: itemsPerPage,
				pageNo: currentPageActiveUser,
				filter: filter
			};
		}
		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${API_Endpoint}/User/get-active-customers`,
			headers: {
				Authorization: `Bearer ${User.token}`,
			},
			data: { token: encryptData(data) },

		};
		axios
			.request(config)
			.then((response) => {
				response.data.data = decryptData(response.data.data);
				setActiveUsers(response.data.data.payload.user);
				setTotalPagesActiveUserForm(response.data.data.payload.totalPages)
				setTotalActiveUser(response.data.data.payload.count);
				setActiveUserLoading(false)
			})
			.catch((error: any) => {
				console.log(error.message);
				setActiveUserLoading(false)
				toast.error(error.response.data.message)
			});

	}

	const onSearch = () => {
		setLoading(true)
		
		let data = {}
		let filter: { state?: string, mobile?: string, name?: string, city?: string } = {};
		if (inactiveName) {
			filter.name = inactiveName
		}
		if (inactiveMobile) {
			filter.mobile = inactiveMobile
		}
		if (inactiveState) {
			filter.state = inactiveState
		}
		if (inactiveCity) {
			filter.city = inactiveCity
		}
		if (startDate && endDate) {
			data = {
				pageNo: currentPageNonperform,
				itemPerPage: itemsPerPage,
				startDate,
				endDate,
				filter: filter
			};
		}
		else {
			data = {
				pageNo: currentPageNonperform,
				itemPerPage: itemsPerPage,
				filter: filter
			};
		}
		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${API_Endpoint}/User/nonPerformingUser`,
			headers: {
				Authorization: `Bearer ${User.token}`,
			},
			data: { token: encryptData(data) },
		};
		axios
			.request(config)
			.then((response) => {
				response.data.data = decryptData(response.data.data)
				setLoading(false)
				setInActiveUsers(response.data.data.payload.items);
				setTotalPagesNonperform(response.data.data.payload.totalPages)
				setTotalNonPerformUser(response.data.data.payload.total)
			})
			.catch((error: any) => {
				console.log(error.message);
				setLoading(false)
				toast.error(error.response.data.message)
			});
	}

	const getInactiveDepositUsers = () => {
		setLoading(true);
	
		const isEmptyObject = (obj = {}) => {
            return Object.keys(obj).length === 0;
        };
;
	
			let filter: Partial<{
			email: string;
			mobile: string;
			name: string;
			city: string;
			state: string;
			accountNumber: string;
			aadhaarNumber: string;
			deviceType: string;
		}> = {
			email: searchInActiveDepositUserEmail || undefined,
			mobile: searchInActiveDepositUserMob || undefined,
			name: searchInactiveDepositName || undefined,
			city: searchInActiveDepositUserAddress || undefined,
			state: searchInActiveDepositUserState || undefined,
			accountNumber: searchInactiveDepositAccNo || undefined,
			aadhaarNumber: searchInActiveDepositUserAadharNo || undefined,
			deviceType: undefined,
		};
	
		filter = Object.fromEntries(Object.entries(filter).filter(([_, v]) => v !== undefined));
	

        // Determine if a new filter is applied
        const isNewFilter = JSON.stringify(filter) !== JSON.stringify(previousFilter);
        const pageNo = isNewFilter ? 1 : inActiveUsersPage;

        // Update the previous filter state if a new filter is applied
        if (isNewFilter) {
            setPreviousFilter(filter);
            setInactiveUsersPage(1);
        }

	
		let data = {};
		if (startDate && endDate) {
			data = {
				itemsPerPage: itemsPerPage,
				pageNo: pageNo,  // Correct pageNo assignment
				filter: filter,
				startDate: dateTime(startDate),
				endDate: dateTime(endDate)
			};
		} else {
			data = {
				itemsPerPage: itemsPerPage,
				pageNo: pageNo,  // Correct pageNo assignment
				filter: filter
			};
		}
	
		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${API_Endpoint}/transaction/inactive-customer-deposit`,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${User.token}`,
			},
			data: { token: encryptData(data) },
		};
	
	
		axios.request(config).then((response) => {
			response.data.data = decryptData(response.data.data);
	
			setInActiveUsersData(response.data.data.payload.items);
			setTotalPagesInactive(response.data.data.payload.totalPages);
			setTotalInActiveUsersCount(response.data.data.payload.total);
			setLoading(false);
		}).catch((error) => {
			console.log(error);
			setLoading(false);
			toast.error(error.response.data.message);
		});
	};
	
	
	const getData = () => {
		if (Current_User_Type === "activeUser") {
			getNewActiveUsers()
		}
		getGlobalUserCount()
		if (Current_User_Type === "Non_Performing_User") {
			getActiveUsers()
		}
		else if (Current_User_Type === "User") {
			getAllUsers()
		} else if (Current_User_Type === "Sub_Admin") {
			getAllSubAdmins()
		}else if(Current_User_Type==="In_Active_Deposit"){
			getInactiveDepositUsers();
		}
	}

	 // Call getInactiveDepositUsers whenever the component mounts or the page changes
	 useEffect(() => {
		getInactiveDepositUsers();
	}, [inActiveUsersPage, previousFilter]);

	useEffect(() => {
		getAllUsers();
	}, [currentPage,previousGetUsersFilter]);


	useEffect(() => {
		getData()
		onSearch()
	}, [ itemsPerPage, Current_User_Type, currentPageNonperform, itemsPerPageNonperform, currentPageActiveUser, itemsPerPageActiveUser]);

	useEffect(() => {
		getStateAndCity();
		getRoles()
		getGlobalUserCount()
	}, []);
	const getRoles = () => {
		let config = {
			method: "post",
			url: `${API_Endpoint}/roles`,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${User.token}`,
			},
			data: { token: encryptData({}) },
		};

		axios.request(config).then((response) => {
			response.data.data = decryptData(response.data.data);
			const roles = response.data.data.payload
			const foundRole: any = roles.find((item: any) => item._id as string === User.data.Role_ID)
			if (foundRole && foundRole.Name === 'Full Access') {
				setPermissionButton(true)
				User.data.permission = true
			}
			Set_Roles(response.data.data.payload);
		}).catch((error) => {
			console.log(error);
		});
	}


	const getStateAndCity = () => {
		axios
			.request({
				url: `${API_Endpoint}/Country`,
				method: "get",
				headers: { Authorization: `Bearer ${User.token}` },
			})
			.then((response) => {
				setState(response.data.data[0].states);
				// setLoading(false);
			})
			.catch((err: any) => {
				setLoading(false);
				console.log(`${err.message}`);
			});
	};

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		if (!name) {
			setNameError(true);
			setNameHint("enter name");
		}
		else if (!mobile) {
			setMobileError(true);
			setMobileHint("please enter mobile");
		}
		else if (!email) {
			setEmailError(true);
			setEmailHint("please enter email");
		}
		else if (!password) {
			setPasswordError(true);
			setPasswordHint("please enter password");
		}
		else if (!Selected_User_Type) {
			Set_Select_User_Type_Error_Status(true);
			setSelectedRoleHint("please enter role");
		}
		else if (!Selected_Role && Selected_User_Type === User_Types.Sub_Admin) {
			Set_Select_Role_Error_Status(true);
			setSelectedRoleHint("please enter role");
		}
		else {
			let data = Selected_User_Type === User_Types.Sub_Admin ? {
				name: name,
				mobile: mobile,
				email: email,
				password: password,
				Role_ID: Selected_Role
			} :
				{
					name: name,
					city: 'Unknown',
					state: 'Unknown',
					balance: 0,
					profitLoss: 0,
					mobile: mobile,
					email: email,
					password: password
				};
			let config = {
				method: "post",
				maxBodyLength: Infinity,
				url: Selected_User_Type === User_Types.Sub_Admin ? `${API_Endpoint}/SubAdmin` : `${API_Endpoint}/User`,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${User.token}`,
				},
				data: { token: encryptData(data) },
			};

			axios.request(config).then((response) => {
				if (response.data.success === true) {
					toast("User Registered Successfully");
				}
				setLoading(false);
				setRegisterPopup(false);
				setName("");
				setEmail("");
				setMobile("");
				Set_Selected_Role("");
				setPassword("");
				if (Selected_User_Type === User_Types.User) {
					setusers([data as never, ...users]);
				}
				if (Selected_User_Type === User_Types.Sub_Admin) {
					Set_Sub_Admins([data as never, ...Sub_Admins]);
				}
				if (Selected_User_Type === User_Types.Active_User) {
					setActiveUsers([data as never, ...activeUsers]);
				}
				if (Selected_User_Type === User_Types.Non_Performing_User) {
					setInActiveUsers([data as never, ...inActiveUsers]);
				}
				Set_Selected_User_Type('');
			}).catch((error) => {
				console.log(error);
				setLoading(false);
				toast.error(error.response.data.message)
			});
		}
	};

	const handleUpdateSudAdmin = (event: FormEvent) => {
		event.preventDefault()
		let data = {
			subAdminId: subAdminId,
			updatedBy: User.data._id,
			roleId: roleId
		}
		let config = {
			method: "post",
			url: `${API_Endpoint}/SubAdmin/update-subAdmin-role`,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${User.token}`,
			},
			data: {
				token: encryptData(data)
			},
		};
		axios.request(config).then((response) => {
			getAllSubAdmins()
			setEditRegisterPopup(false)
		}).catch((error) => {
			console.log(error);
		});
	}

	const handleCloseRegisterPopup = () => {
		setRegisterPopup(false);
		setName("");
		setEmail("");
		setMobile("");
		setSelectedRoleHint("");
		setPassword("");
	};
	const handleCloseEditRegisterPopup = () => {
		setEditRegisterPopup(false);
	};
	const clearDates = () => {
		setStartDate('')
		setEndDate('')
	}
	const handleEditSubAdmin = (id: string) => {
		setSubAdminId(id)
		setEditRegisterPopup(true)
	}

	const handleRemark = (e: ChangeEvent<HTMLInputElement>) => {
		setRemark(e.target.value);
		setRemarkError(false);
	};

	const handleAction = (_id: string, blockUser: boolean) => {
		setLoading(true)
		let data = {
			_id: _id,
			blockUser: blockUser
		}
		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${API_Endpoint}/User/blockAndUnblockUser`,
			headers: {
				Authorization: `Bearer ${User.token}`,
			},
			data: { token: encryptData(data) },
		};

		
		axios
			.request(config)
			.then(() => {
				setLoading(false)
				getAllUsers()
			})
			.catch((error: any) => {
				setLoading(false)
				toast.error(error.response.data.message)
			});
	}

	const [blockingUser_ID, setBlocking_UserId] = useState("");
	const [blockUser, setBlockUser] = useState<boolean>();
	const [openPopup, setOpenPopup] = useState<boolean>(false);
	const [remark, setRemark] = useState<string>("");
	const handleActionSubmit = () => {
		if (remark != "" && blockingUser_ID != "") {
			setOpenPopup(false);
			setLoading(true);
			let data = {
				_id: blockingUser_ID,
				blockUser: blockUser,
				blockUserReason: remark
			}

			let config = {
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_Endpoint}/User/blockAndUnblockUser`,
				headers: {
					Authorization: `Bearer ${User.token}`,
				},
				data: { token: encryptData(data) },
			};

			axios
				.request(config)
				.then((response) => {
					response.data.data = decryptData(response.data.data)
					setLoading(false);
					getAllUsers()
					setRemark("");
					setBlocking_UserId("")

				})
				.catch((error: any) => {
					console.log(error)
					setLoading(false)
					toast.error(error.response.data.message)
				});


		} else {
			toast.error("something wrong try again letter")
		}

	}

	const handleBlockAction = (_id: string, blockUser: boolean) => {
		setBlocking_UserId(_id);
		setBlockUser(blockUser)
		setOpenPopup(true);
	}


	const getGlobalUserCount = () => {
		setLoading(true)
		const token = localStorage.getItem("token");
		let data = {}
		if (startDate && endDate) {
			data = {
				startDate: dateTime(startDate),
				endDate: dateTime(endDate)
			};
		} else {
			const currentDate = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0];
			data = {
				startDate: dateTime(currentDate),
				endDate: dateTime(currentDate)
			};
		}
		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${API_Endpoint}/User/get-user-globals-count`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			data: { token: encryptData(data) }
		};
		axios
			.request(config)
			.then((response) => {
				response.data.data = decryptData(response.data.data)
				setGlobalUser(response.data.data.payload)
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
			});
	}
	useEffect(() => {
		localStorage.setItem('currentPage', currentPage.toString());
	}, [currentPage]);

	const handlePageChange = (event: React.ChangeEvent<unknown>, New_Page: number) => {
		setCurrentPage(New_Page);
	};



	const usersGlobalList = () => {
		let data = {
			startDate: startDate,
			endDate: endDate,
		}
		Navigate("/users-global-list", { state: data })
	}


	useEffect(() => {
		if (Current_User_Type === 'Non_Performing_User') {
			getActiveUsers()
		}
		if (Current_User_Type === 'activeUser') {
			getNewActiveUsers()
		}
	}, [itemsPerPage])


	useEffect(()=>{
		if(User.data.Role_ID==="658a877056138bb0bc4eba35"){
			let roles=Roles.filter((Role:any)=>Role.Name==="Calling Team");
			Set_Roles(roles)
		}
	},[Roles])

	return (
		<>
			{loading || activeUserLoading ? (
				<Loader />
			) : (
				<div className="g-sidenav-show  bg-gray-100">
					<ToastContainer autoClose={2000} position="top-center" />
					<Sidenav />
					<main className="main-content position-relative">

						<div>
							<Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
								<DialogContent>
									<form onSubmit={handleActionSubmit}>
										{actionPopup === "Approved" ? <div className="d-flex justify-content-center">
											<p>Are you sure ?</p>
										</div> : <div>
											<Reusable_Input required={true} type={"text"} label={"Please enter remark"} fullWidth={true} value={remark} error={remarkError} helperText={remarkHelperText} onChange={handleRemark} />
										</div>}
										<DialogActions>
											<Button
												className="btn-popup"
												variant="outlined"
												onClick={() => setOpenPopup(false)}
												color="primary"
											>
												Cancel
											</Button>
											<Button
												className="btn-popup"
												variant="outlined"
												type="submit"
												color="primary"

											>
												Submit
											</Button>
										</DialogActions>
									</form>
								</DialogContent>
							</Dialog>
						</div>
						<div style={{ background: "#f8f9fa" }}>
							<Breadcrumbs tab={"Users"} />
							<div className="container-fluid">
								<div className="row">
									<div className="row tp-form mb-1" style={{ display: 'flex' }}>
										{Current_User_Type!==User_Types.In_Active_Deposit && <>
										<div className="col-6 col-xl-2 col-sm-4 pdrt">
											<label className="lbl">From Date</label>
											<input
												type="date"
												className="form-control"
												placeholder="From Date"
												value={startDate}
												onChange={(e) => setStartDate(e.target.value)}
											/>
										</div>
										<div className="col-6 col-xl-2 col-sm-4 pdrt">
											<label className="lbl">To Date</label>
											<input
												type="date"
												className="form-control"
												placeholder="To Date"
												value={endDate}
												onChange={(e) => setEndDate(e.target.value)}
											/>
										</div>
										<div className="col-6 col-xl-2 col-sm-4 pdrt">
											<label className="lbl"></label>
											<a onClick={getData} className="sechBtn mt-1">
												Apply
											</a>
										</div>
										<div className="col-6 col-xl-2 col-sm-4 pdrt">
											<label className="lbl"></label>
											<a onClick={clearDates} className="sechBtn mt-1">
												clear dates
											</a>
										</div>
										</>
										}
										<div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-1">
											<label className="lbl">Select User Type</label>
											<Stateful_Select
												value={Current_User_Type}
												onChange={(newValue) => {
													Set_Current_User_Type(newValue)
												}}
												options={[User_Types.User,
												User_Types.Sub_Admin,
												User_Types.Active_User,
												User_Types.Non_Performing_User,User_Types.In_Active_Deposit]}
											/>
										</div>
										<div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-1">
											<label className="lbl">Items Per Page</label>
											<Stateful_Select
												// label="Items Per Page"
												value={itemsPerPage.toString()}
												onChange={(newValue: any) => handlePerPage(newValue)}
												options={["10", "25", "50", "75", "100"]}
											/>
										</div>
										{/* {User.data.Responsibilities.includes(Responsibilities.Register_New_User) && <div className="col-6 col-xl-2 col-sm-4 pdrt reg mt-1 role-mob2 d-flex align-items-center" style={{ width: "100px" }}>
											<label className="lbl"></label>
											<a onClick={() => setRegisterPopup(true)} className="sechBtn apply-btn">Register</a>
										</div>} */}
										{User.data.Responsibilities.includes(Responsibilities.Register_New_User) && 
										<div hidden={Current_User_Type===User_Types.In_Active_Deposit } className="col-6 col-xl-2 col-sm-4 pdrt">
											<label className="lbl"></label>
											<a onClick={() => setRegisterPopup(true)} className="sechBtn mt-1">
												Register
											</a>
										</div>}
										<div hidden={ Current_User_Type===User_Types.In_Active_Deposit  } className="col-6 col-xl-2 col-sm-4 pdrt" style={{ cursor: "pointer" }}>
											<label className="lbl"></label>
											<a onClick={usersGlobalList} className="sechBtn mt-1">
												Global User : {globalUser}
											</a>
										</div>
										<div className="col-6 col-xl-2 col-sm-4 pdrt reg mt-1 role-mob2 d-flex align-items-center mt-4">
											{/* <label className="lbl"></label> */}
											<b>
												Total user count : {
													Current_User_Type === User_Types.User? totalUserCount: Current_User_Type === User_Types.Sub_Admin
															? totalUser
															: Current_User_Type === User_Types.Non_Performing_User
																? totalNonPerformUser
																: Current_User_Type === User_Types.Active_User
																	? totalActiveUser
																	: Current_User_Type===User_Types.In_Active_Deposit?inActiveUserCount:null
																	 // Handle other cases or set a default value
																	
												}
											</b>

										</div>
									</div>
									<div>
										<Dialog
											open={registerPopup}
											onClose={() => setRegisterPopup(false)}
										>
											<DialogContent>
												<form>
													<div>
														<Reusable_Input label={"Full Name"} value={name} error={nameError} helperText={nameHint} onChange={handleName} type={"text"} fullWidth={true} />
													</div>
													<div className="mt-2">
														<Reusable_Input label={"Mobile"} value={mobile} error={mobileError} helperText={mobileHint} onChange={handleMobile} type={"text"} fullWidth={true} />
													</div>
													<div className="mt-2">
														<Reusable_Input label={"Email"} value={email} error={emailError} helperText={emailHint} onChange={handleEmail} type={"email"} fullWidth={true} />
													</div>
													<div className="mt-2">
														<Reusable_Input label={"Password"} value={password} error={passwordError} helperText={passwordHint} onChange={handlePassword} type={"password"} fullWidth={true} />
													</div>
													<div className="mt-2">
														<FormControl
															variant="outlined"
															fullWidth
															size="small"
														>
															<InputLabel>Select User Type</InputLabel>
															<Select
																MenuProps={{ disablePortal: true }}
																labelId="demo-select-small-label"
																id="demo-select-small"
																label="Select role"
																value={Selected_User_Type}
																onChange={Change_User_Type}
																error={Select_User_Type_Error_Status}
															>
																<MenuItem value={User_Types.User}>User</MenuItem>
																<MenuItem value={User_Types.Sub_Admin}>Sub-Admin</MenuItem>
															</Select>
														</FormControl>
													</div>
													{Selected_User_Type === User_Types.Sub_Admin &&
														<div className="mt-2">
															<FormControl
																variant="outlined"
																fullWidth
																size="small"
															>
																<InputLabel>Select Role</InputLabel>
																<Select
																	labelId="demo-select-small-label"
																	id="demo-select-small"
																	label="Select role"
																	value={Selected_Role}
																	onChange={Change_Role}
																	error={Select_Role_Error_Status}
																>
											
																	 {Roles.map((Role: any) => <MenuItem key={Role._id} value={Role._id}>{Role.Name}</MenuItem>)}
																</Select>
															</FormControl>
														</div>
													}
													<div className="mt-2">
														<DialogActions>
															<Button
																className="btn-popup"
																variant="outlined"
																onClick={handleCloseRegisterPopup}
																color="primary"
															>
																Cancel
															</Button>
															<Button
																className="btn-popup"
																variant="outlined"
																type="submit"
																color="primary"
																onClick={handleSubmit}
															>
																Register
															</Button>
														</DialogActions>

													</div>
												</form>
											</DialogContent>
										</Dialog>
									</div>
									<div>
										<Dialog
											open={editRegisterPopup}
											onClose={() => setEditRegisterPopup(false)}
										>
											<DialogContent>
												<form>
													<div className="mt-2">
														<FormControl
															variant="outlined"
															fullWidth
															size="small"
															onClick={handleSubmit}
														>
															<InputLabel>Select Role</InputLabel>
															<Select
																labelId="demo-select-small-label"
																id="demo-select-small"
																label="Select role"
																value={roleId}
																onChange={ChangeEditRole}
																error={selectUserEditError}
															>
																{Roles.map((Role: any) => <MenuItem key={Role._id} value={Role._id}>{Role.Name}</MenuItem>)}
															</Select>
														</FormControl>
													</div>

													<div className="mt-2">
														<DialogActions>
															<Button
																className="btn-popup"
																variant="outlined"
																onClick={handleCloseEditRegisterPopup}
																color="primary"
															>
																Cancel
															</Button>
															<Button
																className="btn-popup"
																variant="outlined"
																type="submit"
																color="primary"
																onClick={handleUpdateSudAdmin}
															>
																Submit
															</Button>
														</DialogActions>
													</div>
												</form>
											</DialogContent>
										</Dialog>
									</div>
									<div className="col-12 mt-2">
										<div className="table-responsive">
											{
												Current_User_Type === User_Types.User  && <> <table className="table table-view">
													<thead>
														<tr>
															<th></th>
															<th>Name</th>
															<th>User Bank Name</th>
															<th>User Encrypted Dp Id</th>
															<th>Mobile Phone</th>
															<th>Account Number</th>
															<th>Aadhar Number</th>
															<th>Email</th>
															<th>City</th>
															<th>State</th>
															<th>Device Type</th>
															<th>Platform</th>
															<th>Current App Version</th>
															<th>Updated App Version</th>
															<th>Balance</th>
															<th>Created</th>
															<th>Time</th>
															<th>Last Activity</th>
															{/* <th>Wager</th> */}

															<th>Bonus Balance</th>
															<th>Action</th>
															<th>Block User Reason</th>
														</tr>
													</thead>
													<thead>
														<tr className="bg-table">
															<th className="thdr"></th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchName}
																		onChange={handleSearchName}
																		onSearch={getAllUsers}
																		placeholder="Search by name"
																	/>
																</div>
															</th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchMob}
																		onChange={handleSearchMob}
																		onSearch={getAllUsers}
																		placeholder="Search by mobile"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchAccNo}
																		onChange={handleSearchAccNo}
																		onSearch={getAllUsers}
																		placeholder="Search by acc no"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchAadharNo}
																		onChange={handleSearchAadharNo}
																		onSearch={getAllUsers}
																		placeholder="Search by aadhar no"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchEmail}
																		onChange={handleSearchEmail}
																		onSearch={getAllUsers}
																		placeholder="Search by email"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchAddress}
																		onChange={handleSearchAddress}
																		onSearch={getAllUsers}
																		placeholder="Search by city"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchState}
																		onChange={handleSearchState}
																		onSearch={getAllUsers}
																		placeholder="Search by state"
																	/>
																</div>
															</th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															{/* <th className="thdr"></th> */}
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr"></th>
														</tr>
													</thead>
													<tbody>
														{users?.map((User: any, index: number) => (
															<tr id={User._id}>
																<td>{index + 1 + (currentPage && itemsPerPage ? (currentPage - 1) * itemsPerPage : 0)}</td>
																<td key={User._id} onClick={() => {
																	const url = `/user-report/${User._id}/${User.name}`;
																	Navigate(url);
																	// window.open(url, "_blank");
																}} style={{ cursor: 'pointer' }}>
																	{User.name}
																</td>
																<td>{User?.userBankName}</td>
																<td>{User?.encryptedUserName}</td>
																<td>
																	+{User?.countryCode || 91}
																	{User?.mobile}
																</td>
																<td>{User?.accountNumber}</td>
																<td>{User?.aadhaarNumber}</td>
																<td>{User?.email}</td>
																<td>
																	{User?.city}
																</td>
																<td>{User?.state}</td>
																<td>{User.deviceType}</td>
																<td>{User.subDomain}</td>
																<td>{User.currentAppVersion}</td>
																<td>{User.updatedAppVersion}</td>
																<td>{Math.floor(User?.balance)}</td>
																<td>
																	{formatDate(User?.createdOn)}
																</td>
																<td>
																	{formatedTime(User?.createdOn)}
																</td>
																<td>
																	{formatDate(User?.updatedAt) + " | " + formatedTime(User.updatedAt)}
																</td>
																{/* <td>{User.wager}</td> */}
																<td>{User?.bonusWalletBalance}</td>
																<td>
																	<span>
																		<Button
																			onClick={() => handleBlockAction(User._id, !User.blockUser)}
																			className="withdraw-btn"
																			variant="contained">{User.blockUser === true ? "Un Block" : "Block"}
																		</Button>
																	</span>
																</td>
																<td>{User?.blockUserReason}</td>
															</tr>
														))}
													</tbody>
												</table>
												</>
											}


											{/* Inactive users */}

											{
												Current_User_Type === User_Types.In_Active_Deposit && <> <table className="table table-view">
													<thead>
														<tr>
															<th></th>
															<th>Name</th>
															<th>User Bank Name</th>
															<th>User Encrypted Dp Id</th>
															<th>Mobile Phone</th>
															<th>Account Number</th>
															<th>Aadhar Number</th>
															<th>Email</th>
															<th>City</th>
															<th>State</th>
															<th>Device Type</th>
															<th>Current App Version</th>
															<th>Updated App Version</th>
															<th>Balance</th>
															<th>Created</th>
															<th>Time</th>
															<th>Last Activity</th>
															{/* <th>Wager</th> */}

															<th>Bonus Balance</th>
															<th>Action</th>
															<th>Block User Reason</th>
														</tr>
													</thead>
													<thead>
														<tr className="bg-table">
															<th className="thdr"></th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchInactiveDepositName}
																		onChange={(e:ChangeEvent<HTMLInputElement>)=>setSearchInactiveDepositName(e.target.value)}
																		onSearch={getInactiveDepositUsers}
																		placeholder="Search by name"
																	/>
																</div>
															</th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchInActiveDepositUserMob}
																		onChange={(e:ChangeEvent<HTMLInputElement>)=>setSearchInActiveDepositUserMob(e.target.value)}
																		onSearch={getInactiveDepositUsers}
																		placeholder="Search by mobile"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchInactiveDepositAccNo}
																		onChange={(e:ChangeEvent<HTMLInputElement>)=>setSearchInactiveDepositAccNo(e.target.value)}
																		onSearch={getInactiveDepositUsers}
																		placeholder="Search by acc no"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchInActiveDepositUserAadharNo}
																		onChange={(e:ChangeEvent<HTMLInputElement>)=>setSearchInActiveDepositUserAadharNo(e.target.value)}
																		onSearch={getInactiveDepositUsers}
																		placeholder="Search by aadhar no"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchInActiveDepositUserEmail}
																		onChange={(e:ChangeEvent<HTMLInputElement>)=>setSearchInActiveDepositUserEmail(e.target.value)}
																		onSearch={getInactiveDepositUsers}
																		placeholder="Search by email"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchInActiveDepositUserAddress}
																		onChange={(e:ChangeEvent<HTMLInputElement>)=>setSearchInActiveDepositUserAddress(e.target.value)}
																		onSearch={getInactiveDepositUsers}
																		placeholder="Search by city"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchInActiveDepositUserState}
																		onChange={(e:ChangeEvent<HTMLInputElement>)=>setSearchInActiveDepositUserState(e.target.value)}
																		onSearch={getInactiveDepositUsers}
																		placeholder="Search by state"
																	/>
																</div>
															</th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															{/* <th className="thdr"></th> */}
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr"></th>
														</tr>
													</thead>
													<tbody>
														{inActiveUsersData?.map((User: any, index: number) => (
															<tr id={User._id}>
																<td>{index + 1 + (inActiveUsersPage && itemsPerPage ? (inActiveUsersPage - 1) * itemsPerPage : 0)}</td>
																<td key={User._id} onClick={() => {
																	const url = `/user-report/${User._id}/${User.name}`;
																	Navigate(url);
																	// window.open(url, "_blank");
																}} style={{ cursor: 'pointer' }}>
																	{User.name}
																</td>
																<td>{User?.userBankName}</td>
																<td>{User?.encryptedUserName}</td>
																<td>
																	+{User?.countryCode || 91}
																	{User?.mobile}
																</td>
																<td>{User?.accountNumber}</td>
																<td>{User?.aadhaarNumber}</td>
																<td>{User?.email}</td>
																<td>
																	{User?.city}
																</td>
																<td>{User?.state}</td>
																<td>{User.deviceType}</td>
																<td>{User.currentAppVersion}</td>
																<td>{User.updatedAppVersion}</td>
																<td>{Math.floor(User?.balance)}</td>
																<td>
																	{formatDate(User?.createdOn)}
																</td>
																<td>
																	{formatedTime(User?.createdOn)}
																</td>
																<td>
																	{formatDate(User?.updatedAt) + " | " + formatedTime(User.updatedAt)}
																</td>
																{/* <td>{User.wager}</td> */}
																<td>{User?.bonusWalletBalance}</td>
																<td>
																	<span>
																		<Button
																			onClick={() => handleBlockAction(User._id, !User.blockUser)}
																			className="withdraw-btn"
																			variant="contained">{User.blockUser === true ? "Un Block" : "Block"}
																		</Button>
																	</span>
																</td>
																<td>{User?.blockUserReason}</td>
															</tr>
														))}
													</tbody>
												</table>
												</>
											}







											{
												Current_User_Type === User_Types.Sub_Admin && <table className="table table-view">
													<thead>
														<tr>
															<th></th>
															<th>Name</th>
															<th>Mobile Phone</th>
															<th>Email</th>
															<th>Role</th>
														</tr>
													</thead>
													<tbody>
														{Sub_Admins?.map((Sub_Admin: any, index: number) => (
															<tr key={Sub_Admin._id}>
																<td>{index + 1 + (currentPage && itemsPerPage ? (currentPage - 1) * itemsPerPage : 0)}</td>
																<td>
																	{Sub_Admin.name}
																</td>
																<td>
																	+{Sub_Admin.countryCode || 91}{Sub_Admin.mobile}
																</td>
																<td>{Sub_Admin.email}</td>
																<td>{Roles.find((Role: any) => Role._id === Sub_Admin.Role_ID) !== undefined ? (Roles.find((Role: any) => Role._id === Sub_Admin.Role_ID) as any).Name : ''}
																	{


																		permissionButton ?
																			<FontAwesomeIcon
																				className="fa fa-pencil-square icon-home icon-banner"
																				icon={faPencilSquare}
																				style={{ cursor: "pointer" }}
																				onClick={(id) => handleEditSubAdmin(Sub_Admin._id)}
																			/> : ""
																	}
																</td>
															</tr>
														))}
													</tbody>
												</table>
											}
											{
												Current_User_Type === User_Types.Active_User && <table className="table table-view">
													<thead>
														<tr>
															<th></th>
															<th>Name</th>
															<th>Mobile Phone</th>
															<th>Account Number</th>
															<th>Aadhar Number</th>
															<th>Email</th>
															<th>City</th>
															<th>State</th>
															<th>Device Type</th>
															<th>Balance</th>
															<th>Date</th>
															<th>Time</th>
														</tr>
													</thead>
													<thead>
														<tr className="bg-table">
															<th className="thdr"></th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchActiveUserName}
																		onChange={handleSearchActiveUserName}
																		onSearch={getNewActiveUsers}
																		placeholder="Search by name"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchActiveUserMob}
																		onChange={handleSearchActiveUserMob}
																		onSearch={getNewActiveUsers}
																		placeholder="Search by mobile"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchActiveUserAccNo}
																		onChange={handleSearchActiveUserAccNo}
																		onSearch={getNewActiveUsers}
																		placeholder="Search by acc no"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchActiveUserAadharNo}
																		onChange={handleSearchActiveUserAadharNo}
																		onSearch={getNewActiveUsers}
																		placeholder="Search by aadhar no"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchActiveUserEmail}
																		onChange={handleSearchActiveUserEmail}
																		onSearch={getNewActiveUsers}
																		placeholder="Search by email"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchActiveUserAddress}
																		onChange={handleSearchActiveUserAddress}
																		onSearch={getNewActiveUsers}
																		placeholder="Search by city"
																	/>
																</div>
															</th>
															<th className="thdr">
																<div className="d-flex justify-content-center">
																	<SearchBar
																		value={searchActiveUserState}
																		onChange={handleSearchActiveUserState}
																		onSearch={getNewActiveUsers}
																		placeholder="Search by state"
																	/>
																</div>
															</th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr"></th>
															<th className="thdr"></th>

														</tr>
													</thead>
													<tbody>
														{activeUsers?.map((active: any, index: number) => (
															<tr key={active._id}>
																<td>{index + 1 + (currentPageActiveUser && itemsPerPageActiveUser ? (currentPageActiveUser - 1) * parseInt(itemsPerPageActiveUser) : 0)}</td>
																<td onClick={() => {
																	const url = `/user-report/${active._id}/${active.name}`;
																	window.open(url, "_blank");
																}} style={{ cursor: 'pointer' }}>
																	{active.name}
																</td>
																<td>
																	+{active.countryCode || 91}{active.mobile}
																</td>
																<td>{active.accountNumber}</td>
																<td>{active.aadhaarNumber}</td>
																<td>{active.email}</td>
																<td>{active.city}</td>
																<td>{active.state}</td>
																<td>{active.deviceType}</td>
																<td>{Math.floor(active.balance)}</td>
																<td>{formatDate(active.createdOn)}</td>
																<td>{formatedTime(active.createdOn)}</td>
															</tr>
														))}
													</tbody>
												</table>
											}
											{
												Current_User_Type === User_Types.Non_Performing_User && <table className="table table-view">
													<thead>
														<tr>
															<th></th>
															<th>Name</th>
															<th>Mobile Phone</th>
															<th>Email</th>
															<th>State</th>
															<th>City</th>
															<th>Balance</th>
															<th>Deposit Amount</th>
															<th>Date</th>
															<th>Time</th>
														</tr>
													</thead>
													<thead>
														<tr>
															<th></th>
															<th>
																<div>
																	<input value={inactiveName} onChange={handleInactive} placeholder=" Search name" />
																	<IconButton
																		onClick={onSearch}
																		type="button"
																		sx={{ p: '10px 0px' }}
																		aria-label="search"
																		className='icon-button-user'
																	>
																		<SearchIcon />
																	</IconButton>
																</div>
															</th>
															<th>
																<div>
																	<input value={inactiveMobile} onChange={handleInactiveMobile} placeholder=" Search mobile" />
																	<IconButton
																		onClick={onSearch}
																		type="button"
																		sx={{ p: '10px 0px' }}
																		aria-label="search"
																		className='icon-button-user'
																	>
																		<SearchIcon />
																	</IconButton>
																</div>
															</th>
															<th></th>
															<th>
																<div>
																	<input value={inactiveState} onChange={handleInactiveState} placeholder=" Search state" />
																	<IconButton
																		onClick={onSearch}
																		type="button"
																		sx={{ p: '10px 0px' }}
																		aria-label="search"
																		className='icon-button-user'
																	>
																		<SearchIcon />
																	</IconButton>
																</div>
															</th>
															<th>
																<div>
																	<input value={inactiveCity} onChange={handleInactiveCity} placeholder=" Search city" />
																	<IconButton
																		onClick={onSearch}
																		type="button"
																		sx={{ p: '10px 0px' }}
																		aria-label="search"
																		className='icon-button-user'
																	>
																		<SearchIcon />
																	</IconButton>
																</div>
															</th>
															<th></th>
															<th></th>
															<th></th>
															<th></th>
														</tr>
													</thead>
													<tbody>
														{inActiveUsers?.map((active: any, index: number) => (
															<tr key={active._id}>
																<td>{index + 1 + (currentPageNonperform && itemsPerPageNonperform ? (currentPageNonperform - 1) * parseInt(itemsPerPageNonperform) : 0)}</td>
																<td onClick={() => {
																	const url = `/user-report/${active._id}/${active.name}`;
																	window.open(url, "_blank");
																}} style={{ cursor: 'pointer' }}>
																	{active.name}
																</td>
																<td>
																	+{active.countryCode || 91}{active.mobile}
																</td>
																<td>{active.email}</td>
																<td>{active.state}</td>
																<td>{active.city}</td>
																<td>{Math.floor(active.balance)}</td>
																<td>{Math.floor(active.totalAmount)}</td>
																<td>{formatDate(active.createdOn)}</td>
																<td>{formatedTime(active.createdOn)}</td>
															</tr>
														))}
													</tbody>
												</table>
											}
										</div>
										{Current_User_Type === User_Types.Active_User && <ul className="pagination d-flex justify-content-center">
											<Stack spacing={2}>
												<Pagination
													count={totalPagesActiveUserForm}
													color="secondary"
													page={currentPageActiveUser}
													onChange={(Event, New_Page) =>
														setcurrentPageActiveUser(New_Page)
													}
												/>
											</Stack>
										</ul>}
										{Current_User_Type === User_Types.Non_Performing_User && <ul className="pagination d-flex justify-content-center">
											<Stack spacing={2}>
												<Pagination
													count={totalPagesNonperform}
													color="secondary"
													page={currentPageNonperform}
													onChange={(Event, New_Page) =>
														setCurrentPageNonperform(New_Page)
													}
												/>
											</Stack>
										</ul>}
										{Current_User_Type === User_Types.User && <ul className="pagination d-flex justify-content-center">
											<Stack spacing={2}>
												<Pagination
													count={totalPages}
													color="secondary"
													page={currentPage}
													// onChange={(Event, New_Page) =>
													// 	setCurrentPage(New_Page)
													// }
													onChange={handlePageChange}
												/>
											</Stack>
										</ul>}
										{Current_User_Type === User_Types.In_Active_Deposit && <ul className="pagination d-flex justify-content-center">
											<Stack spacing={2}>
												<Pagination
													count={totalPagesInactive}
													color="secondary"
													page={inActiveUsersPage}
													onChange={(Event, New_Page) =>
														setInactiveUsersPage(New_Page)
													}
													// onChange={handlePageChange}
												/>
											</Stack>
										</ul>}

										
									</div>
								</div>
								<footer className="footer">
									<div className="container-fluid">
										<div className="row align-items-center justify-content-lg-between">
											<div className="col-lg-6 mb-lg-0  ">
												<div className="copyright text-center text-sm text-muted text-lg-start">
													© fairbets.co
												</div>
											</div>
											<div className="col-lg-6">
												<ul className="nav nav-footer justify-content-center justify-content-lg-end">
													<li className="nav-item">
														<a className="nav-link text-muted" target="_blank">
															Home
														</a>
													</li>
													<li className="nav-item">
														<a className="nav-link text-muted" target="_blank">
															About Us
														</a>
													</li>
													<li className="nav-item">
														<a className="nav-link text-muted" target="_blank">
															Blog
														</a>
													</li>
													<li className="nav-item">
														<a
															className="nav-link pe-0 text-muted"
															target="_blank"
														>
															License
														</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</footer>
							</div>
						</div>
					</main>
				</div>
			)}
		</>
	);
}

export default Users;