import React from 'react';

// Styles
import './UserReportCards.css'

function UserReportCards({ title, value }: UserReportCardProps) {
    // RUN API
    return (
        <div className='user-report-card'>
            <p className='user-report-card-title-text'>{`${title}`}</p>
            <p className='user-report-card-value-text'>{value}</p>
        </div>
    )
}

export default UserReportCards;

interface UserReportCardProps {
    title: String,
    value: number
}
