import React, { useState, useEffect, useContext } from 'react'
import Sidenav from '../../../../Components/SideNavigation/SideNavigation'
import Breadcrumbs from '../../../../Components/Breadcrumbs/Breadcrumbs'
import { API_Endpoint } from '../../../../Configuration/Settings'
import { encryptData } from '../../../../utils/encryptData'
import { User_Context } from '../../../../Contexts/User'
import axios from 'axios'
import { decryptData } from '../../../../utils/decryptData'
import { useLocation } from 'react-router-dom';
import { formatedTime } from '../../../../utils/utility'
import Stateful_Select from '../../../../Components/Dropdown/Dropdown'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilSquare } from '@fortawesome/free-solid-svg-icons'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import Loader from '../../../../Components/Loader/Loader'


const UserExposure = () => {
    const { User } = useContext(User_Context)
    const location = useLocation();
    const customProps = location.state;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sattaMatkaData, setSattaMatkaData] = useState<any>([]);
    const [jetFairData, setJetfairData] = useState<any>([]);
    const [falconData, setFalconData] = useState<any>([]);
    const [provider, setProvider] = useState("SattaMatka");
    const [loading,setLoading]=useState(false);

    const formatedDate = (date: any) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${day}-${month}-${year}`;
    };
    const formattedDate = (timestamp: any) => formatedDate(new Date(timestamp));

    const getExposerLists = () => {
        let data = {
            _id: customProps,
        };
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/User/user-exposer-lists`,
            headers: {
                Authorization: `Bearer ${User.token}`,
            },
            data: { token: encryptData(data) },
        };
        axios
            .request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data)
                setSattaMatkaData(response.data.data.payload._sattaMatka)
                setFalconData(response.data.data.payload._falcon)
                setJetfairData(response.data.data.payload._jetfair)
            })
            .catch((error: any) => {
                console.log(error.message);
            });
    }

    useEffect(() => {
        getExposerLists();
    }, [currentPage])

    const handleProvider = (newValue: any) => {
        setProvider(newValue)
    };
    const [editPopup, setEditPopup] = useState(false)
    const [editStatus, setEditStatus] = useState("l");
    const [editAmount, setEditAmount] = useState("");
    const [currentEditId, setCurrentEditId] = useState("");

    const handleSubmitData = (e: any) => {
        e.preventDefault();
        setLoading(true)
        let data = {
            _id: currentEditId,
            status: editStatus,
            amount: editAmount,
            updatedBy: {
                _id: User.data._id,
                name: User.data.name
            }
        };
        let token = localStorage.getItem("token");
        axios.post(`${API_Endpoint}/User/update-bets-admin`, { token: encryptData(data) }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setLoading(false)
            setEditPopup(false);
            getExposerLists();
        })
            .catch((error) => {
                setEditPopup(false);
                toast.error(error.message);
                setLoading(false)
            })
    }

    const openEditPopup = (item: any) => {
        setEditPopup(true);
        setCurrentEditId(item?._id)
    }

    const handleStatusChange = (e: any) => {
        setEditStatus(e.target.value);
    };

    const handleAmountChange = (e: any) => {
        setEditAmount(e.target.value);
    };

    return <>
        {loading && <Loader/>}
        <div className="g-sidenav-show  bg-gray-100">
            <Sidenav />
            <main className="main-content position-relative">
                <div>
                    <Dialog open={editPopup} onClose={() => setEditPopup(false)}>
                        {/* <DialogTitle>Payment Form</DialogTitle> */}
                        <DialogContent>
                            <form onSubmit={handleSubmitData}>

                                <div>
                                    <div className="label">Select Status</div>
                                    <select style={{ width: "100%", height: "35px" }} onChange={handleStatusChange} value={editStatus} id="">
                                        <option value="w">Win</option>
                                        <option value="l">Loss</option>
                                    </select>
                                </div>

                                {
                                    editStatus === "w" &&
                                    <div style={{ marginTop: "5px" }}>
                                        <div style={{ color: "#000", fontSize: "13px" }}>Enter Amount</div>
                                        <input type="text" onChange={handleAmountChange} value={editAmount} />
                                    </div>
                                }





                                <DialogActions>
                                    <Button
                                        className="btn-popup"
                                        variant="outlined"
                                        onClick={() => setEditPopup(false)}
                                        color="primary"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="btn-popup"
                                        variant="outlined"
                                        type="submit"
                                        color="primary"
                                    >
                                        Submit
                                    </Button>
                                </DialogActions>
                            </form>
                        </DialogContent>
                    </Dialog>
                </div>
                <div style={{ background: "#f8f9fa" }}>
                    <Breadcrumbs tab={provider} />
                    <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-1 mb-3 px-2">
                        <label className="lbl">Select Provider</label>
                        <Stateful_Select
                            value={provider}
                            onChange={(newValue: any) => handleProvider(newValue)}
                            options={["SattaMatka", "Falcon", "Jetfair"]}
                        />
                    </div>
                    {provider === "SattaMatka" && (
                        <div className="container-fluid">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table className="table table-view">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>bazar Name</th>
                                                <th>bazar Id</th>
                                                <th>Game Name</th>
                                                <th>Game Id</th>
                                                <th>Game</th>
                                                <th> Game Akda</th>
                                                <th>Game Type</th>
                                                <th>Result Date</th>
                                                <th>Transaction Id</th>
                                                <th>Customer Id</th>
                                                <th>Point</th>
                                                <th>Status</th>
                                                <th>Created on</th>
                                                <th>Updated on</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sattaMatkaData?.map((item: any, index: number) => {
                                                return (
                                                    <tr key={index} id={item._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.bazar_name}</td>
                                                        <td>{item.bazar_id}</td>
                                                        <td>{item.gameName}</td>
                                                        <td>{item.game_id}</td>
                                                        <td>{item.game}</td>
                                                        <td>{item.game_name}</td>
                                                        <td>{item.game_type}</td>
                                                        <td>{item.result_date}</td>
                                                        <td>{item.transaction_id}</td>
                                                        <td>{item.customer_id}</td>
                                                        <td>{item.point}</td>
                                                        <td>{item.status}</td>
                                                        <td>{`${formattedDate(item.createdOn)} , ${formatedTime(item.createdOn)}`}</td>
                                                        <td>{`${formattedDate(item.updatedOn)} , ${formatedTime(item.updatedOn)}`}</td>
                                                        <td><FontAwesomeIcon
                                                            id={item._id}
                                                            className="fa fa-pencil-square icon-home icon-trash "
                                                            icon={faPencilSquare}
                                                            onClick={() => openEditPopup(item)}
                                                            style={{ cursor: "pointer" }}
                                                        /></td>

                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                    {provider === "Falcon" && (
                        <div className="container-fluid">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table className="table table-view">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Event Name</th>
                                                <th>Event Type</th>
                                                <th>Market Id</th>
                                                <th>Market Name</th>
                                                <th>Market Type</th>
                                                <th>Runner Id</th>
                                                <th>Runner Name</th>
                                                <th>Transaction Id</th>
                                                <th>Amount</th>
                                                <th>Commission Amount</th>
                                                <th>Cashout Amount</th>
                                                <th>Paybale Amount</th>
                                                <th>Session Point</th>
                                                <th>Point</th>
                                                <th>Net Pl</th>
                                                <th>Rate</th>
                                                <th>Stake</th>
                                                <th>Bet Status</th>
                                                <th>Bet Type</th>
                                                <th>Updated on</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {falconData?.map((item: any, index: number) => {
                                                return (
                                                    <tr key={index} id={item._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.Eventname}</td>
                                                        <td>{item.Eventtypename}</td>
                                                        <td>{item.MarketID}</td>
                                                        <td>{item.Marketname}</td>
                                                        <td>{item.Markettype}</td>
                                                        <td>{item.RunnerID}</td>
                                                        <td>{item.Runnername}</td>
                                                        <td>{item.TransactionID}</td>
                                                        <td>{item.Amount}</td>
                                                        <td>{item.CommissionAmount}</td>
                                                        <td>{item.cashoutAmount}</td>
                                                        <td>{item.PayableAmount}</td>
                                                        <td>{item.SessionPoint}</td>
                                                        <td>{item.Point}</td>
                                                        <td>{item.NetPL}</td>
                                                        <td>{item.Rate}</td>
                                                        <td>{item.Stake}</td>
                                                        <td>{item.betStatus}</td>
                                                        <td>{item.BetType}</td>
                                                        <td>{`${formattedDate(item.createdOn)} , ${formatedTime(item.createdOn)}`}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                    {provider === "Jetfair" && (
                        <div className="container-fluid">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table className="table table-view">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Game Name</th>
                                                <th>Runner Name</th>
                                                <th>Market Name</th>
                                                <th>Market Id</th>
                                                <th>Transaction Id</th>
                                                <th>Transaction Code</th>
                                                <th>Transaction Type</th>
                                                <th>Amount</th>
                                                <th>Commission Amount</th>
                                                <th>Rate</th>
                                                <th>Stake</th>
                                                <th>Net Pl</th>
                                                <th>Bet Status</th>
                                                <th>Bet Type</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {jetFairData?.map((item: any, index: number) => {
                                                return (
                                                    <tr key={index} id={item._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.gameName}</td>
                                                        <td>{item.runnerName}</td>
                                                        <td>{item.marketName}</td>
                                                        <td>{item.marketId}</td>
                                                        <td>{item.transactionId}</td>
                                                        <td>{item.transactionCode}</td>
                                                        <td>{item.transactionType}</td>
                                                        <td>{Math.floor(item.amount)}</td>
                                                        <td>{Math.floor(item.commissionAmount)}</td>
                                                        <td>{Math.floor(item.rate)}</td>
                                                        <td>{Math.floor(item.stake)}</td>
                                                        <td>{Math.floor(item.netpl)}</td>
                                                        <td>{item.betStatus}</td>
                                                        <td>{item.betType}</td>
                                                        <td>{`${formattedDate(item.updatedOn)} , ${formatedTime(item.updatedOn)}`}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </main>
        </div>
        </>
}

export default UserExposure
