import React, { useContext, useState, useEffect, ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { User_Context } from '../../../../Contexts/User';
import { dateTime } from '../../../../utils/utility';
import { API_Endpoint } from '../../../../Configuration/Settings';
import { encryptData } from '../../../../utils/encryptData';
import { decryptData } from '../../../../utils/decryptData';
import { Pagination, Stack } from "@mui/material";
import { formatDate, formatedTime } from '../../../../utils/utility';
import axios from 'axios';

// Components
import Sidenav from '../../../../Components/SideNavigation/SideNavigation';
import Breadcrumbs from '../../../../Components/Breadcrumbs/Breadcrumbs';
import SearchBar from '../../../../Components/SearchBox/Search';

const itemsPerPage = 50

function FundRequestBonusWalletTable() {
    const { User } = useContext(User_Context);
    const location = useLocation();
    const navState = location.state ? location.state : {}

    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState<any>([]);

    const [totalPages, setTotalPages] = useState(0);
    const [pageNo, setPageNo] = useState(1)

    const [searchUserName, setSearchUserName] = useState<string>("");
    const [searchUserMobile, setSearchUserMobile] = useState<string>("");

    const getPendingBonusWalletData = (isFilter?: boolean) => {
        setLoading(true)
        const token = localStorage.getItem("token");
        let data: any = {};
        if (navState.startDate !== '' && navState.endDate !== '') {
            data = {
                startDate: dateTime(navState.startDate),
                endDate: dateTime(navState.endDate),
                itemsPerPage: itemsPerPage,
                pageNo: pageNo,
                allData: navState.allData,
                filter: {}
            };
        } else {
            // const currentDate = new Date().toISOString().split('T')[0];
            const currentDate = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0];
            data = {
                startDate: dateTime(currentDate),
                endDate: dateTime(currentDate),
                itemsPerPage: itemsPerPage,
                pageNo: pageNo,
                allData: navState.allData,
                filter: {}
            };
        }

        if (isFilter && searchUserName.length > 0 && searchUserMobile.length === 0) {
            data.filter.name = searchUserName
        } else if (isFilter && searchUserMobile.length > 0 && searchUserName.length === 0) {
            data.filter.mobile = searchUserMobile
        } else if (isFilter && searchUserMobile.length > 0 && searchUserName.length > 0) {
            data.filter.name = searchUserName;
            data.filter.mobile = searchUserMobile;
        }

        if (token) {
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${API_Endpoint}/bonus-wallet/fund-pending`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: { token: encryptData(data) }
            };
            axios
                .request(config)
                .then((response) => {
                    response.data.data = decryptData(response.data.data)
                    setTableData(response.data.data.payload.documents);
                    setTotalPages(response.data.data.payload.totalPages)
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }

    const getApprovedBonusWalletData = (isFilter?: boolean) => {
        setLoading(true)
        const token = localStorage.getItem("token");
        let data: any = {};
        if (navState.startDate && navState.endDate) {
            data = {
                startDate: dateTime(navState.startDate),
                endDate: dateTime(navState.endDate),
                itemsPerPage: itemsPerPage,
                pageNo: pageNo,
                allData: navState.allData,
                filter: {}
            };
        } else {
            // const currentDate = new Date().toISOString().split('T')[0];
            const currentDate = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0];
            data = {
                startDate: dateTime(currentDate),
                endDate: dateTime(currentDate),
                itemsPerPage: itemsPerPage,
                pageNo: pageNo,
                allData: navState.allData,
                filter: {}
            };
        }

        if (isFilter && searchUserName.length > 0 && searchUserMobile.length === 0) {
            data.filter.name = searchUserName
        } else if (isFilter && searchUserMobile.length > 0 && searchUserName.length === 0) {
            data.filter.mobile = searchUserMobile
        } else if (isFilter && searchUserMobile.length > 0 && searchUserName.length > 0) {
            data.filter.name = searchUserName;
            data.filter.mobile = searchUserMobile;
        }

        if (token) {
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${API_Endpoint}/bonus-wallet/fund-approved`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: { token: encryptData(data) }
            };

            axios
                .request(config)
                .then((response) => {
                    response.data.data = decryptData(response.data.data)
                    setTableData(response.data.data.payload.documents);
                    setTotalPages(response.data.data.payload.totalPages);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }

    const getTotalBonusWalletData = (isFilter?: boolean) => {
        setLoading(true)
        const token = localStorage.getItem("token");
        let data: any = {};
        if (navState.startDate && navState.endDate) {
            data = {
                startDate: dateTime(navState.startDate),
                endDate: dateTime(navState.endDate),
                itemsPerPage: itemsPerPage,
                pageNo: pageNo,
                allData: navState.allData,
                filter: {}
            };
        } else {
            // const currentDate = new Date().toISOString().split('T')[0];
            const currentDate = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0];
            data = {
                startDate: dateTime(currentDate),
                endDate: dateTime(currentDate),
                itemsPerPage: itemsPerPage,
                pageNo: pageNo,
                allData: navState.allData,
                filter: {}
            };
        }

        if (isFilter && searchUserName.length > 0 && searchUserMobile.length === 0) {
            data.filter.name = searchUserName
        } else if (isFilter && searchUserMobile.length > 0 && searchUserName.length === 0) {
            data.filter.mobile = searchUserMobile
        } else if (isFilter && searchUserMobile.length > 0 && searchUserName.length > 0) {
            data.filter.name = searchUserName;
            data.filter.mobile = searchUserMobile;
        }

        if (token) {
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${API_Endpoint}/bonus-wallet/fund-transfer-in-bonus-wallet`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: { token: encryptData(data) }
            };
            axios
                .request(config)
                .then((response) => {
                    response.data.data = decryptData(response.data.data)
                    setTableData(response.data.data.payload.documents);
                    setTotalPages(response.data.data.payload.totalPages);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        if (navState.type === "pending") {
            getPendingBonusWalletData()
        } else if (navState.type === "approved") {
            getApprovedBonusWalletData()
        } else if (navState.type === "totalData") {
            getTotalBonusWalletData()
        }

    }, [navState, pageNo]);

    const filterTransaction = () => {
        setPageNo(1);
        if (navState.type === "pending") {
            getPendingBonusWalletData(true)
        } else if (navState.type === "approved") {
            getApprovedBonusWalletData(true)
        } else if (navState.type === "totalData") {
            getTotalBonusWalletData(true)
        }
    }

    const handleSearchUserName = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchUserName(event.target.value)
    }

    const handleSearchUserMob = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchUserMobile(event.target.value)
    }

    return (
        <>
            <div className="g-sidenav-show  bg-gray-100">
                <Sidenav />
                <main className="main-content position-relative">
                    <div style={{ background: "#f8f9fa" }}>
                        <Breadcrumbs tab={`Bonus Wallet Table - ${navState.type === "pending" ? "Pending" : navState.type === "approved" ? "Approved" : "Total Data"}`} />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table table-view">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Name</th>
                                                    <th>Mobile</th>
                                                    <th>Opening Balance</th>
                                                    <th>Amount</th>
                                                    <th>Closing Balance</th>
                                                    <th>Referred By Name</th>
                                                    <th>Referred By Mobile</th>
                                                    <th>Referred To Name</th>
                                                    <th>Referred To Mobile</th>
                                                    <th>First Deposit %</th>
                                                    <th>Referral %</th>
                                                    <th>Status</th>
                                                    <th>Created on</th>
                                                    <th>Updated on</th>
                                                    {/* <th>Updated By</th> */}
                                                </tr>
                                                <tr>
                                                    <th className="thdr"></th>
                                                    <th className="thdr">
                                                        <div className="d-flex justify-content-center">
                                                            <SearchBar value={searchUserName} onChange={handleSearchUserName} onSearch={filterTransaction} placeholder="Search by user name" />
                                                        </div>
                                                    </th>
                                                    <th className="thdr">
                                                        <div className="d-flex justify-content-center">
                                                            <SearchBar value={searchUserMobile} onChange={handleSearchUserMob} onSearch={filterTransaction} placeholder="Search by mobile no" />
                                                        </div>
                                                    </th>
                                                    <th className="thdr"></th>
                                                    <th className="thdr"></th>
                                                    <th className="thdr"></th>
                                                    <th className="thdr"></th>
                                                    <th className="thdr"></th>
                                                    <th className="thdr"></th>
                                                    <th className="thdr"></th>
                                                    <th className="thdr"></th>
                                                    <th className="thdr"></th>
                                                    <th className="thdr"></th>
                                                    <th className="thdr"></th>
                                                    <th className="thdr"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData.map((item: any, index: number) => {
                                                    return (
                                                        <tr key={index} id={item._id}>
                                                            <td>{index + 1 + (pageNo && itemsPerPage  ? (pageNo - 1)  * itemsPerPage : 0)}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.mobile}</td>
                                                            <td>{item.bonusWalletOpenBalance}</td>
                                                            <td>{item.amount}</td>
                                                            <td>{item.bonusWalletClosingBalance}</td>
                                                            <td>{item.referredByName}</td>
                                                            <td>{item.referredByMobile}</td>
                                                            <td>{item.referredToName}</td>
                                                            <td>{item.referredToMobile}</td>
                                                            <td>{item.firstDepositPercentage}%</td>
                                                            <td>{item.referralPercentage}%</td>
                                                            <td>{item.status}</td>
                                                            <td>{`${formatDate(item.createdOn)} , ${formatedTime(item.createdOn)}`}</td>
                                                            <td>{`${formatDate(item.updatedOn)} , ${formatedTime(item.updatedOn)}`}</td>
                                                        </tr>
                                                    )
                                                })}


                                            </tbody>
                                        </table>
                                    </div>
                                    <ul className="pagination d-flex justify-content-center">
                                        <Stack spacing={2}>
                                            <Pagination
                                                count={totalPages}
                                                color="secondary"
                                                page={pageNo}
                                                onChange={(Event, New_Page) =>
                                                    setPageNo(New_Page)
                                                }
                                            />
                                        </Stack>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default FundRequestBonusWalletTable