import React, { useState, useContext, useEffect, ChangeEvent, FormEvent } from 'react'
import Sidenav from '../SideNavigation/SideNavigation'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import { Button, Pagination, Stack, TextField } from "@mui/material";
import axios from 'axios';
import { API_Endpoint } from '../../Configuration/Settings';
import { encryptData } from '../../utils/encryptData';
import { decryptData } from '../../utils/decryptData';
import { User_Context } from '../../Contexts/User';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import Stateful_Select from '../Dropdown/Dropdown';
import CloseIcon from '@mui/icons-material/Close';
import "./CustomerAllotment.css"
function CustomerAllotment() {
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false)
    const { User } = useContext(User_Context)
    const [customerData, setCustomerData] = useState([])
    const [blockRoleID, setBlockRoleId] = useState("");
    const [blockUserId, setBlockUserId] = useState("");
    const [openPopup, setOpenPopup] = useState<boolean>(false);
    const [blockUser, setBlockUser] = useState<boolean>();
    const [remark, setRemark] = useState<string>("");
    const navigate = useNavigate()
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [allData, setAllData] = useState<boolean>(false);
    const [todayData, setTodayData] = useState<boolean>(true);
    const [openCallerReportPopup, setOpenCallerReportPopup] = useState<boolean>(false);
    const [callerId, setCallerId] = useState<String>("");



    const getCustomers = async () => {
        setLoading(true)
        let data = {}
        let config = {
            method: "post",
            url: `${API_Endpoint}/SubAdmin/get-all-customerSupport`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${User.token}`,
            },
            data: {
                token: encryptData(data)
            },
        };
        axios.request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data);
                setCustomerData(response.data.data.payload)
                setLoading(false)
            }).catch((error) => {
                toast.error(error.response.data.message)
                setLoading(false)
            });
    }

    useEffect(() => {
        getCustomers()
    }, [])

    const customerAllotment = (data: any) => {
        let customData = data
        navigate("/customer-allotted", { state: customData })
    }

    const removeCustomer = (data: any, id: any) => {
        let customData = data
        navigate("/remove-customer", { state: { customData, id } },)
    }

    const addRemoveCustomer = (id: any, type: string) => {
        let data = {
            id: id,
            type: type
        }
        navigate("/add-customer", { state: data })
    }

    const handleBlockAction = (roleid: string, blockUser: boolean, _id: string) => {
        setBlockUserId(_id)
        setBlockRoleId(roleid);
        setBlockUser(blockUser)
        setOpenPopup(true);
    }

    const handleActionSubmit = (e: FormEvent) => {
        e.preventDefault()
        if (!remark) {
            toast.error("Please enter remark")
        } else {
            setOpenPopup(false);
            setLoading(true);
            let data = {
                _id: blockUserId,
                Role_ID: blockRoleID,
                status: blockUser,
                blockReason: remark
            }
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${API_Endpoint}/SubAdmin/block-caller`,
                headers: {
                    Authorization: `Bearer ${User.token}`,
                },
                data: { token: encryptData(data) },
            };
            axios
                .request(config)
                .then(() => {
                    setLoading(false);
                    getCustomers()
                    setRemark("");
                    setBlockRoleId("")
                    setBlockUserId("")
                })
                .catch((error: any) => {
                    setLoading(false)
                    toast.error(error.response.data.message)
                });
        }
    }

    const handleRemark = (e: ChangeEvent<HTMLInputElement>) => {
        setRemark(e.target.value);
    };


    const handlePerPage = (newValue: any) => {
        setItemsPerPage(newValue)
    }

    const clearDate = () => {
        setStartDate('')
        setEndDate('')
    }
    const handleCallerReport = (id: string) => {
        setCallerId(id);
        setOpenCallerReportPopup(true)
    }



    const handleCallerReportSubmit = (e: any) => {
        let CallerData;

        if (e.target.id === "apply" && startDate && endDate) {
            CallerData = {
                _id: callerId,
                startDate: startDate,
                endDate: endDate,
                todayData: false,
                allData: false
            }
        } else if (e.target.id === "countinue") {
            CallerData = {
                _id: callerId,
                startDate: "",
                endDate: "",
                todayData: true,
                allData: false
            }
        } else if (e.target.id === "all-data") {
            CallerData = {
                _id: callerId,
                startDate: "",
                endDate: "",
                todayData: false,
                allData: true
            }
        }

        navigate("/caller-report", { state: { data: CallerData } })
    }

    // dates are done then send alldata and todaydata false
    // direct continue dates string blank or todaydata true and all data false
    // alldata true when click  and today data false or both date string empty


    return (
        <>
            {loading && (<Loader />)}
            <div className="g-sidenav-show  bg-gray-100">
                <ToastContainer autoClose={2000} position="top-center" />
                <Sidenav />
                <main className="main-content position-relative">


                    <Dialog open={openCallerReportPopup} onClose={() => setOpenCallerReportPopup(false)}>
                        <DialogContent className='caller-popup-content'>
                            <div className="caller-popup-container">

                                <div className="col-2 col-xl-2 col-sm-4 pdrt">
                                    <label className="lbl">From Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="From Date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                </div>
                                <div className="col-2 col-xl-2 col-sm-4 pdrt">
                                    <label className="lbl">To Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="To Date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </div>

                                <div className="col-6 col-xl-2 text-center col-sm-4 pdrt">
                                    <label className="lbl">All Data</label>
                                    <a onClick={handleCallerReportSubmit} id='all-data' className="sechBtn mt-1">
                                        All Data
                                    </a>
                                </div>

                                <div className="col-6 col-xl-1 col-sm-4 pdrt">
                                    <label className="lbl">Apply</label>
                                    <button disabled={!startDate && !endDate}
                                        id='apply'
                                        style={{ border: "none", backgroundColor: (!startDate && !endDate) || (!startDate || !endDate) ? "#1976d2c3" : "#436ad8" }} onClick={handleCallerReportSubmit} className="sechBtn mt-1">
                                        Apply
                                    </button>
                                </div>

                                <div className="col-6 col-xl-2 col-sm-4 text-center pdrt">
                                    <label className="lbl">Clear Dates</label>
                                    <a onClick={clearDate} className="sechBtn mt-1">
                                        Clear Dates
                                    </a>
                                </div>

                            </div>

                            <div className="countinue-button"><button
                                disabled={startDate != "" && endDate != ""}
                                style={{ backgroundColor: (!startDate && !endDate) ? "#436ad8" : "#1976d2c3" }}
                                id='countinue'
                                onClick={handleCallerReportSubmit} >Countinue</button>
                            </div>

                        </DialogContent>
                        <div className="close-caller-report-box" onClick={()=>setOpenCallerReportPopup(false)}>
                            <CloseIcon />
                        </div>
                    </Dialog>

                    <div>
                        <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
                            <DialogContent>
                                <form onSubmit={handleActionSubmit}>
                                    <div>
                                        <TextField type={"text"} label={"Please enter remark"} fullWidth={true} value={remark} onChange={handleRemark} />
                                    </div>
                                    <DialogActions>
                                        <Button
                                            className="btn-popup"
                                            variant="outlined"
                                            onClick={() => setOpenPopup(false)}
                                            color="primary"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="btn-popup"
                                            variant="outlined"
                                            type="submit"
                                            color="primary"

                                        >
                                            Submit
                                        </Button>
                                    </DialogActions>
                                </form>
                            </DialogContent>
                        </Dialog>
                    </div>
                    <div style={{ background: "#f8f9fa" }}>
                        <Breadcrumbs tab={"Customer Allotment"} />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 mt-2">
                                    <div className="table-responsive">
                                        <table className="table table-view">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Mobile</th>
                                                    <th>Email</th>
                                                    <th>Role Id</th>
                                                    <th>Allotted Customer</th>
                                                    <th>Action</th>
                                                    <th>Caller Report</th>
                                                    <th>Block Reason</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {customerData.map((item: any, index: number) => {
                                                    return (
                                                        <tr id={item._id}>
                                                            <td>{index + 1}</td>
                                                            <td style={{ cursor: "pointer" }} onClick={() => customerAllotment(item.allotedCustomer)}>{item.name}</td>
                                                            <td style={{ cursor: "pointer" }} onClick={() => customerAllotment(item.allotedCustomer)}>{item.mobile}</td>
                                                            <td style={{ cursor: "pointer" }} onClick={() => customerAllotment(item.allotedCustomer)}>{item.email}</td>
                                                            <td style={{ cursor: "pointer" }} onClick={() => customerAllotment(item.allotedCustomer)}>{item.Role_ID}</td>
                                                            <td style={{ cursor: "pointer" }} onClick={() => customerAllotment(item.allotedCustomer)}>{item.allotedCustomer.length}</td>
                                                            <td>
                                                                <span>
                                                                    <Button
                                                                        onClick={() => addRemoveCustomer(item._id, "add")}
                                                                        className="withdraw-btn"
                                                                        variant="contained"
                                                                    >
                                                                        Allot Customer
                                                                    </Button>
                                                                </span>
                                                                <span>
                                                                    <Button
                                                                        onClick={() => removeCustomer(item.allotedCustomer, item._id)}
                                                                        className="btn-withdraw"
                                                                        variant="contained"
                                                                    >
                                                                        Remove Customer
                                                                    </Button>
                                                                </span>
                                                                <span>
                                                                    <Button
                                                                        id={item._id}
                                                                        onClick={() => handleBlockAction(item.Role_ID, !item.block, item._id)}
                                                                        className="btn-withdraw"
                                                                        variant="contained"
                                                                    >
                                                                        {item.block === true ? "Un Block Caller" : "Block Caller"}
                                                                    </Button>
                                                                </span>

                                                            </td>
                                                            <td><span>
                                                                <Button
                                                                    id={item._id}
                                                                    onClick={() => handleCallerReport(item._id)}
                                                                    className="btn-withdraw"
                                                                    variant="contained"
                                                                >
                                                                    Caller Report
                                                                </Button>
                                                            </span></td>
                                                            <td>{item.blockReason}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <ul className="pagination d-flex justify-content-center">
                                        <Stack spacing={2}>
                                            <Pagination
                                                count={totalPages}
                                                color="secondary"
                                                page={currentPage}
                                                onChange={(event, newPage) => setCurrentPage(newPage)}
                                            />
                                        </Stack>
                                    </ul>
                                </div>
                            </div>
                            <footer className="footer">
                                <div className="container-fluid">
                                    <div className="row align-items-center justify-content-lg-between">
                                        <div className="col-lg-6 mb-lg-0  ">
                                            <div className="copyright text-center text-sm text-muted text-lg-start">
                                                © fairbets.co
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                                                <li className="nav-item">
                                                    <a className="nav-link text-muted" target="_blank">
                                                        Home
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link text-muted" target="_blank">
                                                        About Us
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link text-muted" target="_blank">
                                                        Blog
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link pe-0 text-muted"
                                                        target="_blank"
                                                    >
                                                        License
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default CustomerAllotment