import { Tooltip, Button } from "@mui/material";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const ExcelExport = ({ inputData, fileName, headings }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
  const fileExtension = ".xlsx";

  const exportData = () => {
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, headings)
    XLSX.utils.sheet_add_json(ws, inputData, { origin: 'A2', skipHeader: true });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <Tooltip title="Excel Export">
        <a
          className="sechBtn mt-1"
          title="Export All Data to Excel"
          style={{ cursor: "pointer" }}
          onClick={(e) => exportData(fileName)}
        >
          <FileDownloadIcon></FileDownloadIcon>
        </a>
      </Tooltip>
    </>
  );
};

export default ExcelExport;
