import React, { useState, useContext, ChangeEvent } from 'react'
import "../Coins/coins.css"
import Button from "@mui/material/Button";
import axios from 'axios';
import { API_Endpoint } from '../../Configuration/Settings';
import { encryptData } from '../../utils/encryptData';
import Loader from '../Loader/Loader';
import { User_Context } from '../../Contexts/User';
import { ToastContainer, toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import { TextField } from '@mui/material';

function RemoveBonusWalletCoins() {
    const [amount, setAmount] = useState<string>("")
    const [userRemark, setUserRemark] = useState<string>("");
    const { User } = useContext(User_Context)
    const { User_ID } = useParams();
    const [loading, setLoading] = useState<boolean>(false);

    const handleAmount = (e: ChangeEvent<HTMLInputElement>) => {
        setAmount(e.target.value)
    }
    const handleRemark = (e: ChangeEvent<HTMLInputElement>) => {
        setUserRemark(e.target.value)
    }

    const handleRemoveCoin = (e: any) => {
        e.preventDefault()
        if (!amount) {
            toast.error("Please enter amount")
        } else if (!userRemark) {
            toast.error("Please enter remark")
        } else {
            setLoading(true)
            let obj: any = {
                bonusBy: {
                    name: User.data.name,
                    _id: User.data._id,
                    type: "remove bonus",
                    transaction: "credit",
                },
                userId: User_ID,
                amount: amount,
                type: "remove bonus",
                remark: userRemark
            }
            let payload: any = {
                token: encryptData(obj)
            };
            const token = localStorage.getItem("token");
            axios
                .post(
                    `${API_Endpoint}/bonus-wallet/remove-bonus`,
                    payload,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response) => {
                    setLoading(false)
                    toast.success("Bonus coins removed successfully");
                    setAmount("")
                    setUserRemark("")
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                    setLoading(false);
                });
        }
    }
    return (
        <>
            {loading && <Loader />}
            <div className='parent-container'>
                <ToastContainer autoClose={2000} position="top-center" />
                <div className='centered-div'>
                    <div className='mt-2 text-inp'>
                        <label>Amount</label>
                        <TextField label={"Enter Amount"} value={amount} onChange={handleAmount} type={"number"} fullWidth={true} />
                    </div>
                    <div className='mt-2 text-inp'>
                        <label>Remark</label>
                        <TextField label={"Enter Remark"} value={userRemark} onChange={handleRemark} type={"text"} fullWidth={true} />
                    </div>
                    <div className='d-flex justify-content-around mt-4 mb-4'>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={(e: any) => handleRemoveCoin(e)}
                            >
                                Remove Bonus Coins
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RemoveBonusWalletCoins