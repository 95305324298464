import React, { ChangeEvent } from "react";
import { TextField, TextFieldProps } from "@material-ui/core";

type PropsType = {
  label?: string;
  value: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void; 
  error?: boolean;
  helperText?: string;
  type: string;
  fullWidth: boolean;
  isDisabled?: boolean
  customInputTextStyles?: Object;
  required?:boolean;
};

const Reusable_Input = ({
  label,
  value,
  onChange,
  error,
  helperText,
  type,
  fullWidth,
  isDisabled = false,
  required,
  customInputTextStyles = {}
}: PropsType): JSX.Element => {
  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      type={type}
      fullWidth={fullWidth}
      required={required}
      variant="outlined"
      size="small"
      className="mt-1"
      disabled={isDisabled}
      InputLabelProps={{
        style: {
          fontFamily: "Roboto",
          fontSize: "14px",
          padding: 0
        },
      }}
      inputProps={{
        style: customInputTextStyles
      }}
    />
  );
};

export default Reusable_Input;
