import React, { useState, useEffect, useContext, ChangeEvent } from 'react'
import Sidenav from '../SideNavigation/SideNavigation'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import axios from 'axios'
import { API_Endpoint } from '../../Configuration/Settings'
import { encryptData } from '../../utils/encryptData'
import { decryptData } from '../../utils/decryptData'
import { Button } from '@mui/material'
import { User_Context } from '../../Contexts/User'
import Loader from '../Loader/Loader'
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilSquare } from "@fortawesome/free-solid-svg-icons";
import { TextField } from "@material-ui/core";
import {
    Dialog,
    DialogActions,
    DialogContent,
} from "@material-ui/core";
import Switch from "@mui/material/Switch";
import "./Percentage.css"


interface Percent {
    type: string,
    _id: string,
    bonus: number,
    percent: number,
    status: boolean,
    startAmount: number,
    endAmount: number
}

function Percentage() {
    const [loading, setLoading] = useState<boolean>(false);
    const [percentData, setPercentData] = useState<Array<Percent>>([])
    const { User } = useContext(User_Context)
    const [openPopup, setOpenPopup] = useState(false);
    const [openAddPopup, setAddOpenPopup] = useState(false);
    const [percentItem, setPercentItem] = useState<Percent>();
    const [percentType, setPercentType] = useState<number>();
    const [percentStartAmount, setPercentStartAmount] = useState<number>();
    const [percentEndAmount, setPercentEndAmount] = useState<number>();
    const [percentBonus, setPercentBonus] = useState<number>();
    const [addType, setAddType] = useState<string>("");
    const [addTypeError, setAddTypeError] = useState<boolean>(false);
    const [addTypeHelperText, setAddTypeHelperText] = useState<string>("");
    const [addPercent, setAddPercent] = useState<number>();
    const [addPercentError, setAddPercentError] = useState<boolean>(false);
    const [addPercentHelperText, setAddPercentHelperText] = useState<string>("");
    const [addStartAmount, setAddStartAmount] = useState<number | null>();
    const [addStartError, setAddStartError] = useState<boolean>(false);
    const [addStartHelperText, setAddStartHelperText] = useState<string>("");
    const [addEndAmount, setAddEndAmount] = useState<number | null>();
    const [addBonus, setAddBonus] = useState<number>();
    const [addBonusError, setAddBonusError] = useState<boolean>(false);
    const [addBonusHelperText, setAddBonusHelperText] = useState<string>("");
    const [addEndError, setAddEndError] = useState<boolean>(false);
    const [addEndHelperText, setAddEndHelperText] = useState<string>("");

    const label = { inputProps: { "aria-label": "Switch demo" } };



    const handleAddType = (event: ChangeEvent<HTMLInputElement>) => {
        setAddType(event.target.value)
    };

    const handleAddPercent = (event: ChangeEvent<HTMLInputElement>) => {
        setAddPercent(parseInt(event.target.value))
    };

    const handleAddStartAmount = (event: ChangeEvent<HTMLInputElement>) => {
        setAddStartAmount(parseInt(event.target.value))
    };

    const handleAddEndAmount = (event: ChangeEvent<HTMLInputElement>) => {
        setAddEndAmount(parseInt(event.target.value))
        if (addStartAmount) {
            if (parseInt(event.target.value) < addStartAmount) {
                setAddEndError(true)
                setAddEndHelperText("Enter amount greater than start amount")
                setAddEndAmount(parseInt(event.target.value))
            }
            else {
                setAddEndError(false)
                setAddEndHelperText("")
            }
        }
    };

    const handleAddBonus = (event: ChangeEvent<HTMLInputElement>) => {
        setAddBonus(parseInt(event.target.value))
    };

    const handleChangePercent = (event: ChangeEvent<HTMLInputElement>) => {
        setPercentType(parseInt(event.target.value))
    };

    const handleChangeStartAmount = (event: ChangeEvent<HTMLInputElement>) => {
        setPercentStartAmount(parseInt(event.target.value))
    };

    const handleChangeEndAmount = (event: ChangeEvent<HTMLInputElement>) => {
        setPercentEndAmount(parseInt(event.target.value))
    };

    const handleChangeBonus = (event: ChangeEvent<HTMLInputElement>) => {
        setPercentBonus(parseInt(event.target.value))
    };

    const getAllData = () => {
        setLoading(true)
        axios.request({
            method: "post",
            url: `${API_Endpoint}/change-percentage/get-All`,
            headers: { Authorization: `Bearer ${User.token}` },
            data: { token: encryptData({}) },
        }).then((response) => {
            response.data.data = decryptData(response.data.data)
            setPercentData(response.data.data.payload)
            setLoading(false)
        }).catch((error) => {
            toast.error(error.message)
            setLoading(false)
        });
    }

    const handleSubmit = () => {
        setLoading(true)
        let data = {
            type: percentItem,
            percent: percentType,
            startAmount: percentStartAmount,
            endAmount: percentEndAmount,
            bonus: percentBonus
        }
        axios.request({
            method: "post",
            url: `${API_Endpoint}/change-percentage`,
            headers: { Authorization: `Bearer ${User.token}` },
            data: { token: encryptData(data) },
        }).then((response) => {
            setOpenPopup(false)
            setLoading(false)
            getAllData()
        }).catch((error) => {
            toast.error(error.message)
            setLoading(false)
        });
    }
    const handleAddSubmit = (e: any) => {
        e.preventDefault()
        if (!addType) {
            setAddTypeError(true)
            setAddTypeHelperText("Enter type name")
        } else if (!addPercent) {
            setAddPercentError(true)
            setAddPercentHelperText("Enter percentage")
        } else if (!addStartAmount) {
            setAddStartError(true)
            setAddStartHelperText("Enter start amount")
        } else if (!addEndAmount) {
            setAddEndError(true)
            setAddEndHelperText("Enter end amount")
        } else if (!addBonus) {
            setAddBonusError(true)
            setAddBonusHelperText("Enter bonus")
        }
        if (addStartAmount && addEndAmount && addBonus) {
            if (addEndAmount > addStartAmount) {
                setLoading(true)
                let data = {
                    type: addType,
                    percent: addPercent,
                    startAmount: addStartAmount,
                    endAmount: addEndAmount,
                    bonus: addBonus
                }
                axios.request({
                    method: "post",
                    url: `${API_Endpoint}/change-percentage`,
                    headers: { Authorization: `Bearer ${User.token}` },
                    data: { token: encryptData(data) },
                }).then((response) => {
                    setAddOpenPopup(false)
                    setLoading(false)
                    getAllData()
                }).catch((error) => {
                    toast.error(error.message)
                    setLoading(false)
                });
            } else {
                setAddEndError(true)
                setAddEndHelperText("Enter amount greater than start amount")
            }
        }

    }

    const openEditDialog = (item: any) => {
        setOpenPopup(true)
        setPercentItem(item.type)
        setPercentType(item.percent)
        setPercentStartAmount(item.startAmount)
        setPercentEndAmount(item.endAmount)
        setPercentBonus(item.bonus)
    }

    useEffect(() => {
        getAllData()
    }, [])

    const handleStatusChange = (event: ChangeEvent<HTMLInputElement>, type: string) => {
        setLoading(true)
        const status = event.target.checked;
        let data = {
            type: type,
            status: status
        }
        console.log(data);
        axios.request({
            method: "post",
            url: `${API_Endpoint}/change-percentage/change-status`,
            headers: { Authorization: `Bearer ${User.token}` },
            data: { token: encryptData(data) },
        }).then((response) => {
            setOpenPopup(false)
            setLoading(false)
            getAllData()
        }).catch((error) => {
            toast.error(error.message)
            setLoading(false)
        });
    }
    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className="g-sidenav-show  bg-gray-100">
                    <ToastContainer autoClose={2000} position="top-center" />
                    <Sidenav />
                    <main className="main-content position-relative">
                        <div style={{ background: "#f8f9fa" }}>
                            <Breadcrumbs tab={"Percentage"} button={<Button
                                onClick={() => setAddOpenPopup(true)}
                                className="btn-payment"
                                variant="contained"
                            >
                                Add
                            </Button>} />
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="table-responsive">
                                            <table className="table table-view">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Name</th>
                                                        <th>Percentage</th>
                                                        <th>Start Amount</th>
                                                        <th>End Amount</th>
                                                        <th>Bonus</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {percentData.map((item, index) => (
                                                        <tr id={item._id}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.type}</td>
                                                            <td>{item.percent}</td>
                                                            <td>{item.startAmount}</td>
                                                            <td>{item.endAmount}</td>
                                                            <td>{item.bonus}</td>
                                                            <td>
                                                                <div className='switch-btn'>
                                                                    <span><FontAwesomeIcon
                                                                        className="fa fa-pencil-square icon-home icon-banner"
                                                                        icon={faPencilSquare}
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => openEditDialog(item)}
                                                                    /></span>
                                                                    <span>
                                                                        <Switch
                                                                            {...label}
                                                                            id={item._id}
                                                                            checked={item.status}
                                                                            onChange={(event) => handleStatusChange(event, item.type)}
                                                                        />
                                                                    </span>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
                                        <DialogContent>
                                            <form onSubmit={handleSubmit}>
                                                <div className='parent-container'>
                                                    <div className='centered-div'>
                                                        <div className='mt-1 text-inp'>
                                                            <label>Percent</label>
                                                            <TextField onChange={handleChangePercent} variant="outlined" size="small" className="mt-2" value={percentType} type={"number"} fullWidth={true} />
                                                        </div>
                                                        <div className='mt-1 text-inp'>
                                                            <label>Start Amount</label>
                                                            <TextField onChange={handleChangeStartAmount} variant="outlined" size="small" className="mt-2" value={percentStartAmount} type={"number"} fullWidth={true} />
                                                        </div>
                                                        <div className='mt-1 text-inp'>
                                                            <label>End Amount</label>
                                                            <TextField onChange={handleChangeEndAmount} variant="outlined" size="small" className="mt-2" value={percentEndAmount} type={"number"} fullWidth={true} />
                                                        </div>
                                                        <div className='mt-1 text-inp'>
                                                            <label>Bonus</label>
                                                            <TextField onChange={handleChangeBonus} variant="outlined" size="small" className="mt-2" value={percentBonus} type={"number"} fullWidth={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <DialogActions className='mt-2'>
                                                    <Button
                                                        className="btn-popup"
                                                        variant="outlined"
                                                        onClick={() => setOpenPopup(false)}
                                                        color="primary"
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        className="btn-popup"
                                                        variant="outlined"
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                </DialogActions>
                                            </form>
                                        </DialogContent>
                                    </Dialog>
                                </div>
                                <div>
                                    <Dialog open={openAddPopup} onClose={() => setAddOpenPopup(false)}>
                                        <DialogContent>
                                            <form onSubmit={handleAddSubmit}>
                                                <div className='parent-container'>
                                                    <div className='centered-div'>
                                                        <div className='mt-1 text-inp'>
                                                            <label>Type</label>
                                                            <TextField error={addTypeError} helperText={addTypeHelperText} onChange={handleAddType} variant="outlined" size="small" className="mt-2" value={addType} type={"string"} fullWidth={true} />
                                                        </div>
                                                        <div className='mt-1 text-inp'>
                                                            <label>Percent</label>
                                                            <TextField error={addPercentError} helperText={addPercentHelperText} onChange={handleAddPercent} variant="outlined" size="small" className="mt-2" value={addPercent} type={"number"} fullWidth={true} />
                                                        </div>
                                                        <div className='mt-1 text-inp'>
                                                            <label>Start Amount</label>
                                                            <TextField error={addStartError} helperText={addStartHelperText} onChange={handleAddStartAmount} variant="outlined" size="small" className="mt-2" value={addStartAmount} type={"number"} fullWidth={true} />
                                                        </div>
                                                        {addStartAmount && (
                                                            <div className='mt-1 text-inp'>
                                                                <label>End Amount</label>
                                                                <TextField error={addEndError} helperText={addEndHelperText} onChange={handleAddEndAmount} variant="outlined" size="small" className="mt-2" value={addEndAmount} type={"number"} fullWidth={true} />
                                                            </div>
                                                        )}
                                                        <div className='mt-1 text-inp'>
                                                            <label>Bonus</label>
                                                            <TextField error={addBonusError} helperText={addBonusHelperText} onChange={handleAddBonus} variant="outlined" size="small" className="mt-2" value={addBonus} type={"number"} fullWidth={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <DialogActions className='mt-2'>
                                                    <Button
                                                        className="btn-popup"
                                                        variant="outlined"
                                                        onClick={() => setAddOpenPopup(false)}
                                                        color="primary"
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        className="btn-popup"
                                                        variant="outlined"
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                </DialogActions>
                                            </form>
                                        </DialogContent>
                                    </Dialog>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </>
    )
}

export default Percentage