import React, { MouseEventHandler } from 'react';

// Styles
import './UserReportButtons.css'


interface UserReportButtonsProps {
    title: String,
    onClick?: MouseEventHandler<HTMLDivElement>
}


function UserReportButtons({ title, onClick }: UserReportButtonsProps) {
    return (
        <div className='user-report-button' onClick={onClick}>
            <p className='user-report-button-text'>{title}</p>
        </div>
    )
}

export default UserReportButtons;