import React, {
  FormEvent,
  ChangeEvent,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "../Casino_Games/Casino_Games.css";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import axios from "axios";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { faTrashCan, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../../../Components/Loader/Loader";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { API_Endpoint } from "../../../../Configuration/Settings";
import { Responsibilities } from "../../../../Configuration/Enums";
import { User_Context } from "../../../../Contexts/User";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import Reusable_Input from "../../../../Components/InputField/InputField";
import Stateful_Select from "../../../../Components/Dropdown/Dropdown";
import { SessionHelper } from "../../../../utils/SessionHelper";
import { toast, ToastContainer } from "react-toastify";

interface CasinoGame {
  _id: string;
  game_name: string;
  Url: string;
  Description: string;
  Image: string;
  MobileUrl: string;
  Branded: string;
  SuperBranded: string;
  hasDemo: string;
  CategoryID: string;
  SortPerCategory: string;
  merchantId: string;
  SubMerchantID: string;
  AR: string;
  IDCountryRestriction: string;
  ImageFullPath: string;
  Sort: string;
  MobilePageCode: string;
  MobileAndroidPageCode: string;
  IsVirtual: string;
  tableId: string;
  CustomSort: string;
  RTP: string;
  status: boolean;
}

const GameCategoryData = [
  "Andar Bahar",
  "Roulette",
  "Dragon Tiger",
  "Lucky Sevens",
  "Poker",
  "Teen Patti",
  "BlackJack",
];

function CasinoList() {
  const [addCasinoPopup, setAddCasinoPopup] = useState(false);
  const [addImagePopup, setAddImagePopup] = useState(false);
  const [miraiPopup, setMiraiPopup] = useState(false);
  const [tableIdPopup, setTableIdPopup] = useState(false);
  const [gameName, setGameName] = useState<string>("");
  const [merchantId, setMerchantId] = useState<string>("");
  const [_id, set_id] = useState<string>("");
  const [urlErrorMessage, setUrlMessage] = useState<string>("");
  const [imageErrorMessage, setImageErrorMessage] = useState<string>("");
  const [urlError, setUrlError] = useState<boolean>(false);
  const [allGame, setAllGame] = useState<Array<CasinoGame>>([]);
  const [imageError, setImageError] = useState<boolean>(false);
  const [gameError, setGameError] = useState<boolean>(false);
  const [categoryError, setCategoryError] = useState<boolean>(false);
  const [imageFullPathError, setImageFullPathError] = useState<boolean>(false);
  const [categoryIdError, setCategoryIdError] = useState<boolean>(false);
  const [merchantIdError, setMerchantIdError] = useState<boolean>(false);
  const [tableIdError, setTableIdError] = useState<boolean>(false);
  const [merchantNameError, setMerchantNameError] = useState<boolean>(false);
  const [gameNameErrorMessage, setGameNameErrorMessage] = useState<string>("");
  const [categoryErrorMessage, setCategoryErrorMessage] = useState<string>("");
  const [categoryIdErrorMessage, setCategoryIdErrorMessage] =
    useState<string>("");
  const [merchantIdErrorMessage, setMerchantIdErrorMessage] =
    useState<string>("");
  const [imageFullPathErrorMessage, setImageFullPathErrorMessage] =
    useState<string>("");
  const [tableIdErrorMessage, setTableIdErrorMessage] = useState<string>("");
  const [merchantNameErrorMessage, setMerchantNameErrorMessage] =
    useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [imgFullPath, setImgFullPath] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");
  const [categoryId, setCategoryId] = useState<string>("");
  const [tableId, setTableId] = useState<string>("");
  const [merchantName, setMerchantName] = useState<string>("");
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1); // Initialize with 1
  const [totalPages, setTotalPages] = useState(1); // Initialize with 1
  const { User } = useContext(User_Context);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);
  const [gameCategoryOptionKey, setGameCategoryOptionKey] =
    useState<string>("");
  const [gameNameSearchKey, setGameNameSearchKey] = useState<string>("");
  const [gameIdSearchKey, setGameIDSearchKey] = useState<string>("");
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [providerNameSearchKey, setProviderNameSearchKey] =
    useState<string>("");
  const [newGameId, setNewGameId] = useState<string>("");
  const [imageGameName, setImageGameName] = useState<string>("");
  const [qtechGameName, setQtechGameName] = useState<string>("");
  const [qtechGameId, setQtechGameId] = useState<string>("");
  const [qtechTableId, setQtechtableId] = useState<string>("");
  const [qtechTableIdText, setQtechtableIdText] = useState<string>("");
  const [qtechTableIdError, setQtechtableIdError] = useState<boolean>(false);
  const [imageGameId, setImageGameId] = useState<string>("");
  const [addImageUrl, setAddImageUrl] = useState<string>("");
  const [addImageUrlText, setAddImageUrlText] = useState<string>("");
  const [addImageError, setAddImageError] = useState<boolean>(false);
  const [lobbySwitch, setLobbySwitch] = useState<boolean>(false);
  const [helixSwitch, setHelixSwitch] = useState<boolean>(false);
  const [catfishSwitch, setCatfishSwitch] = useState<boolean>(false);

  const providerList = useRef([]);

  const getAppConfig = async () => {
    const appConfig = await axios.get(`${API_Endpoint}/configs`);
    SessionHelper.setActiveExchangeProvider(
      appConfig?.data?.data?.payload?.activeCasinoProvider ??
      'QTECH'
    );
  };

  useEffect(() => {
    getAppConfig();
  }, [])

  const activeCasinoProvider = SessionHelper.getActiveExchangeProvider();

  const [activeProvider, setActiveProvider] = useState<string>(activeCasinoProvider);
  const [dropDownPopup, setDropDownPopup] = useState<boolean>(false);
  const activeProviderTempStore = useRef<string>('');


  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const checked = event.target.checked;
    const id = event.target.id;
    const apiUrl = `${API_Endpoint}/Qtech/Edit-Games`;
    const postData = activeCasinoProvider === "QTECH" ? {
      gameId: id,
      status: checked,
    } : {
      _id: id,
      status: checked,
    }
    const token = localStorage.getItem("token");
    if (token) {
      const config = {
        method: "post",
        url: apiUrl,
        data: { token: encryptData(postData) },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          const updatedGameStatus = allGame.map((item) => {
            if (item._id === id) {
              return {
                ...item,
                status: checked,
              };
            } else return item;
          });
          setAllGame(updatedGameStatus);
          getAllGames();
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
        });
    }
  };
  const openDeletePopup = (id: string) => {
    setDeletePopup(true);
    set_id(id);
  };

  const deleteGame = () => {
    setLoading(true);
    setDeletePopup(false);
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .post(
          `${API_Endpoint}/casinoGames/delete`,
          { token: encryptData({ _id: _id }) },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          const updatedGames = allGame.filter((item) => item._id !== _id);
          setAllGame(updatedGames);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
        });
    }
  };

  const getAllGames = () => {
    setLoading(true);
    const apiUrl = `${API_Endpoint}/casinoGames/getDataWithPages`;
    const postData: {
      pageNo: number;
      itemsPerPage: number;
      Filters: { [key: string]: string };
    } = {
      pageNo: currentPage,
      itemsPerPage: itemsPerPage,
      Filters: {},
    };
    if (gameCategoryOptionKey || gameNameSearchKey) {
      postData.Filters["Name"] = gameCategoryOptionKey || gameNameSearchKey;
    }
    if (gameIdSearchKey) {
      activeCasinoProvider === "QTECH" ? postData.Filters["gameId"] = gameIdSearchKey : postData.Filters["Game_Code"] = gameIdSearchKey;
    }
    if (providerNameSearchKey) {
      postData.Filters["provider.name"] = providerNameSearchKey;
    }
    const token = localStorage.getItem("token");
    if (token) {
      const config = {
        method: "post",
        url: apiUrl,
        data: { token: encryptData(postData) },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          response.data.data = decryptData(response.data.data);
          setAllGame(response.data.data.payload.items);
          setTotalPages(response.data.data.payload.totalPages);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          console.log(`${err.message}`);
        });
    }
  };

  const getProviderList = () => {
    const apiUrl = `${API_Endpoint}/Qtech/Get-provider`;
    const token = localStorage.getItem("token");

    if (token) {
      const config = {
        method: "post",
        url: apiUrl,
        data: { token: encryptData({}) },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .request(config)
        .then((response) => {
          response.data.data = decryptData(response.data.data);
          providerList.current = response?.data?.data?.payload || [];
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleAddNewGameSubmit = () => {
    if (!gameName) {
      setGameError(true);
      setGameNameErrorMessage("enter game name");
      return;
    } else if (!newGameId) {
      setCategoryError(true);
      setCategoryErrorMessage("please enter category");
      return;
    }
    setLoading(true);
    const apiUrl = `${API_Endpoint}/Qtech/Add-Games`;
    const token = localStorage.getItem("token");
    const postData = {
      gameId: newGameId,
      Name: gameName,
    };
    if (token) {
      const config = {
        method: "post",
        url: apiUrl,
        data: { token: encryptData(postData) },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .request(config)
        .then((response) => {
          response.data.data = decryptData(response.data.data);
          getAllGames();
          setAddCasinoPopup(false);
          setLoading(false);
          return;
        })
        .catch((error) => {
          console.log(error);
          setAddCasinoPopup(false);
          setLoading(false);
          return;
        });
    }
  };

  const handlePerPage = (newValue: any) => {
    setItemsPerPage(newValue);
  };

  const submitDropDown = () => {
    const apiUrl = `${API_Endpoint}/change-percentage/active-casino-providers`;
    const token = localStorage.getItem("token");
    const data = {
      casinoActiveProvider: activeProviderTempStore.current
    }
    if (token) {
      const config = {
        method: "post",
        url: apiUrl,
        data: { token: encryptData(data) },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .request(config)
        .then((response) => {
          SessionHelper.setActiveExchangeProvider(activeProviderTempStore.current);
          setActiveProvider(activeProviderTempStore.current);
          setDropDownPopup(false)
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.message)
        });
    }
  }


  const handleActiveProvider = (newValue: any) => {
    activeProviderTempStore.current = newValue;
    setDropDownPopup(true);
  };

  useEffect(() => {
    getProviderList();
  }, []);

  useEffect(() => {
    if (activeCasinoProvider) {
      setActiveProvider(activeCasinoProvider)
    }
  }, [activeCasinoProvider]);

  useEffect(() => {
    getAllGames();
  }, [currentPage, itemsPerPage, activeProvider]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    // if (!selectedFile) {
    //   setSelectedFileError(true);
    //   setSelectedFileErrorMessage("Choose file");
    // }
    if (!gameName) {
      setGameError(true);
      setGameNameErrorMessage("enter game name");
    } else if (!category) {
      setCategoryError(true);
      setCategoryErrorMessage("please enter category");
    } else if (!url) {
      setUrlError(true);
      setUrlMessage("please enter url");
    } else if (!imageUrl) {
      setImageError(true);
      setImageErrorMessage("please enter image url");
    } else if (!categoryId) {
      setCategoryIdError(true);
      setCategoryIdErrorMessage("please enter category id message");
    } else if (!merchantId) {
      setMerchantIdError(true);
      setMerchantIdErrorMessage("please select merchant id");
    } else if (!imgFullPath) {
      setImageFullPathError(true);
      setImageFullPathErrorMessage("Please add image full path");
    } else if (!tableId) {
      setTableIdError(true);
      setTableIdErrorMessage("please enter table id ");
    } else if (!merchantName) {
      setMerchantNameError(true);
      setMerchantNameErrorMessage("please add merchant name ");
    } else {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (token) {
        const data = {
          Name: gameName,
          ImageFullPath: imgFullPath,
          Category: category,
          CategoryID: categoryId,
          MerchantID: merchantId,
          TableID: tableId,
          Url: url,
          Image: imageUrl,
          merchantName: merchantName,
          status: status,
        };
        const config = {
          method: "post",
          url: `${API_Endpoint}/casinoGames`,
          data: { token: encryptData(data) },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        axios
          .request(config)
          .then((response) => {
            response.data.data = decryptData(response.data.data);
            setAllGame(response.data.data.payload);
            setAddCasinoPopup(false);
            window.location.reload();
            setLoading(false);
          })
          .catch((err: any) => {
            setLoading(false);
            console.log(`${err.message}`);
          });
      }
    }
  };

  const handleOnSubmitButtonPress = () => {
    if (!isFilterApplied) {
      getAllGames();
      setIsFilterApplied(true);
      return;
    }
    setIsFilterApplied(false);
    setGameCategoryOptionKey("");
    setGameNameSearchKey("");
    setGameIDSearchKey("");
    setProviderNameSearchKey("");
  };

  const GameCategoryDropdown = ({
    title = "Game Category",
    type = "Default",
  }: {
    title?: string;
    type?: "Default" | "New";
  }) => (
    <div
      className={
        type === "Default"
          ? "col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web"
          : ""
      }
    >
      <label className="lbl">{title}</label>
      <Stateful_Select
        value={type === "Default" ? gameCategoryOptionKey : gameName}
        onChange={(newValue) => {
          type === "Default"
            ? setGameCategoryOptionKey(newValue)
            : setGameName(newValue);
        }}
        options={GameCategoryData}
      />
    </div>
  );

  const handleAddImage = (_id: any, name: any) => {
    setImageGameId(_id)
    setImageGameName(name)
    setAddImagePopup(true)
  }
  const handleAddTableId = (name: any, id: any) => {
    setQtechGameName(name)
    setQtechGameId(id)
    setTableIdPopup(true)
  }

  const handleSetImageUrl = (e: ChangeEvent<HTMLInputElement>) => {
    setAddImageError(false)
    setAddImageUrl(e.target.value)
  }

  const handleSetTableId = (e: ChangeEvent<HTMLInputElement>) => {
    setQtechtableIdError(false)
    setQtechtableId(e.target.value)
  }

  const handleCloseImagePopup = () => {
    setAddImagePopup(false)
    setAddImageError(false)
    setAddImageUrlText("")
    setAddImageUrl("")
  }
  const handleCloseTableIdPopup = () => {
    setTableIdPopup(false)
    setQtechtableIdError(false)
    setQtechtableIdText("")
    setQtechtableId("")
  }

  const submitImageUrl = () => {
    if (!addImageUrl) {
      setAddImageError(true)
      setAddImageUrlText("Please enter URL")
    } else {
      setLoading(true)
      let token = localStorage.getItem("token");
      let data = {
        id: imageGameId,
        url: addImageUrl
      }
      const config = {
        method: "post",
        url: `${API_Endpoint}/Qtech/Add-image`,
        data: { token: encryptData(data) },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          response.data.data = decryptData(response.data.data);
          const updatedGameStatus = allGame.map((item) => {
            if (item._id === imageGameId) {
              return {
                ...item,
                Thumbnail: addImageUrl,
              };
            } else return item;
          });
          setAllGame(updatedGameStatus);
          setAddImageUrl("")
          setAddImagePopup(false)
          setLoading(false);
          toast.success("Url uploaded successfully")
        })
        .catch((err: any) => {
          setLoading(false);
          toast.error(err.response.data.messsage)
        });
    }
  }

  const submitTableId = () => {
    if (!qtechTableId) {
      setQtechtableIdError(true)
      setQtechtableIdText("Please Enter Table Id")
    } else {
      setLoading(true)
      let token = localStorage.getItem("token");
      let data = {
        tableId: qtechTableId,
        gameId: qtechGameId
      }
      const config = {
        method: "post",
        url: `${API_Endpoint}/Qtech/Add-tableId`,
        data: { token: encryptData(data) },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          response.data.data = decryptData(response.data.data);
          const updatedGameStatus = allGame.map((item: any) => {
            if (item.gameId === qtechGameId) {
              return {
                ...item,
                tableId: qtechTableId,
              };
            } else return item;
          });
          setAllGame(updatedGameStatus);
          setQtechtableId("")
          setTableIdPopup(false)
          setLoading(false);
          toast.success("Table Id uploaded successfully")
        })
        .catch((err: any) => {
          setLoading(false);
          toast.error(err.response.data.messsage)
        });
    }
  }

  const getSwitchStatus = () => {
    const apiUrl = `${API_Endpoint}/change-percentage/mirai-casino`;
    const token = localStorage.getItem("token");
    if (token) {
      const config = {
        method: "post",
        url: apiUrl,
        data: { token: encryptData({}) },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .request(config)
        .then((response) => {
          const decryptedData = decryptData(response.data.data);
          decryptedData.payload.forEach((item: any) => {
            switch (item.type) {
              case "mirai casino":
                setLobbySwitch(item.status);
                break;
              case "mirai catfish":
                setCatfishSwitch(item.status);
                break;
              case "mirai helix":
                setHelixSwitch(item.status);
                break;
              default:
                break;
            }
          });
        })
        .catch((error) => {
          toast.error(error.message)
        });
    }
  };
  const handleSwitchStatus = (type: string, status: boolean) => {
    const apiUrl = `${API_Endpoint}/change-percentage/status-mirai-casino`;
    const token = localStorage.getItem("token");
    const data = {
      type,
      status
    }
    if (token) {
      const config = {
        method: "post",
        url: apiUrl,
        data: { token: encryptData(data) },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .request(config)
        .then((response) => { })
        .catch((error) => {
          toast.error(error.message)
        });
    }
  };

  const handleLobbyStatus = (event: any) => {
    const newStatus = event.target.checked;
    setLobbySwitch(newStatus);
    handleSwitchStatus("mirai casino", newStatus);
  };

  const handleHelixStatus = (event: any) => {
    const newStatus = event.target.checked;
    setHelixSwitch(newStatus);
    handleSwitchStatus("mirai helix", newStatus);
  };

  const handleCatfishStatus = (event: any) => {
    const newStatus = event.target.checked;
    setCatfishSwitch(newStatus);
    handleSwitchStatus("mirai catfish", newStatus);
  };


  useEffect(() => {
    getSwitchStatus()
  }, [])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="g-sidenav-show  bg-gray-100">
          <ToastContainer autoClose={2000} position="top-center" />
          <Sidenav />
          <main className="main-content position-relative">
            <div style={{ background: "#f8f9fa" }}>
              <Breadcrumbs
                tab={"Casino Games"}
                button={
                  User.data.Responsibilities.includes(
                    Responsibilities.Add_Game
                  ) && (
                    <Button
                      onClick={() => setAddCasinoPopup(true)}
                      className="btn-payment"
                      variant="contained"
                    >
                      Add
                    </Button>
                  )
                }
              />
              <div>
                <Dialog
                  open={addCasinoPopup}
                  onClose={() => setAddCasinoPopup(false)}
                >
                  <DialogContent>
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div>
                        <Reusable_Input
                          label={"Game ID"}
                          value={newGameId}
                          error={gameError}
                          helperText={gameNameErrorMessage}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setGameError(false);
                            setNewGameId(event.target.value);
                          }}
                          type={"text"}
                          fullWidth={true}
                        />
                      </div>
                      <div className="mt-2">
                        {/* <Reusable_Input
                                                    label={"Category"}
                                                    value={category}
                                                    error={categoryError}
                                                    helperText={
                                                        categoryErrorMessage
                                                    }
                                                    onChange={(
                                                        event: ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        setCategoryError(false);
                                                        setCategory(
                                                            event.target.value
                                                        );
                                                    }}
                                                    type={"text"}
                                                    fullWidth={true}
                                                /> */}
                        <GameCategoryDropdown
                          title={"Game Name"}
                          type={"New"}
                        />
                      </div>
                      {/* <div className="mt-2">
                                                <Reusable_Input
                                                    label={"Url"}
                                                    value={url}
                                                    error={urlError}
                                                    helperText={urlErrorMessage}
                                                    onChange={(
                                                        event: ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        setUrlError(false);
                                                        setUrl(
                                                            event.target.value
                                                        );
                                                    }}
                                                    type={"text"}
                                                    fullWidth={true}
                                                />
                                            </div>
                                            <div className="mt-2">
                                                <Reusable_Input
                                                    label={"Image URL"}
                                                    value={imageUrl}
                                                    error={imageError}
                                                    helperText={
                                                        imageErrorMessage
                                                    }
                                                    onChange={(
                                                        event: ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        setImageError(false);
                                                        setImageUrl(
                                                            event.target.value
                                                        );
                                                    }}
                                                    type={"text"}
                                                    fullWidth={true}
                                                />
                                            </div>
                                            <div>
                                                <TextField
                                                    className="mt-2"
                                                    onChange={(
                                                        event: ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        setImageFullPathError(
                                                            false
                                                        );
                                                        setImgFullPath(
                                                            event.target.value
                                                        );
                                                    }}
                                                    // error={linkError}
                                                    // helperText={linkHelperText}
                                                    value={imgFullPath}
                                                    id="outlined-basic"
                                                    type="text"
                                                    fullWidth
                                                    label="Image Full Path"
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{
                                                        style: {
                                                            fontFamily:
                                                                "Roboto",
                                                            fontSize: "14px",
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div className="mt-2">
                                                <Reusable_Input
                                                    label={"Category Id"}
                                                    value={categoryId}
                                                    error={categoryIdError}
                                                    helperText={
                                                        categoryIdErrorMessage
                                                    }
                                                    onChange={(
                                                        event: ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        setCategoryIdError(
                                                            false
                                                        );
                                                        setCategoryId(
                                                            event.target.value
                                                        );
                                                    }}
                                                    type={"text"}
                                                    fullWidth={true}
                                                />
                                            </div> */}
                      {/* <div className="mt-2">
                                                <Reusable_Input
                                                    label={"Merchant Id"}
                                                    value={merchantId}
                                                    error={merchantIdError}
                                                    helperText={
                                                        merchantIdErrorMessage
                                                    }
                                                    onChange={(
                                                        event: ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        setMerchantIdError(
                                                            false
                                                        );
                                                        setMerchantId(
                                                            event.target.value
                                                        );
                                                    }}
                                                    type={"text"}
                                                    fullWidth={true}
                                                />
                                            </div> */}
                      {/* <div className="mt-2">
                                                <Reusable_Input
                                                    label={"Table Id"}
                                                    value={tableId}
                                                    error={tableIdError}
                                                    helperText={
                                                        tableIdErrorMessage
                                                    }
                                                    onChange={(
                                                        event: ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        setTableIdError(false);
                                                        setTableId(
                                                            event.target.value
                                                        );
                                                    }}
                                                    type={"text"}
                                                    fullWidth={true}
                                                />
                                            </div> */}

                      {/* <div>
                                                <TextField
                                                    className="mt-2"
                                                    onChange={(
                                                        event: ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        setMerchantName(
                                                            event.target.value
                                                        );
                                                    }}
                                                    // error={linkError}
                                                    // helperText={linkHelperText}
                                                    value={merchantName}
                                                    id="outlined-basic"
                                                    type="text"
                                                    fullWidth
                                                    label="Merchant Name"
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{
                                                        style: {
                                                            fontFamily:
                                                                "Roboto",
                                                            fontSize: "14px",
                                                        },
                                                    }}
                                                />
                                            </div> */}
                      {/* <div>
                                                <FormGroup className="mt-2">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={status}
                                                                onChange={(e) =>
                                                                    setStatus(
                                                                        e.target
                                                                            .checked
                                                                    )
                                                                }
                                                                color="primary"
                                                            />
                                                        }
                                                        label={
                                                            status
                                                                ? "Active"
                                                                : "Inactive"
                                                        }
                                                    />
                                                </FormGroup>
                                            </div> */}

                      <DialogActions>
                        <Button
                          className="btn-popup"
                          variant="outlined"
                          onClick={() => setAddCasinoPopup(false)}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn-popup"
                          variant="outlined"
                          type="submit"
                          color="primary"
                          onClick={handleAddNewGameSubmit}
                        >
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  </DialogContent>
                </Dialog>
              </div>
              <div>
                <Dialog
                  open={deletePopup}
                  onClose={() => setDeletePopup(false)}
                >
                  <DialogTitle>Are You Sure ?</DialogTitle>
                  <DialogContent>
                    <DialogActions className="mt-n3">
                      <Button
                        className="btn-popup"
                        variant="outlined"
                        onClick={() => setDeletePopup(false)}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn-popup"
                        variant="outlined"
                        type="submit"
                        color="primary"
                        onClick={() => {
                          deleteGame();
                        }}
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </DialogContent>
                </Dialog>
              </div>
              <div>
                <Dialog
                  open={addImagePopup}
                  onClose={() => setAddImagePopup(false)}
                >
                  <DialogContent>
                    <div>
                      <Reusable_Input
                        value={imageGameName}
                        type={"text"}
                        fullWidth={true}
                        isDisabled={true}
                      />
                    </div>
                    <div className="mt-3">
                      <Reusable_Input
                        value={addImageUrl}
                        error={addImageError}
                        helperText={addImageUrlText}
                        onChange={handleSetImageUrl}
                        label="Enter Image Url"
                        type={"text"}
                        fullWidth={true}
                      />
                    </div>
                    <DialogActions className="mt-3">
                      <Button
                        className="btn-popup"
                        variant="outlined"
                        onClick={handleCloseImagePopup}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn-popup"
                        variant="outlined"
                        type="submit"
                        color="primary"
                        onClick={submitImageUrl}
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </DialogContent>
                </Dialog>
              </div>
              <div>
                <Dialog
                  open={miraiPopup}
                  onClose={() => setMiraiPopup(false)}
                >
                  <DialogContent>
                    <div className="mirai-games">
                      <span>Lobby</span>
                      <Switch
                        {...label}
                        checked={lobbySwitch}
                        onChange={handleLobbyStatus}
                      />
                    </div>
                    <div className="mirai-games">
                      <span>Helix</span>
                      <Switch
                        {...label}
                        checked={helixSwitch}
                        onChange={handleHelixStatus}
                      />
                    </div>
                    <div className="mirai-games">
                      <span>Catfish</span>
                      <Switch
                        {...label}
                        checked={catfishSwitch}
                        onChange={handleCatfishStatus}
                      />
                    </div>
                    <div className="mt-1 d-flex justify-content-center">
                      <DialogActions>
                        <Button
                          className="btn-popup"
                          variant="outlined"
                          onClick={() => setMiraiPopup(false)}
                          color="primary"
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-1">
                    <label className="lbl">Active Casino Provider</label>
                    <Stateful_Select
                      value={activeProvider}
                      onChange={(newValue: any) => handleActiveProvider(newValue)}
                      options={[
                        "WACS",
                        "QTECH",
                      ]}
                    />
                  </div>
                  <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-1">
                    <Button onClick={() => setMiraiPopup(true)} className="mt-4" variant="contained">Mirai Games</Button>
                  </div>
                </div>
              </div>
              {dropDownPopup && (
                <Dialog open={dropDownPopup} onClose={() => setDropDownPopup(false)}>
                  <DialogTitle>Are You Sure?</DialogTitle>
                  <DialogContent>
                    <DialogActions className="mt-n3">
                      <Button className="btn-popup" variant="outlined" color="primary" onClick={() => setDropDownPopup(false)}>
                        Cancel
                      </Button>
                      <Button className="btn-popup" variant="outlined" type="submit" color="primary" onClick={submitDropDown}>
                        Submit
                      </Button>
                    </DialogActions>
                  </DialogContent>
                </Dialog>
              )}
              <div className="container-fluid">
                <div className="row">
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-1">
                      <label className="lbl">Items Per Page</label>
                      <Stateful_Select
                        value={itemsPerPage.toString()}
                        onChange={(newValue: any) => handlePerPage(newValue)}
                        options={[
                          "10",
                          "25",
                          "50",
                          "75",
                          "100",
                          "120",
                          "150",
                          "200",
                        ]}
                      />
                    </div>
                    <GameCategoryDropdown />
                    <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web">
                      <label className="lbl">Game Name</label>
                      <Reusable_Input
                        label={"Enter Game Name"}
                        value={gameNameSearchKey}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setGameNameSearchKey(event.target.value);
                        }}
                        type={"text"}
                        fullWidth={true}
                        error={false}
                        helperText={""}
                        isDisabled={!!gameCategoryOptionKey?.length}
                        customInputTextStyles={{
                          padding: "6px",
                        }}
                      />
                    </div>
                    <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web">
                      <label className="lbl">{activeCasinoProvider === "WACS" ? "Game Code" : "Game Id"}</label>
                      <Reusable_Input
                        label={activeCasinoProvider === "WACS" ? "Enter Game Code" : "Enter Game ID"}
                        value={gameIdSearchKey}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setGameIDSearchKey(event.target.value);
                        }}
                        type={"text"}
                        fullWidth={true}
                        error={false}
                        helperText={""}
                        customInputTextStyles={{
                          padding: "6px",
                        }}
                      />
                    </div>
                    {activeCasinoProvider === "QTECH" && (<div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web">
                      <label className="lbl">Provder Name</label>
                      <Stateful_Select
                        value={providerNameSearchKey}
                        onChange={(newValue) => {
                          setProviderNameSearchKey(newValue);
                        }}
                        options={providerList.current}
                      />
                    </div>)}

                    <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web">
                      <Button
                        onClick={handleOnSubmitButtonPress}
                        className="sech-button px-2 py-1"
                        variant="contained"
                        disabled={
                          !gameCategoryOptionKey &&
                          !gameNameSearchKey &&
                          !gameIdSearchKey &&
                          !providerNameSearchKey
                        }
                      >
                        {isFilterApplied ? "Clear Data" : "Submit"}
                      </Button>
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="table-responsive">
                      <table className="table table-view">
                        <thead>
                          <tr>
                            <th></th>
                            {/* id, name, gameId, provider name, provider id, category, */}
                            <th>ID</th>
                            <th>Game Name</th>
                            {activeCasinoProvider === "QTECH" ? <th>Game ID</th> : <th>Game Code</th>}
                            {activeCasinoProvider === "QTECH" && <th>Table ID</th>}
                            <th>Provider ID</th>
                            <th>Category</th>
                            <th>Image</th>
                            {activeCasinoProvider === "WACS" && <th>Add Image</th>}
                            {User.data.Responsibilities.includes(
                              Responsibilities.Toggle_Game
                            ) && <th>Status</th>}
                            {/* <th>Action</th> */}
                          </tr>
                        </thead>

                        <tbody>
                          {allGame.map((item: any, index: number) => {
                            return (
                              <>
                                <tr id={item._id}>
                                  <td>{index + 1 + (currentPage && itemsPerPage ? (currentPage - 1) * itemsPerPage : 0)}</td>
                                  <td>{item?._id}</td>
                                  <td>{item?.Name}</td>
                                  {activeCasinoProvider === "WACS" ? <td>{item?.Game_Code}</td> : <td>{item?.gameId}</td>}
                                  {activeCasinoProvider === "QTECH" &&
                                    <td>
                                      <span className="d-flex justify-content-center">
                                        {item.tableId}<FontAwesomeIcon
                                          id={item._id}
                                          className="fa fa-pencil-square icon-home icon-trash "
                                          icon={faPlus}
                                          onClick={() => handleAddTableId(item.Name, item.gameId)}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </span>
                                    </td>
                                  }
                                  {activeCasinoProvider === "WACS" ? <td>{item?.Provider_ID}</td> : <td>{item?.provider?.id}</td>}
                                  {activeCasinoProvider === "WACS" ? <td>{item?.Category_ID}</td> : <td>{item?.category}</td>}
                                  <td>
                                    <img
                                      className="image-casino"
                                      src={activeCasinoProvider === "WACS" ? item?.Thumbnail : (item?.images?.[1]?.url ?? "")}
                                    ></img>
                                  </td>
                                  {activeCasinoProvider === "WACS" &&
                                    <td>
                                      <Button
                                        onClick={() => handleAddImage(item._id, item.Name)}
                                        className="btn-payment"
                                        variant="contained"
                                      >
                                        Add
                                      </Button>
                                    </td>}
                                  {User.data.Responsibilities.includes(
                                    Responsibilities.Toggle_Game
                                  ) && (
                                      <td>
                                        <Switch
                                          id={activeCasinoProvider === "QTECH" ? item.gameId : item._id}
                                          {...label}
                                          checked={item.status}
                                          color="primary"
                                          //onChange={(e) => setActive(e.target.checked)}
                                          onChange={(e) => handleSwitchChange(e)}
                                        />
                                      </td>
                                    )}
                                  {/* <td>
                                    {User.data.Responsibilities.includes(Responsibilities.Delete_Game) && <span>
                                      <FontAwesomeIcon
                                        //id
                                        className="fa fa-pencil-square icon-home icon-trash "
                                        icon={faTrashCan}
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          openDeletePopup(item._id)
                                        }
                                      />
                                    </span>}
                                  </td> */}
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <Dialog
                        open={tableIdPopup}
                        onClose={() => setTableIdPopup(false)}
                      >
                        <DialogContent>
                          <div>
                            <Reusable_Input
                              value={qtechGameName}
                              type={"text"}
                              fullWidth={true}
                              isDisabled={true}
                            />
                          </div>
                          <div className="mt-3">
                            <Reusable_Input
                              value={qtechTableId}
                              error={qtechTableIdError}
                              helperText={qtechTableIdText}
                              onChange={handleSetTableId}
                              label="Enter Table Id"
                              type={"text"}
                              fullWidth={true}
                            />
                          </div>
                          <DialogActions className="mt-3">
                            <Button
                              className="btn-popup"
                              variant="outlined"
                              onClick={handleCloseTableIdPopup}
                              color="primary"
                            >
                              Cancel
                            </Button>
                            <Button
                              className="btn-popup"
                              variant="outlined"
                              type="submit"
                              color="primary"
                              onClick={submitTableId}
                            >
                              Submit
                            </Button>
                          </DialogActions>
                        </DialogContent>
                      </Dialog>
                    </div>
                    <ul className="pagination d-flex justify-content-center">
                      <Stack spacing={2}>
                        <Pagination
                          count={totalPages}
                          color="secondary"
                          page={currentPage}
                          onChange={(event, newPage) => setCurrentPage(newPage)}
                        />
                      </Stack>
                    </ul>
                  </div>
                </div>
                <footer className="footer">
                  <div className="container-fluid">
                    <div className="row align-items-center justify-content-lg-between">
                      <div className="col-lg-6 mb-lg-0  ">
                        <div className="copyright text-center text-sm text-muted text-lg-start">
                          © fairbets.co
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              About Us
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Blog
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link pe-0 text-muted"
                              target="_blank"
                            >
                              License
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
}

export default CasinoList;