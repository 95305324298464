import React, { useState, useContext, ChangeEvent, FormEvent } from 'react'
import "../Coins/coins.css"
import Reusable_Input from '../InputField/InputField'
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import axios from 'axios';
import { API_Endpoint } from '../../Configuration/Settings';
import { encryptData } from '../../utils/encryptData';
import { decryptData } from '../../utils/decryptData';
import Loader from '../Loader/Loader';
import { User_Context } from '../../Contexts/User';
import { ToastContainer, toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import { TextField } from '@mui/material';

interface Payload {
    token: string;
    remark?: string;
}

function BonusWalletCoins() {
    const [amount, setAmount] = useState<string>("")
    const [amountError, setAmountError] = useState<boolean>(false)
    const [amountHelperText, setAmountHelperText] = useState<string>("")
    const [userRemark, setUserRemark] = useState<string>("");
    const [userRemarkError, setUserRemarkError] = useState<boolean>(false)
    const [userRemarkText, setUserRemarkText] = useState<string>("")


    const [selectedOption, setSelectedOption] = useState<string>("");
    const [typeError, setTypeError] = useState<boolean>(false);

    const { User } = useContext(User_Context)
    const { User_ID } = useParams();


    const handleDropdownChange = (event: SelectChangeEvent<string>) => {
        setSelectedOption(event.target.value);
        setTypeError(false);
    };

    const handleAmount = (e: ChangeEvent<HTMLInputElement>) => {
        setAmountError(false)
        setAmount(e.target.value)
    }

    const [loading, setLoading] = useState<boolean>(false);

    const handleAddCoin = (e: any) => {
        e.preventDefault()
        if (!amount) {
            setAmountError(true)
            setAmountHelperText("Please enter amount")
        } else {
            setLoading(true)
            let obj : any = {
                bonusBy: {
                    name: User.data.name,
                    _id: User.data._id,
                    type: selectedOption,
                    transaction: "credit",
                },
                userId: User_ID,
                amount: amount,
                type: selectedOption,
                remark: userRemark
            }
            let payload:any = {
                token: encryptData(obj)
            };
            const token = localStorage.getItem("token");
            axios
                .post(
                    `${API_Endpoint}/bonus-wallet/add-bonus`,
                    payload,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response) => {
                    setLoading(false)
                    toast.success("Bonus coins added successfully");
                    setAmount("")
                    setSelectedOption("")
                    setUserRemark("")
                })
                .catch((err) => {
                    toast.error(err.message);
                    setLoading(false);
                });
        }
    }

    return (
        <>
            {loading ? (<Loader />) : (
                <div className='parent-container'>
                    <div className='centered-div'>
                        <div className='mt-2 text-inp'>
                            <label>Amount</label>
                            <Reusable_Input label={"Enter Amount"} value={amount} error={amountError} helperText={amountHelperText} onChange={handleAmount} type={"number"} fullWidth={true} />
                        </div>
                        <div className='mt-2'>
                            <label>Reason</label>
                            <div className='mt-n2'>
                                <FormControl
                                    className="form-banner mt-2"
                                    sx={{ m: 1, width: 300 }}
                                    size="small"
                                >
                                    <InputLabel
                                        className="label-banner"
                                        id="demo-select-small-label"
                                    >
                                        Select
                                    </InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={selectedOption}
                                        label="Select"
                                        fullWidth
                                        onChange={handleDropdownChange}
                                        error={typeError}
                                    >
                                        <MenuItem value="manualBonusUserFirstDeposit">User First Deposit Bonus</MenuItem>
                                        <MenuItem value="manualBonusUserOtherDeposit">User Other Deposit Bonus</MenuItem>
                                        <MenuItem value="manualBonusReferralFirstDeposit">Referral First Deposit Bonus</MenuItem>
                                        <MenuItem value="manualBonusReferralOtherDeposit">Referral Other Deposit Bonus</MenuItem>
                                        <MenuItem value="other">Enter Manually</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        {selectedOption === "other" && (
                            <div className='mt-2'>
                                <label>Enter Reason</label>
                                <TextField
                                    onChange={(e) => setUserRemark(e.target.value)}
                                    value={userRemark}
                                    id="outlined-basic"
                                    type="text"
                                    fullWidth
                                    error={userRemarkError}
                                    helperText={userRemarkText}
                                    multiline={true}
                                    label="Enter Reason Remark"
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{
                                        style: {
                                            fontFamily: "Roboto",
                                            fontSize: "14px",
                                        },
                                    }}
                                />
                            </div>
                        )}
                        <div className='d-flex justify-content-around mt-4 mb-4'>
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(e: any) => handleAddCoin(e)}
                                >
                                    Add Bonus Coins
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}

export default BonusWalletCoins