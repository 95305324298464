import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { faPencilSquare } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../../Components/Loader/Loader";
import { API_Endpoint } from "../../../../Configuration/Settings";
import { Responsibilities } from "../../../../Configuration/Enums";
import { User_Context } from "../../../../Contexts/User";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import Reusable_Input from "../../../../Components/InputField/InputField";
import { dateTime } from "../../../../utils/utility";
import { toast } from "react-toastify";


interface Utr {
    _id: string,
    BankName: string,
    accountNumber: string,
    accountHolderName: string,
    ifsc: string,
    status: boolean,
    pendingTotal: number,
    approvedTotal: number
}


function UtrProvider() {
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [addPopup, setAddPopup] = useState<boolean>(false);
    const [utrData, setUtrData] = useState<Utr[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [id, setId] = useState<string>("");


    const label = { inputProps: { "aria-label": "Switch demo" } };

    const [bankName, setBankName] = useState<string>("");
    const [bankNameError, setBankNameError] = useState<boolean>(false);
    const [bankNameErrorHelperText, setBankNameErrorHelperText] = useState<string>("");

    const [accHolderName, setAccHolderName] = useState<string>("");
    const [accHolderNameError, setAccHolderNameError] = useState<boolean>(false);
    const [accHolderHelperText, setAccHolderHelperText] = useState<string>("");

    const [accountNo, setAccountNo] = useState<string>("");
    const [accountNoError, setAccountNoError] = useState<boolean>(false);
    const [accountNoHelperText, setAccountNoHelperText] = useState<string>("");

    const [ifsc, setIfsc] = useState<string>("");
    const [ifscError, setIfscError] = useState<boolean>(false);
    const [ifscHelperText, setIfscHelperText] = useState<string>("");

    const openAddDialog = () => {
        setAddPopup(true)
    }

    const clearDate = () => {
        setStartDate('')
        setEndDate('')
    }


    const handleBankName = (event: ChangeEvent<HTMLInputElement>) => {
        setBankName(event.target.value);
        setBankNameError(false);
    };

    const handleAccHolderName = (event: ChangeEvent<HTMLInputElement>) => {
        setAccHolderName(event.target.value);
        setAccHolderNameError(false);
    };

    const handleAccountNo = (event: ChangeEvent<HTMLInputElement>) => {
        setAccountNo(event.target.value);
        setAccountNoError(false);
    };

    const handleIfsc = (event: ChangeEvent<HTMLInputElement>) => {
        setIfsc(event.target.value);
        setIfscError(false);
    };

    const openDeletePopup = (id: string) => {
        setDeletePopup(true);
        setId(id);
    };

    const handleStatusChange = (event: ChangeEvent<HTMLInputElement>, bankName: string) => {
        setLoading(true);
        const status = event.target.checked;
        const _id = event.target.id;
        const token = localStorage.getItem("token");
        let data = {
            _id: _id,
            status: status
        }
        axios
            .post(
                `${API_Endpoint}/utrProvider/update`,
                {
                    token: encryptData(data)
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(() => {
                const updatedStatusData = utrData.map((item) => {
                    if (item._id === _id) {
                        return {
                            ...item,
                            status: status,
                        };
                    } else if (item.BankName === bankName && item._id !== _id) {
                        return {
                            ...item,
                            status: false,
                        };
                    } else {
                        return item;
                    }
                });
                setUtrData(updatedStatusData)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const getData = () => {
        let filter = {}
        if (startDate && endDate) {
            filter = { startDate: startDate, endDate: endDate }
        } else {
            // const currentDate = new Date().toISOString().split('T')[0];
            const currentDate = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0];
            filter = { startDate: dateTime(currentDate), endDate: dateTime(currentDate) }
        }
        setLoading(true);
        const token = localStorage.getItem("token");
        axios
            .post(`${API_Endpoint}/utrProvider/getAll`, { token: encryptData(filter) }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                response.data.data = decryptData(response.data.data);
                setUtrData(response.data.data.payload)
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    }

    const handleSubmitData = (e: FormEvent) => {
        e.preventDefault()
        if (!bankName) {
            setBankNameError(true);
            setBankNameErrorHelperText("Enter bank name");
        } else if (!accountNo) {
            setAccountNoError(true);
            setAccountNoHelperText("Enter account no");
        } else if (!accHolderName) {
            setAccHolderNameError(true);
            setAccHolderHelperText("Enter account holder name");
        } else if (!ifsc) {
            setIfscError(true);
            setIfscHelperText("Enter ifsc");
        } else {
            const token = localStorage.getItem("token");
            setLoading(true)
            let data = {
                bankName: bankName,
                accountNumber: accountNo,
                accountHolderName: accHolderName,
                ifsc: ifsc,
                status: false
            }
            axios
                .post(
                    `${API_Endpoint}/utrProvider`,
                    {
                        token: encryptData(data)
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((res) => {
                    setLoading(false);
                    setAddPopup(false)
                    setBankName("")
                    setBankNameError(false)
                    setAccountNo("")
                    setAccountNoError(false)
                    setAccHolderName("")
                    setAccHolderNameError(false)
                    setIfsc("")
                    setIfscError(false)
                    getData()
                })
                .catch((err) => {
                    toast.error(err.response.data.message)
                    setLoading(false);
                });
        }
    }

    const deleteUtrProvider = () => {
        setLoading(true);
        setDeletePopup(false);
        const token = localStorage.getItem("token");
        axios
            .post(`${API_Endpoint}/utrprovider/delete`, { token: encryptData({ _id: id }) }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(() => {
                const updatedData = utrData.filter((item) => item._id !== id);
                setUtrData(updatedData);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const handleClosePopup = () => {
        setAddPopup(false)
        setBankName("")
        setBankNameError(false)
        setAccountNo("")
        setAccountNoError(false)
        setAccHolderName("")
        setAccHolderNameError(false)
        setIfsc("")
        setIfscError(false)
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            {loading ? (<Loader />) : (<div>
                <Sidenav />
                <main className="main-content position-relative">
                    <div >
                        <Breadcrumbs tab={"UTR Providers"} button={<Button onClick={openAddDialog} className="btn-payment" variant="contained">Add</Button>} />
                        <div className="container-fluid">
                            <div className="row tp-form">
                                <div className="col-6 col-xl-2 col-sm-3 pdrt">
                                    <label className="lbl">From Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="From Date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                </div>
                                <div className="col-6 col-xl-2 col-sm-3 pdrt">
                                    <label className="lbl">To Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="To Date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </div>
                                <div className="col-6 col-xl-2 col-sm-3 pdrt">
                                    <label className="lbl"></label>
                                    <a className="sechBtn mt-1" onClick={getData}>
                                        Apply
                                    </a>
                                </div>
                                <div className="col-6 col-xl-2 col-sm-3 pdrt">
                                    <label className="lbl"></label>
                                    <a onClick={clearDate} className="sechBtn mt-1">
                                        Clear Dates
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Dialog open={addPopup} onClose={handleClosePopup}>
                                <DialogContent>
                                    <form>
                                        <div>
                                            <Reusable_Input type={"text"} label={"Enter Bank Name"} fullWidth={true} value={bankName} error={bankNameError} helperText={bankNameErrorHelperText} onChange={handleBankName} />
                                        </div>
                                        <div className="mt-2">
                                            <Reusable_Input type={"number"} label={"Enter Bank Account Number"} fullWidth={true} value={accountNo} error={accountNoError} helperText={accountNoHelperText} onChange={handleAccountNo} />
                                        </div>
                                        <div className="mt-2">
                                            <Reusable_Input type={"text"} label={"Enter Account Holder Name"} fullWidth={true} value={accHolderName} error={accHolderNameError} helperText={accHolderHelperText} onChange={handleAccHolderName} />
                                        </div>
                                        <div className="mt-2 mb-2">
                                            <Reusable_Input type={"text"} label={"Enter IFSC Name"} fullWidth={true} value={ifsc.toUpperCase()} error={ifscError} helperText={ifscHelperText} onChange={handleIfsc} />
                                        </div>
                                        <DialogActions>
                                            <Button
                                                className="btn-popup"
                                                variant="outlined"
                                                onClick={handleClosePopup}
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className="btn-popup"
                                                variant="outlined"
                                                type="submit"
                                                color="primary"
                                                onClick={handleSubmitData}
                                            >
                                                Submit
                                            </Button>
                                        </DialogActions>
                                    </form>
                                </DialogContent>
                            </Dialog>
                        </div>
                        <div>
                            <Dialog open={deletePopup} onClose={() => setDeletePopup(false)}>
                                <DialogTitle>Are You Sure ?</DialogTitle>
                                <DialogContent>
                                    <DialogActions className="mt-n3">
                                        <Button
                                            className="btn-popup"
                                            variant="outlined"
                                            onClick={() => setDeletePopup(false)}
                                            color="primary"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="btn-popup"
                                            variant="outlined"
                                            type="submit"
                                            color="primary"
                                            onClick={() => deleteUtrProvider()}
                                        >
                                            Delete
                                        </Button>
                                    </DialogActions>
                                </DialogContent>
                            </Dialog>
                        </div>
                        <div className="container-fluid mt-2">
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table table-view">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Total Amount</th>
                                                    <th>Account Holder Name</th>
                                                    <th>Bank Name</th>
                                                    <th>Bank Account Number</th>
                                                    <th>IFSC</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {utrData.map((item, index) => {
                                                    return (
                                                        <tr key={item._id}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                Approved - <b>{item.approvedTotal}</b> <br />
                                                                Pending -  <b>{item.pendingTotal}</b>
                                                            </td>
                                                            <td>{item.accountHolderName}</td>
                                                            <td>{item.BankName}</td>
                                                            <td>{item.accountNumber}</td>
                                                            <td>{item.ifsc}</td>
                                                            <td>
                                                                <Switch
                                                                    {...label}
                                                                    id={item._id}
                                                                    checked={item.status}
                                                                    onChange={(event) => handleStatusChange(event, item.BankName)}
                                                                />
                                                            </td>
                                                            <td><FontAwesomeIcon
                                                                id={item._id}
                                                                className="fa fa-pencil-square icon-home icon-trash "
                                                                icon={faTrashCan}
                                                                onClick={() => openDeletePopup(item._id)}
                                                                style={{ cursor: "pointer" }}
                                                            /></td>
                                                        </tr>
                                                    )

                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>)}

        </>

    )
}

export default UtrProvider