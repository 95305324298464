import React, { useState, useContext, useEffect, ChangeEvent } from 'react'
import { API_Endpoint } from '../../../../Configuration/Settings';
import { User_Context } from '../../../../Contexts/User';
import { encryptData } from '../../../../utils/encryptData';
import { decryptData } from '../../../../utils/decryptData';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useParams } from 'react-router-dom'
import Loader from '../../../../Components/Loader/Loader'
import { Pagination, Stack } from '@mui/material'
import TableFiltersSelectBox from '../../../../Components/TableFilters/TableFiltersSelectBox'
import { formatDate, formatedTime } from '../../../../utils/utility';

function JetFairHistory() {
    const [loading, setLoading] = useState(false)
    const [historyData, setHistoryData] = useState([]);
    const { User } = useContext(User_Context)
    const { User_ID, userName } = useParams();
    const [itemsPerPage, setItemsPerPage] = useState<number>(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [transactionId, setTransactionId] = useState<string>("");
    const [searchTransactionCode, setSearchTransactionCode] = useState<string>("");
    const [searchTransactionType, setSearchTransactionType] = useState<string>("");
    const [searchMarketId, setSearchMarketId] = useState<string>("");
    const [searchMarketName, setSearchMarketName] = useState<string>("");
    const [searchRunnerName, setSearchRunnertName] = useState<string>("");
    const [searchRate, setSearchRate] = useState<string>("");
    const [searchStake, setSearchStake] = useState<string>("");



    const getJetFairHistory = async () => {
        setLoading(true)
        let payload: {
            amount?: number, status?: string, pageNo?: number,
            transactionId?: string, gameReference?: string,
            roundId?: string, winAmount?: number, itemsPerPage?: number,
            filter: { [key: string]: string | number },
        }
        payload = {
            itemsPerPage: itemsPerPage,
            pageNo: currentPage,
            filter: {
                clientUsername: User_ID?.toString() ?? ''
            }
        };
        let config = {
            method: "post",
            url: `${API_Endpoint}/User/get-jetfair-history`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${User.token}`,
            },
            data: {
                token: encryptData(payload)
            },
        };
        axios.request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data);
                console.log(response.data.data)
                setHistoryData(response.data.data.payload.items);
                setTotalPages(response.data.data.payload.totalPages);
                setLoading(false)
            }).catch((error) => {
                toast.error(error.response.data.message)
                setLoading(false)
            });
    }

    const onSearch = () => {
        setLoading(true)
        let payload: {
            amount?: number, status?: string, pageNo?: number,
            transactionId?: string, roundId?: string,
            itemsPerPage?: number, filter: { [key: string]: string | number },
        }
        payload = {
            itemsPerPage: itemsPerPage,
            pageNo: currentPage,
            filter: {
                clientUsername: User_ID?.toString() ?? ''
            }
        };
        if (transactionId) {
            payload.filter["transactionId"] = (transactionId);
        }
        if (searchTransactionCode) {
            payload.filter["transactionCode"] = (searchTransactionCode);
        }
        if (searchTransactionType) {
            payload.filter["transactionType"] = (searchTransactionType);
        }
        if (searchMarketId) {
            payload.filter["marketId"] = (searchMarketId);
        }
        if (searchMarketName) {
            payload.filter["marketName"] = (searchMarketName);
        }
        if (searchRunnerName) {
            payload.filter["runnerName"] = (searchRunnerName);
        }
        if (searchRate) {
            payload.filter["rate"] = (searchRate);
        }
        if (searchStake) {
            payload.filter["stake"] = (searchStake);
        }
        let config = {
            method: "post",
            url: `${API_Endpoint}/User/get-jetfair-history`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${User.token}`,
            },
            data: {
                token: encryptData(payload)
            },
        };
        axios.request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data);
                console.log(response.data.data)
                setHistoryData(response.data.data.payload.items);
                setTotalPages(response.data.data.payload.totalPages);
                setLoading(false)
            }).catch((error) => {
                toast.error(error.response.data.message)
                setLoading(false)
            });
    }

    useEffect(() => {
        getJetFairHistory()
    }, [])

    useEffect(() => {
        onSearch();
    }, [currentPage]);

    const handleTransactionId = (event: ChangeEvent<HTMLInputElement>) => {
        setTransactionId(event.target.value);
    };

    const handleTransactionCode = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTransactionCode(event.target.value);
    };

    const handleTransactionType = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTransactionType(event.target.value);
    };

    const handleMarketId = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchMarketId(event.target.value);
    };

    const handleMarketName = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchMarketName(event.target.value);
    };

    const handleRunnerName = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchRunnertName(event.target.value);
    };

    const handleRate = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchRate(event.target.value);
    };

    const handleStake = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchStake(event.target.value);
    };


    return (
        <>
            {loading && (<Loader />)}
            <div className="row mt-4">
                <ToastContainer autoClose={2000} position="top-center" />
                <div className="table-responsive">
                    <table className="table table-view">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Transaction Id</th>
                                <th>Transaction Code</th>
                                <th>Transaction Type</th>
                                <th>Market Id</th>
                                <th>Market Name</th>
                                <th>Runner Name</th>
                                <th>Game Name</th>
                                <th>Rate</th>
                                <th>Stake</th>
                                <th>Run</th>
                                <th>Bet Type</th>
                                <th>Bet Status</th>
                                <th>Site Code</th>
                                <th>Currency</th>
                                <th>Description</th>
                                <th>Commission Amount</th>
                                <th>Bet PL</th>
                                <th>Net PL</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    <div className='iconbtn-div'>
                                        <input value={transactionId} onChange={handleTransactionId} placeholder=" Search transaction id" />
                                        <IconButton
                                            onClick={onSearch}
                                            type="button"
                                            sx={{ p: '10px 0px' }}
                                            aria-label="search"
                                            className='icon-button'
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </div>
                                </th>
                                <th>
                                    <div className='iconbtn-div'>
                                        <input value={searchTransactionCode} onChange={handleTransactionCode} placeholder=" Search transaction code" />
                                        <IconButton
                                            onClick={onSearch}
                                            type="button"
                                            sx={{ p: '10px 0px' }}
                                            aria-label="search"
                                            className='icon-button'
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </div>
                                </th>
                                <th>
                                    <div className='iconbtn-div'>
                                        <input value={searchTransactionType} onChange={handleTransactionType} placeholder=" Search transaction type" />
                                        <IconButton
                                            onClick={onSearch}
                                            type="button"
                                            sx={{ p: '10px 0px' }}
                                            aria-label="search"
                                            className='icon-button'
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </div>
                                </th>
                                <th>
                                    <div className='iconbtn-div'>
                                        <input value={searchMarketId} onChange={handleMarketId} placeholder=" Search market id" />
                                        <IconButton
                                            onClick={onSearch}
                                            type="button"
                                            sx={{ p: '10px 0px' }}
                                            aria-label="search"
                                            className='icon-button'
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </div>
                                </th>
                                <th>
                                    <div className='iconbtn-div'>
                                        <input value={searchMarketName} onChange={handleMarketName} placeholder=" Search market name" />
                                        <IconButton
                                            onClick={onSearch}
                                            type="button"
                                            sx={{ p: '10px 0px' }}
                                            aria-label="search"
                                            className='icon-button'
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </div>
                                </th>
                                <th>
                                    <div className='iconbtn-div'>
                                        <input value={searchRunnerName} onChange={handleRunnerName} placeholder=" Search runner name" />
                                        <IconButton
                                            onClick={onSearch}
                                            type="button"
                                            sx={{ p: '10px 0px' }}
                                            aria-label="search"
                                            className='icon-button'
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </div>
                                </th>
                                <th></th>
                                <th>
                                    <div className='iconbtn-div'>
                                        <input value={searchRate} onChange={handleRate} placeholder=" Search rate" />
                                        <IconButton
                                            onClick={onSearch}
                                            type="button"
                                            sx={{ p: '10px 0px' }}
                                            aria-label="search"
                                            className='icon-button'
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </div>
                                </th>
                                <th>
                                    <div className='iconbtn-div'>
                                        <input value={searchStake} onChange={handleStake} placeholder=" Search stake" />
                                        <IconButton
                                            onClick={onSearch}
                                            type="button"
                                            sx={{ p: '10px 0px' }}
                                            aria-label="search"
                                            className='icon-button'
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </div>
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {historyData.map((item: any, index: number) => {
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{item.transactionId}</td>
                                        <td>{item.transactionCode}</td>
                                        <td>{item.transactionType}</td>
                                        <td>{item.marketId}</td>
                                        <td>
                                            <div className='market-name'>
                                                {item.marketName}
                                            </div>
                                        </td>
                                        <td>{item.runnerName}</td>
                                        <td>{item.gameName}</td>
                                        <td>{Math.round(item.rate)}</td>
                                        <td>{Math.round(item.stake)}</td>
                                        <td>{Math.round(item.run)}</td>
                                        <td>{item.betType}</td>
                                        <td>{item.betStatus}</td>
                                        <td>{item.siteCode}</td>
                                        <td>{item.currency}</td>
                                        <td>{item.description}</td>
                                        <td>{Math.round(item.commissionAmount)}</td>
                                        <td>{Math.round(item.betPL)}</td>
                                        <td>{Math.round(item.netpl)}</td>
                                        <td>{`${formatDate(item.createdOn)} ${formatedTime(item.createdOn)}`}</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 20 }}>
                        <Stack spacing={2}>
                            <Pagination
                                count={totalPages}
                                color="secondary"
                                page={currentPage}
                                onChange={(Event, New_Page) =>
                                    setCurrentPage(New_Page)
                                }
                            />
                        </Stack>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JetFairHistory