export enum Responsibilities {
	View_Dashboard = 'View_Dashboard',
	View_Users = 'View_Users',
	Register_New_User = 'Register_New_User',
	View_Payment_Gateways = 'View_Payment_Gateways',
	Add_Payment_Gateway = 'Add_Payment_Gateway',
	Toggle_Payment_Gateway = 'Toggle_Payment_Gateway',
	Delete_Payment_Gateway = 'Delete_Payment_Gateway',
	View_PayIn_Accounts = 'View_PayIn_Accounts',
	Add_PayIn_Account = 'Add_PayIn_Account',
	Toggle_PayIn_Account = 'Toggle_PayIn_Account',
	Delete_PayIn_Account = 'Delete_PayIn_Account',
	View_PayOut_Accounts = 'View_PayOut_Accounts',
	Add_PayOut_Account = 'Add_PayOut_Account',
	Toggle_PayOut_Account = 'Toggle_PayOut_Account',
	Delete_PayOut_Account = 'Delete_PayOut_Account',
	View_UPIs = 'View_UPIs',
	Add_UPI = 'Add_UPI',
	Toggle_UPI = 'Toggle_UPI',
	Delete_UPI = 'Delete_UPI',
	View_Banners = 'View_Banners',
	Add_Banner = 'Add_Banner',
	Toggle_Banner = 'Toggle_Banner',
	Delete_Banner = 'Delete_Banner',
	View_KYCs = 'View_KYCs',
	Add_KYC = 'Add_KYC',
	Approve_KYC = 'Approve_KYC',
	Upload_KYC_Cards = 'Upload_KYC_Cards',
	Reject_KYC = 'Reject_KYC',
	Delete_KYC = 'Delete_KYC',
	View_Deposits = 'View_Deposits',
	View_Withdrawals = 'View_Withdrawals',
	View_Games = 'View_Games',
	Add_Game = 'Add_Game',
	Toggle_Game = 'Toggle_Game',
	Delete_Game = 'Delete_Game',
	View_Feedback = 'View_Feedback',
	Compeleted_Feedback = 'Compeleted_Feedback',
	Edit_Feedback = 'Edit_Feedback',
	Delete_Feedback = 'Delete_Feedback',
	View_Profit_and_Loss = 'View_Profit_and_Loss',
	View_Roles_and_Responsibilities = 'View_Roles_and_Responsibilities',
	Add_Role = 'Add_Role',
	Edit_Role = 'Edit_Role',
	Delete_Role = 'Delete_Role',
	Fund_Request = 'Fund_Request',
	Fund_Request_Bonus_Wallet = 'Fund_Request_Bonus_Wallet',
	MobileApp = 'MobileApp',
	Chat_Support = 'Chat_Support',
	Admin_Chat_Support = 'Admin_Chat_Support',
	Non_Performing_User = "Non_Performing_User",
	Unique_Deposit_Pending_User = "Unique_Deposit_Pending_User",
	customer_allotment = "customer_allotment",
	my_customers = "my_customers"
}

export enum Button_Types {
	Icon = 'Icon',
	Image_Preview = 'Image_Preview',
	Image_Upload = 'Image_Upload',
	Regular = 'Regular'
}

export enum Column_Types {
	Button = 'Button',
	Combined = 'Combined',
	Input = 'Input',
	Switch = 'Switch',
	Text = 'Text'
}

export enum User_Types {
	User = 'User',
	Sub_Admin = 'Sub_Admin',
	Active_User = 'activeUser',
	Non_Performing_User = 'Non_Performing_User',
	In_Active_Deposit='In_Active_Deposit'
}