import { useContext, useEffect, useState } from "react";
import Loader from "../../../../Components/Loader/Loader";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import axios from "axios";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Stack,
  TextField,
} from "@mui/material";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "../../../../Css/users.css";
import "./Feedback.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { API_Endpoint } from "../../../../Configuration/Settings";
import { Responsibilities } from "../../../../Configuration/Enums";
import { User_Context } from "../../../../Contexts/User";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import Stateful_Select from "../../../../Components/Dropdown/Dropdown";
import { dateTime, formatedTime } from "../../../../utils/utility";

const topSpace = { marginTop: "1.5rem" };

type addFeedback = {
  name: string;
  mobile: string;
  message: string;
};
const PENDING_STATUS = "Pending";
const Feedback = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [userFeedback, setUserFeedback] = useState<string>("");
  const [adminUpdated,setAdminUpdated] = useState<object>({});
  const [userId, setUserId] = useState<string>("");
  const { User } = useContext(User_Context);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<addFeedback>();
  const addFeedback: SubmitHandler<addFeedback> = (payload: addFeedback) => {
    const add = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          const url = `${API_Endpoint}/feedBack`;
          const response = await axios.post(url, { token: encryptData(payload) }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          response.data.data = decryptData(response.data.data);
          response.data.data = response.data.data.payload;
          const { success, data: feedbackAdded } = response.data;
          if (success) {
            setShowAddModal(false);
            setData([...data, feedbackAdded]);
          }
        }
      } catch (error) {
        console.error("Error while adding feedback", error);
      }
    };
    add();
  };

  const filterTransaction = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const feedbackList = async () => {
        try {
          let payload: any = {}
          if (startDate && endDate){
            payload = { pageNo: currentPage, itemsPerPage: itemsPerPage, startDate: dateTime(startDate), endDate: dateTime(endDate) }
          } else {
            payload = { pageNo: currentPage, itemsPerPage: itemsPerPage } 
          }
          let  filter: any = {
            feedBackStatus: PENDING_STATUS
          }
           payload.filter = filter;
          const url = `${API_Endpoint}/feedBack/getAll`;
          const config = {
            method: "post",
            url: url,
            data: { token: encryptData(payload) },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await axios(config);
        
          response.data.data = decryptData(response.data.data);
          response.data.data = response.data.data.payload;
          const {
            data: { items: feedbackList, totalPages },
            success,
          } = response.data;
          if (success) {
            setLoading(false);
            setData(feedbackList);
            setTotalPages(totalPages);
          }
        } catch (error) {
          console.error("fetch feedback list", error);
        }
      };
      feedbackList();
    }
  }

  const clearDate = () => {
    setStartDate('')
    setEndDate('')
  }

  useEffect(() => {
    filterTransaction()
  }, [currentPage, itemsPerPage]);

  const handlePerPage = (newValue: any) => {
    setItemsPerPage(newValue)
  }

  const editFeedback = (id: string, feedbackResponse: string, ) => {
    setShowEditModal(true);
    setUserId(id);
    setUserFeedback(feedbackResponse);
    
  };

  const deleteFeedback = () => {
    const remove = async () => {
      try {
        const url = `${API_Endpoint}/feedBack/getById`;
        const token = localStorage.getItem("token");
        const response = await axios.post(url, { token: encryptData({ id: userId }) },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        const { success } = response.data;
        if (success) {
          setShowDeleteModal(false);
          const filterData = data.filter((obj: any) => obj._id !== userId);
          setData(filterData);
        }
      } catch (error) {
        console.error("Feedback Delete", error);
      }
    };
    remove();
  };

  const updateFeedbackMessage = (e: any) => {
    e.preventDefault();
    setLoading(true);
    const update = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          const url = `${API_Endpoint}/feedBack/updateById`;
          const payload = { token: encryptData({ _id: userId, feedbackResponse: userFeedback, updatedBy: {
            name: User.data.name,
            _id: User.data._id,
          }, }) };
          const response = await axios.post(url, payload, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          response.data.data = decryptData(response.data.data);
          response.data.data = response.data.data.payload;
          const { data: updatedObject, success } = response.data;
          if (success) {
            setLoading(false);
            setShowEditModal(false);
            const updatedResponse = data.map((obj: any) => {
              if (obj._id === updatedObject._id) {
                return {
                  ...obj,
                  feedbackResponse: updatedObject.feedbackResponse,
                };
              } else {
                return obj;
              }
            });
            setData(updatedResponse);
            setUserId("");
            filterTransaction()
          }
        }
      } catch (error) {
        console.error("Feedback Message Update Error:", error);
      }
    };
    update();
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="g-sidenav-show  bg-gray-100">
          <Sidenav />
          <main className="main-content position-relative">
            <div style={{ background: "#f8f9fa" }}>
              <Breadcrumbs tab={"Pending Feedback List"} />
              {/* Add Feedback Modal Popup */}
              {/* <Dialog
                open={showAddModal}
                onClose={() => setShowAddModal(false)}
                fullWidth
              >
                <DialogTitle>Add Feedback</DialogTitle>
                <DialogContent>
                  <form onSubmit={handleSubmit(addFeedback)}>
                    <div>
                      <TextField
                        type="text"
                        label="User Name"
                        fullWidth
                        sx={topSpace}
                        variant="outlined"
                        size="small"
                        {...register("name", { required: true })}
                      />
                      {errors.name && (
                        <span className="form-error">
                          {"User Name is required."}
                        </span>
                      )}
                    </div>

                    <div>
                      <TextField
                        type="tel"
                        label="Mobile Number"
                        fullWidth
                        sx={topSpace}
                        variant="outlined"
                        size="small"
                        {...register("mobile", {
                          required: true,
                          minLength: 10,
                          maxLength: 10,
                          pattern: /^[789\d{9}$]/,
                        })}
                      />
                      {errors.mobile && (
                        <span className="form-error">
                          {"Enter Valid Mobile Number"}
                        </span>
                      )}
                    </div>
                    <div>
                      <TextField
                        type="text"
                        label="Message / Feedback"
                        fullWidth
                        sx={topSpace}
                        variant="outlined"
                        size="small"
                        {...register("message", { required: true })}
                      />
                      {errors.message && (
                        <span className="form-error">
                          {"Message is Required"}
                        </span>
                      )}
                    </div>
                    <div style={topSpace}>
                      <Button
                        variant="contained"
                        className="btn-popup"
                        style={{ marginRight: "1rem" }}
                        onClick={() => setShowAddModal(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        className="btn-popup"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                </DialogContent>
              </Dialog> */}
              {/* Delete Feedback Modal Popup */}
              <Dialog
                open={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
              >
                <DialogTitle>Do you want to Delete!</DialogTitle>
                <DialogContent
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Button
                    variant="contained"
                    className="btn-popup"
                    color="primary"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="btn-popup"
                    color="primary"
                    onClick={deleteFeedback}
                  >
                    Delete
                  </Button>
                </DialogContent>
              </Dialog>
              {/* Edit Feedback Modal Popup */}
              <div>
                <Dialog
                  open={showEditModal}
                  onClose={() => setShowEditModal(false)}
                  fullWidth
                >
                  <DialogContent>
                    <form onSubmit={updateFeedbackMessage}>
                      <div>
                        <TextField
                          onChange={(e) => setUserFeedback(e.target.value)}
                          value={userFeedback}
                          id="outlined-basic"
                          type="text"
                          fullWidth
                          label="Feedback Message"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            style: {
                              fontFamily: "Roboto",
                              fontSize: "14px",
                            },
                          }}
                        />
                      </div>

                      <DialogActions>
                        <Button
                          className="btn-popup"
                          variant="outlined"
                          color="primary"
                          onClick={() => setShowEditModal(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn-popup"
                          variant="outlined"
                          type="submit"
                          color="primary"
                        >
                          Update
                        </Button>
                      </DialogActions>
                    </form>
                  </DialogContent>
                </Dialog>
              </div>
              {/* Edit form end */}

              <div className="container-fluid">
                <div className="row">
                  <div className="col-6 col-xl-2 col-sm-4 pdrt">
                    <label className="lbl">From Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="From Date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="col-6 col-xl-2 col-sm-4 pdrt">
                    <label className="lbl">To Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="To Date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className="col-6 col-xl-2 col-sm-4 pdrt mt-1">
                    <label className="lbl"></label>
                    <a onClick={filterTransaction} className="sechBtn mt-1">
                      Apply
                    </a>
                  </div>
                  <div className="col-6 col-xl-2 col-sm-4 pdrt mt-1">
                      <label className="lbl"></label>
                      <a onClick={clearDate} className="sechBtn mt-1">
                        Clear Dates
                      </a>
                    </div>
                  <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-1">
                    <label>Items Per Page</label>
                    <Stateful_Select
                      // label="Items Per Page"
                      value={itemsPerPage.toString()}
                      onChange={(newValue: any) => handlePerPage(newValue)}
                      options={["10", "25", "50", "75", "100"]}
                    />
                  </div>
                  <div className="col-12 mt-2">
                    <div className="table-responsive">
                      <table className="table table-view">
                        <thead>
                          <tr>
                            <th></th>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>Message</th>
                            <th>Reply</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data.map((item: any, index:number) => {
                            return (
                              <tr key={item._id}>
                                <td>{index + 1 + (currentPage && itemsPerPage  ? (currentPage - 1)  * itemsPerPage : 0)}</td>
                                <td>{item._id}</td>
                                <td>{item.name.slice(0, 15)}</td>
                                <td>{item.mobile}</td>
                                <td className="message-cell">{item.message}</td>
                                <td>{item.feedbackResponse}</td>
                                <td>{`${dateTime(item.createdOn)} ${formatedTime(item.createdOn)}`}</td>
                                <td>
                                  {User.data.Responsibilities.includes(Responsibilities.Edit_Feedback) && <span>
                                    <Button
                                      variant="contained"
                                      onClick={() =>
                                        editFeedback(item._id, item.feedbackResponse)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      Respond
                                    </Button>
                                  </span>}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <ul className="pagination d-flex justify-content-center">
                      <Stack spacing={2}>
                        <Pagination
                          count={totalPages}
                          color="secondary"
                          page={currentPage}
                          onChange={(event, newPage) => setCurrentPage(newPage)}
                        />
                      </Stack>
                    </ul>
                  </div>
                </div>
                <footer className="footer">
                  <div className="container-fluid">
                    <div className="row align-items-center justify-content-lg-between">
                      <div className="col-lg-6 mb-lg-0  ">
                        <div className="copyright text-center text-sm text-muted text-lg-start">
                          © fairbets.co
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              About Us
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Blog
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link pe-0 text-muted"
                              target="_blank"
                            >
                              License
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
};

export default Feedback;