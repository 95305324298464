import React from "react";

// Styles
import './TableFilters.css'

function TableFilterDatePicker({ onChange,classname }: TableFilterDatePickerProps) {
    return (
        <input
            type="date"
            className={classname ? classname : "table-filters-date-box"}
            onChange={(e) => onChange(e)}
        />
    )
}

export default TableFilterDatePicker;

interface TableFilterDatePickerProps {
    onChange: any
    classname?: any
}