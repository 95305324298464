import { ChangeEvent, useEffect, useState, useContext } from "react";
import Loader from "../../../../Components/Loader/Loader";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import axios from "axios";
import { API_Endpoint } from "../../../../Configuration/Settings";
import { toast } from "react-toastify";
import { User_Context } from "../../../../Contexts/User";
import {
    Button,
    TextField,
} from "@mui/material";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "../../../../Css/users.css";
import "./MobileApp.css"
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import { encryptData } from "../../../../utils/encryptData";
import { decryptData } from "../../../../utils/decryptData";
import { dateTime, formatedTime } from "../../../../utils/utility";

const MobileApp = () => {
    const { User } = useContext(User_Context);
    const [loading, setLoading] = useState<boolean>(false);

    const [versionNumber, setVersionNumber] = useState<string>('');
    const [appPath, setAppPath] = useState<string>('');

    const [appFile, setAppFile] = useState<any>({});
    const [appFileName, setAppFileName] = useState<string>('');

    const [storedVersions, setStoredVersions] = useState<any>({});

    const [selectedFileError, setSelectedFileError] = useState<boolean>(false);
    const [selectedFileHelperText, setSelectedFileLinkHelperText] = useState<string>("");

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const reader = new FileReader();
            const File_Object = event.target.files[0];
            reader.readAsDataURL(File_Object);
            reader.onload = () => {
                setAppFile(File_Object)
                setAppFileName(reader.result?.toString() as string)
            };
        }
    };

    const onSubmitButton = async () => {
        setLoading(true)
        if (versionNumber === "") {
            setLoading(false)
            toast('Version Number cannot be blank');
            return;
        }

        if (!appFileName) {
            setLoading(false)
            setSelectedFileError(true);
            setSelectedFileLinkHelperText("Choose file");
            return;
        }
        const formData = new FormData();
        formData.append('version', versionNumber);
        formData.append('apkFile', appFile);
        axios.post(`${API_Endpoint}/app/uploadApk`, formData, {
            headers: {
                Authorization: `Bearer ${User.token}`,
                "Content-Type": "multipart/form-data"
            },
        })
            .then((response) => {
                if (response.data.success) {
                    toast('Successfully Added!');
                    window.location.reload();
                    setLoading(false);
                }
            })
            .catch((err: any) => {
                //   setOpenDialogForImage(false);
                setLoading(false);
                console.log(`${err.message}`);
            });

    }

    const getAppVersions = async () => {
        setLoading(true)

        try {
            const token = localStorage.getItem("token");
            const url = `${API_Endpoint}/app/getAll`;
            const config = {
                method: "post",
                url: url,
                data: { token: encryptData({}) },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await axios(config);
            const { success, data } = response.data;
            if (success) {
                const appData = decryptData(data);
                setStoredVersions(appData.payload);
                setLoading(false);
            } else {
                setLoading(false);
                toast('Error Occured!');
            }
        } catch {
            setLoading(false);
            toast('Error Occured!');
        }
    }

    useEffect(() => {
        getAppVersions();
    }, []);

    useEffect(() => {
        console.log(loading)
    }, [loading]);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className="g-sidenav-show  bg-gray-100">
                    <Sidenav />
                    <main className="main-content position-relative">
                        <div style={{ background: "#f8f9fa" }}>
                            <Breadcrumbs tab={"Mobile App"} />
                        </div>

                        <div className="mobile-app-textfield-container">
                            <form onSubmit={onSubmitButton} encType="multipart/form-data">
                                <div className="mobile-app-textfield-single-container">

                                    <TextField
                                        onChange={handleFileChange}
                                        error={selectedFileError}
                                        helperText={selectedFileHelperText}
                                        // value={selectedFile}
                                        id="outlined-basic"
                                        type="file"
                                        // label="Path to App"
                                        size="small"
                                        InputLabelProps={{
                                            style: {
                                                fontFamily: "Roboto",
                                                fontSize: "14px",
                                            },
                                        }}
                                    />


                                </div>

                                <div className="mobile-app-textfield-single-container">
                                    <TextField
                                        onChange={(e) => setVersionNumber(e.target.value)}
                                        value={versionNumber}
                                        id="outlined-basic"
                                        type="text"
                                        fullWidth
                                        label="Version Number"
                                        variant="outlined"
                                        size="small"
                                        InputLabelProps={{
                                            style: {
                                                fontFamily: "Roboto",
                                                fontSize: "14px",
                                            },
                                        }}
                                    />
                                </div>

                            </form>
                            <Button
                                className="btn-withdraw"
                                variant="contained"
                                color="primary"
                                onClick={onSubmitButton}
                            >
                                Submit
                            </Button>
                        </div>


                        <div className="col-12 mt-2">
                            <div className="table-responsive">
                                <table className="table table-view">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>App URL</th>
                                            <th>Version Number</th>
                                            <th>Created Date</th>
                                            <th>Updated Date</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {Object.keys(storedVersions).length > 0 &&

                                            <tr>
                                                <td>{storedVersions._id}</td>
                                                <td>{storedVersions.path}</td>
                                                <td>{storedVersions.version}</td>
                                                <td>{`${dateTime(storedVersions.createdOn)} ${formatedTime(storedVersions.createdOn)}`}</td>
                                                <td>{`${dateTime(storedVersions.updatedOn)} ${formatedTime(storedVersions.updatedOn)}`}</td>
                                            </tr>

                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </>
    );
};

export default MobileApp;