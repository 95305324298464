import React, { useState, useEffect, useContext, ChangeEvent } from 'react'
import Sidenav from '../SideNavigation/SideNavigation'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import { API_Endpoint } from '../../Configuration/Settings'
import { encryptData } from '../../utils/encryptData'
import { User_Context } from '../../Contexts/User'
import axios from 'axios'
import { decryptData } from '../../utils/decryptData'
import { useLocation } from 'react-router-dom';
import { formatedTime } from '../../utils/utility'
import { Pagination, Stack } from "@mui/material";
import { formatDate } from '../../utils/utility'
import Stateful_Select from '../Dropdown/Dropdown'
import Loader from '../Loader/Loader'
import { ToastContainer, toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import "react-toastify/dist/ReactToastify.css";
import ExcelExport from '../../Excel/ExcelExport'


interface Deposit {
    _id: string,
    name: string,
    mobile: string,
    state: string,
    city: string,
    balance: number,
    createdOn: string,
    updatedOn: string,
    totalAmount: number
}
function UserDepositCoinTable() {
    const { User } = useContext(User_Context)
    const location = useLocation();
    const customProps = location.state;
    const [depositData, setDepositData] = useState<Deposit[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState<string>("20");
    const [loading, setLoading] = useState(false)
    const [searchMobile, setSearchMobile] = useState<string>("");
    const [searchState, setSearchState] = useState<string>("");
    const [searchCity, setSearchCity] = useState<string>("");

    const formatedDate = (date: any) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();

        return `${day}-${month}-${year}`;
    };
    const formattedDate = (timestamp: any) => formatedDate(new Date(timestamp));

    const handlePerPage = (newValue: any) => {
        setItemsPerPage(newValue)
    }

    const handleSearchMobile = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchMobile(event.target.value);
    };

    const handleSearchState = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchState(event.target.value);
    };

    const handleSearchCity = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchCity(event.target.value);
    };

    useEffect(() => {
        setLoading(true)
        let url
        if (customProps.type === "nonperform") {
            url = `${API_Endpoint}/User/nonPerformingUser`
        } else {
            url = `${API_Endpoint}/User/depositUser`
        }
        let data = customProps
        data.pageNo = currentPage
        data.itemPerPage = parseInt(itemsPerPage)
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: url,
            headers: {
                Authorization: `Bearer ${User.token}`,
            },
            data: { token: encryptData(data) },
        };
        axios
            .request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data)
                setTotalPages(response.data.data.payload.totalPages)
                setDepositData(response.data.data.payload.items)
                setLoading(false)
            })
            .catch((error: any) => {
                console.log(error.message);
                toast.error(error.response.data.message)
                setLoading(false)
            });
    }, [currentPage, itemsPerPage])

    const onSearch = () => {
        setLoading(true)
        let data: any = {}
        let filter: { state?: string, mobile?: string, city?: string } = {};
        if (searchMobile) {
            filter.mobile = searchMobile
        }
        if (searchState) {
            filter.state = searchState
        }
        if (searchCity) {
            filter.city = searchCity
        }
        if (customProps.startDate && customProps.endDate) {
            data = {
                pageNo: currentPage,
                itemPerPage: parseInt(itemsPerPage),
                startDate: customProps.startDate,
                endDate: customProps.endDate,
                filter: filter
            };
        }
        else {
            data = {
                pageNo: currentPage,
                itemPerPage: parseInt(itemsPerPage),
                filter: filter
            };
        }
        let url
        if (customProps.type === "nonperform") {
            url = `${API_Endpoint}/User/nonPerformingUser`
        } else {
            data.type = customProps.type
            url = `${API_Endpoint}/User/depositUser`
        }
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: url,
            headers: {
                Authorization: `Bearer ${User.token}`,
            },
            data: { token: encryptData(data) },
        };
        axios
            .request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data)
                setTotalPages(response.data.data.payload.totalPages)
                setDepositData(response.data.data.payload.items)
                setLoading(false)
            })
            .catch((error: any) => {
                console.log(error.message);
                setLoading(false)
            });
    }

    useEffect(() => {
        onSearch()
    }, [currentPage, itemsPerPage]);

    // excel sheet heading start
    const headings = [
		[

		           "_Id",
		           "Name",
		           "Mobile No.",
		           "Email Id",
		           "State",
		           "Bonus Wallet Bal",
		           "City",
		           "Amount",
		           "",
		           "",
		           "Total Deposit Amount",
                   "Date",
		           "Time",
		           
		       ],
	];
    // excel sheet heading code end
    const transformedData = depositData.map((item) => {
		const date = new Date(item.createdOn);
		const formattedDate = date.toLocaleDateString(); 
		const formattedTime = date.toLocaleTimeString();
	          
		return {
		  ...item,
		  createdDate: formattedDate,
		  createdTime: formattedTime,
		};
	          });
    return (
        <>
            {loading && (<Loader />)}
            <div className="g-sidenav-show  bg-gray-100">
                <ToastContainer autoClose={2000} position="top-center" />
                <Sidenav />
                <main className="main-content position-relative">
                    <div style={{ background: "#f8f9fa" }}>
                        <Breadcrumbs
                            tab={
                                customProps.type === "old"
                                    ? "Old Deposits Approved"
                                    : customProps.type === "new"
                                        ? "New Deposits Approved"
                                        : "Non Performing User Deposit Approved Amt"
                            }
                        />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="row tp-form mb-2">
                                    <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                        <label className="lbl">Items Per Page</label>
                                        <div className="mt-1">
                                            <Stateful_Select
                                                value={itemsPerPage}
                                                onChange={(newValue: any) => handlePerPage(newValue)}
                                                options={["10", "25", "50", "75", "100","10000"]}
                                                className="deposit-select"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                        <div className="mt-4">
                                        <div className="excel_sheet exdd widr" > 
												<ExcelExport
												inputData={transformedData}
												headings={headings}
												fileName={"fund_request_Data"}
												/>
											     Download Data
										</div>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div className="col-12 mt-2">
                                    <div className="table-responsive">
                                        <table className="table table-view">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>User Name</th>
                                                    <th>Mobile No</th>
                                                    <th>Amount</th>
                                                    <th>Deposit Amount</th>
                                                    <th>State</th>
                                                    <th>City</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th className='thdr'></th>
                                                    <th className='thdr'></th>
                                                    <th className='thdr'>
                                                        <div>
                                                            <input value={searchMobile} onChange={handleSearchMobile} placeholder=" Search user mobile" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th className='thdr'></th>
                                                    <th className='thdr'></th>
                                                    <th className='thdr'>
                                                        <div>
                                                            <input value={searchState} onChange={handleSearchState} placeholder=" Search user state" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th className='thdr'>
                                                        <div>
                                                            <input value={searchCity} onChange={handleSearchCity} placeholder=" Search user city" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th className='thdr'></th>
                                                    <th className='thdr'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {depositData.map((item, index) => (
                                                    <tr id={item._id}>
                                                        <td>{index + 1 + (currentPage && itemsPerPage ? (currentPage - 1) * parseInt(itemsPerPage) : 0)}</td>
                                                        <td onClick={() => {
                                                            const url = `/user-report/${item._id}/${item.name}`;
                                                            window.open(url, "_blank");
                                                        }} style={{ cursor: 'pointer' }}>{item.name}</td>
                                                        <td>{item.mobile}</td>
                                                        <td>{Math.round(item.balance)}</td>
                                                        <td>{Math.round(item.totalAmount)}</td>
                                                        <td>{item.state}</td>
                                                        <td>{item.city}</td>
                                                        <td>{formattedDate(item.createdOn)}</td>
                                                        <td>{formatedTime(item.createdOn)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <ul className="pagination d-flex justify-content-center">
                                        <Stack spacing={2}>
                                            <Pagination
                                                count={totalPages}
                                                color="secondary"
                                                page={currentPage}
                                                onChange={(Event, New_Page) =>
                                                    setCurrentPage(New_Page)
                                                }
                                            />
                                        </Stack>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>

    )
}

export default UserDepositCoinTable