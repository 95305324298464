import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";

type PropsType = {
  label?: string;
  className?: string;
  value: string;
  onChange: (newValue: string) => void;
  options: string[];
};

const Stateful_Select = ({ label, value, onChange, options, className }: PropsType): JSX.Element => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as string;
    onChange(selectedValue);
  };

  return (
    <FormControl variant="outlined" fullWidth size="small" className={className}>
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={handleChange} >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Stateful_Select;
