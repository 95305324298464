import React, { useState, useEffect, useContext, ChangeEvent } from 'react'
import Sidenav from '../SideNavigation/SideNavigation'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import { API_Endpoint } from '../../Configuration/Settings'
import { encryptData } from '../../utils/encryptData'
import { User_Context } from '../../Contexts/User'
import axios from 'axios'
import { decryptData } from '../../utils/decryptData'
import { useLocation } from 'react-router-dom';
import { formatedTime } from '../../utils/utility'
import { formatDate } from '../../utils/utility'
import { Pagination, Stack } from "@mui/material";
import Stateful_Select from '../Dropdown/Dropdown'
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import "./NonPerforming.css"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from '../Loader/Loader'



interface Pldata {
    _id: string;
    name: string;
    balance: number;
    createdOn: string;
    email: string;
    mobile: string;
    updatedOn: string;
    state: string;
    city: string,
    totalAmount: number

}



function NonPerformingUser() {
    const { User } = useContext(User_Context)
    const location = useLocation();
    const customProps = location.state;
    const [performingUser, setNonPerformingUser] = useState<Pldata[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState<string>("10");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [totalUser, setTotalUser] = useState<number>(0)
    const [searchName, setSearchName] = useState<string>("");
    const [searchMobile, setSearchMobile] = useState<string>("");
    const [searchState, setSearchState] = useState<string>("");
    const [searchCity, setSearchCity] = useState<string>("");
    const [loading, setLoading] = useState(false)

    const handlePerPage = (newValue: any) => {
        setItemsPerPage(newValue)
    }



    const getData = () => {
        setLoading(true)
        let data;
        let filter = {}
        if (startDate && endDate) {
            data = {
                pageNo: currentPage,
                itemPerPage: parseInt(itemsPerPage),
                startDate,
                endDate,
                filter: filter
            };
        }
        else {
            data = {
                pageNo: currentPage,
                itemPerPage: parseInt(itemsPerPage),
                filter: filter
            };
        }

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/User/nonPerformingUser`,
            headers: {
                Authorization: `Bearer ${User.token}`,
            },
            data: { token: encryptData(data) },
        };
        axios
            .request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data)
                setTotalPages(response.data.data.payload.totalPages)
                setNonPerformingUser(response.data.data.payload.items)
                console.log(response.data.data.payload.items)
                setTotalUser(response.data.data.payload.total)
                setLoading(false)
            })
            .catch((error: any) => {
                console.log(error.message);
                toast.error(error.response.data.message)
                setLoading(false)
            });
    }

    const clearDates = () => {
        setStartDate('')
        setEndDate('')
    }

    const today = new Date();
    const oneWeekBefore = new Date(today);
    oneWeekBefore.setDate(today.getDate() - 7);

    const handleSearchName = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchName(event.target.value);
    };

    const handleSearchMobile = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchMobile(event.target.value);
    };

    const handleSearchState = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchState(event.target.value);
    };

    const handleSearchCity = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchCity(event.target.value);
    };

    const onSearch = () => {
        setLoading(true)
        let data = {}
        let filter: { state?: string, mobile?: string, name?: string, city?: string } = {};
        if (searchName) {
            filter.name = searchName
        }
        if (searchMobile) {
            filter.mobile = searchMobile
        }
        if (searchState) {
            filter.state = searchState
        }
        if (searchCity) {
            filter.city = searchCity
        }
        if (startDate && endDate) {
            data = {
                pageNo: currentPage,
                itemPerPage: parseInt(itemsPerPage),
                startDate,
                endDate,
                filter: filter
            };
        }
        else {
            data = {
                pageNo: currentPage,
                itemPerPage: parseInt(itemsPerPage),
                filter: filter
            };
        }
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/User/nonPerformingUser`,
            headers: {
                Authorization: `Bearer ${User.token}`,
            },
            data: { token: encryptData(data) },
        };
        axios
            .request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data)
                setTotalPages(response.data.data.payload.totalPages)
                setNonPerformingUser(response.data.data.payload.items)
                setTotalUser(response.data.data.payload.total)
                setLoading(false)
            })
            .catch((error: any) => {
                console.log(error.message);
                toast.error(error.response.data.message)
                setLoading(false)
            });
    }

    useEffect(() => {
        getData()
        onSearch()
    }, [currentPage, itemsPerPage]);

    return (
        <>
        {loading && (<Loader />)}
            <div className="g-sidenav-show  bg-gray-100">
                <Sidenav />
                <main className="main-content position-relative">
                    <div style={{ background: "#f8f9fa" }}>
                        <Breadcrumbs tab={"User Data"} />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="row tp-form mb-1" style={{ display: 'flex' }}>
                                    <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                        <label className="lbl">From Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            placeholder="From Date"
                                            value={startDate}
                                            max={oneWeekBefore.toISOString().split('T')[0]}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                        <label className="lbl">To Date</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            placeholder="To Date"
                                            value={endDate}
                                            max={oneWeekBefore.toISOString().split('T')[0]}
                                            onChange={(e) => setEndDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                        <label className="lbl"></label>
                                        <a onClick={getData} className="sechBtn mt-1">
                                            Apply
                                        </a>
                                    </div>
                                    <div className="col-6 col-xl-2 col-sm-4 pdrt">
                                        <label className="lbl"></label>
                                        <a onClick={clearDates} className="sechBtn mt-1">
                                            clear dates
                                        </a>
                                    </div>

                                    <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-1">
                                        <label className="lbl">Items Per Page</label>
                                        <Stateful_Select
                                            // label="Items Per Page"
                                            value={itemsPerPage.toString()}
                                            onChange={(newValue: any) => handlePerPage(newValue)}
                                            options={["10", "25", "50", "75", "100"]}
                                        />
                                    </div>
                                </div>
                                <div className="align-items-center mt-2 mb-2">
                                    <label className="lbl"></label>
                                    <b>Total user count : {totalUser}</b>
                                </div>

                            </div>

                            <div className="row">
                                <div className="row tp-form">
                                    {/* <div className="col-6 col-xl-2 col-sm-4 pdrt mb-2">
                                    <Stateful_Select
                                        label="Items Per Page"
                                        value={itemsPerPage.toString()}
                                        onChange={(newValue: any) => handlePerPage(newValue)}
                                        options={["10", "25", "50", "75", "100"]}
                                    />
                                </div> */}
                                </div>
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table table-view">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>User Name</th>
                                                    <th>Email</th>
                                                    <th>Mobile No</th>
                                                    <th>Balance</th>
                                                    <th>Deposit Amount</th>
                                                    <th>State</th>
                                                    <th>City</th>
                                                    <th>Created</th>
                                                    <th>last Activity</th>

                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th className='thdr'></th>
                                                    <th className='thdr'>
                                                        <div>
                                                            <input value={searchName} onChange={handleSearchName} placeholder=" Search user name" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button1'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th className='thdr'></th>
                                                    <th className='thdr'>
                                                        <div>
                                                            <input value={searchMobile} onChange={handleSearchMobile} placeholder=" Search user mobile" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button1'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th className='thdr'></th>
                                                    <th className='thdr'></th>
                                                    <th className='thdr'>
                                                        <div>
                                                            <input value={searchState} onChange={handleSearchState} placeholder=" Search user state" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button1'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th className='thdr'>
                                                        <div>
                                                            <input value={searchCity} onChange={handleSearchCity} placeholder=" Search user city" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button1'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th className='thdr'></th>
                                                    <th className='thdr'></th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {performingUser?.map((item, index) => (
                                                    <tr id={item._id}>
                                                        <td>{index + 1 + (currentPage && itemsPerPage ? (currentPage - 1) * parseInt(itemsPerPage) : 0)}</td>
                                                        <td
                                                            onClick={() => {
                                                                const url = `/user-report/${item._id}/${item.name}`;
                                                                window.open(url, "_blank");
                                                            }} style={{ cursor: 'pointer' }}
                                                        >{item?.name}</td>
                                                        <td>{item?.email}</td>
                                                        <td>{item?.mobile}</td>
                                                        <td>{Math.round(item.balance)}</td>
                                                        <td>{Math.round(item.totalAmount ?? 0)}</td>
                                                        <td>{item.state}</td>
                                                        <td>{item.city}</td>
                                                        {/* <td>{item.utr}</td>
                                                    <td>{item.reason}</td>
                                                    <td>{item.remark}</td>
                                                    <td>{item.tag === "credit" ? "Deposit" : "Debit"}</td>
                                                    <td>{item.updatedBy.name}</td> */}
                                                        <td>{item.createdOn ? `${formatDate(item.createdOn)} ${formatedTime(item.createdOn)}` : ""}</td>
                                                        <td>{item.updatedOn ? `${formatDate(item.updatedOn)} ${formatedTime(item.updatedOn)}` : ""}</td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                    <ul className="pagination d-flex justify-content-center">
                                        <Stack spacing={2}>
                                            <Pagination
                                                count={totalPages}
                                                color="secondary"
                                                page={currentPage}
                                                onChange={(Event, New_Page) =>
                                                    setCurrentPage(New_Page)
                                                }
                                            />
                                        </Stack>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default NonPerformingUser