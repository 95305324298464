import React, { useState, useEffect, useContext } from 'react'
import Sidenav from '../SideNavigation/SideNavigation'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import { API_Endpoint } from '../../Configuration/Settings'
import { encryptData } from '../../utils/encryptData'
import { User_Context } from '../../Contexts/User'
import axios from 'axios'
import { decryptData } from '../../utils/decryptData'
import { useLocation } from 'react-router-dom';
import { formatedTime } from '../../utils/utility'
import { formatDate } from '../../utils/utility'
import { Pagination, Stack } from "@mui/material";
import Stateful_Select from '../Dropdown/Dropdown'
import Loader from "../../Components/Loader/Loader";



interface Pldata {
    _id:string;
    userId:string;
    userName:string;
    userMobile:string;
    orderId:string;
    amount:string;
    paymentGatewayName:string;
    mid:string;
    status:string;
    createdOn:string;

}



function UniquePendingDepositUser() {
    const { User } = useContext(User_Context)
    const location = useLocation();
    const customProps = location.state;
    const [loading, setLoading] = useState<boolean>(true);
    const [uniquePending, setUniquePending] = useState<Pldata[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState<string>("10");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [totalUser, setTotalUser] = useState<number>(0)
    const formatedDate = (date: any) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();

        return `${day}-${month}-${year}`;
    };
    const formattedDate = (timestamp: any) => formatedDate(new Date(timestamp));
    const handlePerPage = (newValue: any) => {
        setItemsPerPage(newValue)
    }

    const getData = () => {

        let data;
        if (startDate && endDate) {
            data = {
                pageNo: currentPage,
                itemsPerPage: parseInt(itemsPerPage),
                type:"pending",
                filter:{},
                startDate,
                endDate
            };
        }
        else {
            data = {
                pageNo: currentPage,
                itemsPerPage: parseInt(itemsPerPage),
                type:"pending",
                filter:{}
                // filters: filters
            };
        }

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/transaction/getAllTransaction`,
            headers: {
                Authorization: `Bearer ${User.token}`,
            },
            data: { token: encryptData(data) },
        };
        axios
            .request(config)
            .then((response) => {
                setLoading(false);
                response.data.data = decryptData(response.data.data)
                setTotalPages(response.data.data.payload.totalPages)
                setUniquePending(response.data.data.payload.items)
                setTotalUser(response.data.data.payload.total)
            })
            .catch((error: any) => {
                setLoading(false);
                console.log(error.message);
            });
    }

    useEffect(() => {
        getData()
    }, [currentPage, itemsPerPage]);

    const clearDates = () => {
        setStartDate('')
        setEndDate('')
    }

    const today = new Date();
    const oneWeekBefore = new Date(today);
    oneWeekBefore.setDate(today.getDate() - 7);

    return (
    <>
     
        <div>
        <div className="g-sidenav-show  bg-gray-100">
        <Sidenav />
        <main className="main-content position-relative">
            <div style={{ background: "#f8f9fa" }}>
                <Breadcrumbs tab={"Unique Deposit Pending User Data"} />
                <div className="container-fluid">
                    <div className="row">
                    <div className="row tp-form mb-1" style={{ display: 'flex' }}>
                        <div className="col-6 col-xl-2 col-sm-4 pdrt">
                            <label className="lbl">From Date</label>
                            <input
                                type="date"
                                className="form-control"
                                placeholder="From Date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                        <div className="col-6 col-xl-2 col-sm-4 pdrt">
                            <label className="lbl">To Date</label>
                            <input
                                type="date"
                                className="form-control"
                                placeholder="To Date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                        <div className="col-6 col-xl-2 col-sm-4 pdrt">
                            <label className="lbl"></label>
                            <a onClick={getData} className="sechBtn mt-1">
                                Apply
                            </a>
                        </div>
                        <div className="col-6 col-xl-2 col-sm-4 pdrt">
                            <label className="lbl"></label>
                            <a onClick={clearDates} className="sechBtn mt-1">
                                clear dates
                            </a>
                        </div>

                        <div className="col-6 col-xl-2 col-sm-4 pdrt btn-top role-mob role-web mt-1">
                            <label className="lbl">Items Per Page</label>
                            <Stateful_Select
                                // label="Items Per Page"
                                value={itemsPerPage.toString()}
                                onChange={(newValue: any) => handlePerPage(newValue)}
                                options={["10", "25", "50", "75", "100"]}
                            />
                        </div>
                    </div>
                    <div className="align-items-center mt-2 mb-2">
                    <label className="lbl"></label>
                    <b>Total user count : {totalUser}</b>
                </div>
                    
                    </div>
              
                {loading ? (
                    <Loader/>
                     ):(
                     <div className="row">
                        <div className="row tp-form">
                            {/* <div className="col-6 col-xl-2 col-sm-4 pdrt mb-2">
                                <Stateful_Select
                                    label="Items Per Page"
                                    value={itemsPerPage.toString()}
                                    onChange={(newValue: any) => handlePerPage(newValue)}
                                    options={["10", "25", "50", "75", "100"]}
                                />
                            </div> */}
                        </div>
                        <div className="col-12">
                            <div className="table-responsive">
                                <table className="table table-view">
                                    <thead>
                                        <tr>
                                            <th>User Name</th>
                                            <th>Mobile No</th>
                                            <th>Transaction Id</th>
                                            <th>Amount</th>
                                            <th>Payment Method</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Status</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {uniquePending?.map((item, index) => (
                                            <tr id={item._id}>
                                                <td
                                                onClick={() => {
                                                    const url = `/user-report/${item.userId}/${item.userName}`;
                                                    window.open(url, "_blank");
                                                  }} style={{ cursor: 'pointer' }}
                                                >{item?.userName}</td>
                                                    <td>{item.userMobile}</td>
                                                    <td>{item.orderId}</td>
                                                    <td>{item.amount}</td>
                                                    <td>{`${item.paymentGatewayName} - ${item.mid}`}</td>
                                                    <td>{formattedDate(item.createdOn)}</td>
                                                    <td>{formatedTime(item.createdOn)}</td>
                                                    <td>{item.status}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                            <ul className="pagination d-flex justify-content-center">
                                <Stack spacing={2}>
                                    <Pagination
                                        count={totalPages}
                                        color="secondary"
                                        page={currentPage}
                                        onChange={(Event, New_Page) =>
                                            setCurrentPage(New_Page)
                                        }
                                    />
                                </Stack>
                            </ul>
                        </div>
                    </div>
                 )
                }
                </div>
            </div>
        </main>
        </div>
        </div>
     
     </>
    )
}

export default UniquePendingDepositUser