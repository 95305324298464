import React, { useState, useEffect, useContext, ChangeEvent } from 'react'
import Sidenav from '../SideNavigation/SideNavigation'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import { API_Endpoint } from '../../Configuration/Settings'
import { encryptData } from '../../utils/encryptData'
import { decryptData } from '../../utils/decryptData'
import axios from 'axios'
import { User_Context } from '../../Contexts/User'
import { ToastContainer, toast } from "react-toastify";
import Loader from '../Loader/Loader'
import { Button, Pagination, Stack } from '@mui/material'
import { formatDate, formatedTime } from '../../utils/utility'
import Checkbox from '@mui/material/Checkbox';
import { useLocation, useNavigate } from 'react-router-dom'
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

function AllCustomers() {
    const { User } = useContext(User_Context)
    const [loading, setLoading] = useState(false)
    const [itemsPerPage, setItemsPerPage] = useState<string>("20");
    const [currentPage, setCurrentPage] = useState(1);
    const [userData, setUserData] = useState([])
    const [totalPages, setTotalPages] = useState(1);
    const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>({});
    const location = useLocation()
    const navigate = useNavigate()
    const [searchName, setSearchName] = useState<string>("");
    const [searchMob, setSearchMob] = useState<string>("");
    const [searchCity, setSearchCity] = useState<string>("");
    const [searchState, setSearchState] = useState<string>("");


    const getAllUsers = () => {
        setLoading(true)
        let filter: {} = {}
        let data = {
            itemsPerPage: itemsPerPage,
            pageNo: currentPage,
            customerAllotment: true,
            filter: filter
        }
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/User/getAll`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${User.token}`,
            },
            data: { token: encryptData(data) },
        };

        axios.request(config).then((response) => {
            response.data.data = decryptData(response.data.data)
            setUserData(response.data.data.payload.items)
            setTotalPages(response.data.data.payload.totalPages)
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false)
            toast.error(error.response.data.message)
        });
    }

    useEffect(() => {
        getAllUsers()
    }, [currentPage])

    const handleChange = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedItems(prevState => ({
            ...prevState,
            [id]: event.target.checked,
        }));
    };

    const handleSubmit = () => {
        setLoading(true)
        let data = {
            _id: location.state.id,
            type: location.state.type,
            allotedCustomer: Object.keys(checkedItems)
        }
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/SubAdmin/update-customer`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${User.token}`,
            },
            data: { token: encryptData(data) },
        };

        axios.request(config)
            .then((response) => {
                setLoading(false);
                navigate("/customer-allotment")
            }).catch((error) => {
                console.log(error);
                setLoading(false)
                toast.error(error.response.data.message)
            });
    }
    const handleSearchName = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchName(event.target.value);
    };

    const handleSearchMob = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchMob(event.target.value);
    };

    const handleSearchCity = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchCity(event.target.value);
    };

    const handleSearchState = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchState(event.target.value);
    };

    const onSearch = () => {
        setLoading(true)
        let payload: {
            amount?: number, status?: string, pageNo?: number,
            transactionId?: string, roundId?: string, itemsPerPage?: string,
            filter: { [key: string]: string | number }, customerAllotment?: boolean
        }
        payload = {
            itemsPerPage: itemsPerPage,
            pageNo: currentPage,
            customerAllotment: true,
            filter: {}
        };
        if (searchName) {
            payload.filter["name"] = (searchName);
        }
        if (searchMob) {
            payload.filter["mobile"] = (searchMob);
        }
        if (searchCity) {
            payload.filter["city"] = (searchCity);
        }
        if (searchState) {
            payload.filter["state"] = (searchState);
        }
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/User/getAll`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${User.token}`,
            },
            data: { token: encryptData(payload) },
        };

        axios.request(config).then((response) => {
            response.data.data = decryptData(response.data.data)
            setUserData(response.data.data.payload.items)
            setTotalPages(response.data.data.payload.totalPages)
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false)
            toast.error(error.response.data.message)
        });
    }

    useEffect(() => {
        onSearch()
    }, [currentPage])

    return (
        <>
            {loading && (<Loader />)}
            <div className="g-sidenav-show  bg-gray-100">
                <Sidenav />
                <ToastContainer autoClose={2000} position="top-center" />
                <main className="main-content position-relative">
                    <div style={{ background: "#f8f9fa" }}>
                        <Breadcrumbs tab={"Add Customers"} button={<Button
                            onClick={handleSubmit}
                            className="btn-withdraw"
                            variant="contained"
                        >
                            Add
                        </Button>} />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 mt-2">
                                    <div className="table-responsive">
                                        <table className="table table-view">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Mobile No</th>
                                                    <th>City</th>
                                                    <th>State</th>
                                                    <th>Email</th>
                                                    <th>Created On</th>
                                                    <th>Last Activity</th>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th className='thdr'></th>
                                                    <th className='thdr'>
                                                        <div className='iconbtn-div'>
                                                            <input value={searchName} onChange={handleSearchName} placeholder=" Search name" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th className='thdr'>
                                                        <div className='iconbtn-div'>
                                                            <input value={searchMob} onChange={handleSearchMob} placeholder=" Search mob" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th className='thdr'>
                                                    <div className='iconbtn-div'>
                                                            <input value={searchCity} onChange={handleSearchCity} placeholder=" Search city" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th className='thdr'>
                                                    <div className='iconbtn-div'>
                                                            <input value={searchState} onChange={handleSearchState} placeholder=" Search state" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th className='thdr'></th>
                                                    <th className='thdr'></th>
                                                    <th className='thdr'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userData.map((item: any, index: number) => {
                                                    return (
                                                        <tr key={item._id} id={item._id}>
                                                            <td>{index + 1 + (currentPage && itemsPerPage ? (currentPage - 1) * parseInt(itemsPerPage) : 0)}</td>
                                                            <td>
                                                                <Checkbox
                                                                    checked={checkedItems[item._id]}
                                                                    onChange={handleChange(item._id)}
                                                                    color="primary"
                                                                    key={item._id}
                                                                />
                                                                {item.name}
                                                            </td>
                                                            <td>{item.mobile}</td>
                                                            <td>{item.city}</td>
                                                            <td>{item.state}</td>
                                                            <td>{item.email}</td>
                                                            <td>{`${formatDate(item.createdOn)} ${formatedTime(item.createdOn)}`}</td>
                                                            <td>{`${formatDate(item.updatedAt)} ${formatedTime(item.updatedAt)}`}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 20 }}>
                                            <Stack spacing={2}>
                                                <Pagination
                                                    count={totalPages}
                                                    color="secondary"
                                                    page={currentPage}
                                                    onChange={(Event, New_Page) =>
                                                        setCurrentPage(New_Page)
                                                    }
                                                />
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default AllCustomers