import React, { useState, useEffect, useContext, ChangeEvent } from 'react'
import TableFiltersSearchBox from '../../../../Components/TableFilters/TableFiltersSearchBox'
import { API_Endpoint } from '../../../../Configuration/Settings'
import { User_Context } from '../../../../Contexts/User'
import { encryptData } from '../../../../utils/encryptData'
import { decryptData } from '../../../../utils/decryptData'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import TableFilterDatePicker from '../../../../Components/TableFilters/TableFilterDatePicker'
import { ToastContainer, toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";


// Components
import Loader from '../../../../Components/Loader/Loader'
import { round } from 'lodash'
import { formatDate, formatedTime } from '../../../../utils/utility'
import { Pagination, Stack } from '@mui/material'
import TableFiltersSelectBox from '../../../../Components/TableFilters/TableFiltersSelectBox'

function WacHistory() {
    const [loading, setLoading] = useState(false)
    const [historyData, setHistoryData] = useState([]);
    const [transactionId, setTransactionId] = useState<string>("");
    const [searchAmount, setSearchAmount] = useState<string>("");
    const [searchWinAmt, setSearchWinAmt] = useState<string>("");
    const [searchRoundId, setSearchRoundId] = useState<string>("");
    const [gameRef, setGameRef] = useState<string>("");
    const { User } = useContext(User_Context)
    const { User_ID } = useParams();
    const [itemsPerPage, setItemsPerPage] = useState<number>(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchStatus, setSearchStatus] = useState('');
    const typeArray = [{ id: 'Select', value: 'Select' }, { id: 'P', value: 'Loss' }, { id: 'W', value: 'Win' }, { id: 'R', value: 'Roll Back' }]

    const getWacHistory = async () => {
        setLoading(true)
        let payload: {
            amount?: number, status?: string, pageNo?: number,
            transactionId?: string, gameReference?: string,
            roundId?: string, winAmount?: number, itemsPerPage?: number,
            filter: { [key: string]: string | number },
        }
        payload = {
            itemsPerPage: itemsPerPage,
            pageNo: currentPage,
            filter: {
                userId: User_ID?.toString() ?? ''
            }
        };
        let config = {
            method: "post",
            url: `${API_Endpoint}/User/get-wacs-history`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${User.token}`,
            },
            data: {
                token: encryptData(payload)
            },
        };
        axios.request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data);
                setHistoryData(response.data.data.payload.items);
                setTotalPages(response.data.data.payload.totalPages);
                setLoading(false)
            }).catch((error) => {
                toast.error(error.response.data.message)
                setLoading(false)
            });
    }

    const onSearch = (e?: any) => {
        setLoading(true)
        let payload: {
            amount?: number, status?: string, pageNo?: number,
            transactionId?: string, gameReference?: string,
            roundId?: string, winAmount?: number, itemsPerPage?: number,
            filter: { [key: string]: string | number },
        }
        payload = {
            itemsPerPage: itemsPerPage,
            pageNo: currentPage,
            filter: {
                userId: User_ID?.toString() ?? ''
            }
        };
        if (transactionId) {
            payload.filter["transactionId"] = (transactionId);
        }
        if (gameRef) {
            payload.filter["gameReference"] = (gameRef);
        }
        if (searchAmount) {
            payload.filter["amount"] = (searchAmount);
        }
        if (searchWinAmt) {
            payload.filter["winAmount"] = (searchWinAmt);
        }
        if (searchRoundId) {
            payload.filter["roundId"] = (searchRoundId);
        }
        if (e?.target?.value == 'Select') {
            setSearchStatus('Select')
        } else if (e?.target?.value) {
            setSearchStatus(e?.target?.value);
            payload.filter["status"] = (e?.target?.value);
        }
        let config = {
            method: "post",
            url: `${API_Endpoint}/User/get-wacs-history`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${User.token}`,
            },
            data: {
                token: encryptData(payload)
            },
        };
        axios.request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data);
                setHistoryData(response.data.data.payload.items);
                setTotalPages(response.data.data.payload.totalPages);
                setLoading(false)
            }).catch((error) => {
                toast.error(error.response.data.message)
                setLoading(false)
            });
    }


    useEffect(() => {
        getWacHistory();
    }, []);

    useEffect(() => {
        onSearch();
    }, [currentPage]);


    const getBackgroundStyle = (action: any) => {
        switch (action) {
            case "W":
                return "rgba(0, 225, 21, 0.31)";
            case "P":
                return "rgba(255, 0, 149, 0.22)";
            case "R":
                return "rgba(255, 255, 0, 0.36)";
            default:
                return "";
        }
    };

    const handleTransactionId = (event: ChangeEvent<HTMLInputElement>) => {
        setTransactionId(event.target.value);
    };

    const handleGameRef = (event: ChangeEvent<HTMLInputElement>) => {
        setGameRef(event.target.value);
    };

    const handleSearchAmount = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchAmount(event.target.value);
    };

    const handleSearchWinAmt = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchWinAmt(event.target.value);
    };

    const handleSearchRoundId = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchRoundId(event.target.value);
    };

    return (
        <>
            {loading && <Loader />}
            <div className="row mt-4">
                <ToastContainer autoClose={2000} position="top-center" />
                <div className="table-responsive">
                    <table className="table table-view">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Transaction Id</th>
                                <th>Game Reference</th>
                                <th>Opening Balance</th>
                                <th>Amount</th>
                                <th>Closing Balance</th>
                                <th>Winning Amount</th>
                                <th>Commission Amount</th>
                                <th>Refund Amount</th>
                                <th>Winning From Wac</th>
                                <th>Round Id</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <thead >
                            <tr>
                                <th></th>
                                <th>
                                    <div className='iconbtn-div'>
                                        <input value={transactionId} onChange={handleTransactionId} placeholder=" Search transaction id" />
                                        <IconButton
                                            onClick={onSearch}
                                            type="button"
                                            sx={{ p: '10px 0px' }}
                                            aria-label="search"
                                            className='icon-button'
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </div>
                                </th>
                                <th>
                                    <div className='iconbtn-div'>
                                        <input value={gameRef} onChange={handleGameRef} placeholder=" Search by game ref" />
                                        <IconButton
                                            onClick={onSearch}
                                            type="button"
                                            sx={{ p: '10px 0px' }}
                                            aria-label="search"
                                            className='icon-button'
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </div>
                                </th>
                                <th></th>
                                <th>
                                    <div className='iconbtn-div'>
                                        <input value={searchAmount} onChange={handleSearchAmount} placeholder=" Search by amount" />
                                        <IconButton
                                            onClick={onSearch}
                                            type="button"
                                            sx={{ p: '10px 0px' }}
                                            aria-label="search"
                                            className='icon-button'
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </div>
                                </th>
                                <th></th>
                                <th>
                                    <div className='iconbtn-div'>
                                        <input value={searchWinAmt} onChange={handleSearchWinAmt} placeholder=" Search by win amount" />
                                        <IconButton
                                            onClick={onSearch}
                                            type="button"
                                            sx={{ p: '10px 0px' }}
                                            aria-label="search"
                                            className='icon-button'
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </div>
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>
                                    <div className='iconbtn-div'>
                                        <input value={searchRoundId} onChange={handleSearchRoundId} placeholder=" Search by round id" />
                                        <IconButton
                                            onClick={onSearch}
                                            type="button"
                                            sx={{ p: '10px 0px' }}
                                            aria-label="search"
                                            className='icon-button'
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </div>
                                </th>
                                <th>
                                    <TableFiltersSelectBox
                                        value={searchStatus}
                                        options={typeArray}
                                        onChange={onSearch}
                                        classname={"table-filters-select-starline"}
                                        searchVal={true}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {historyData.map((data: any, index: number) =>
                                <tr style={{ background: getBackgroundStyle(data.status), color: "black" }} key={data._id}>
                                    <td>{index + 1 + (currentPage && itemsPerPage ? (currentPage - 1) * itemsPerPage : 0)}</td>
                                    <td>{data.transactionId}</td>
                                    <td>{data.gameReference}</td>
                                    <td>{Math.round(data.openingBalance)}</td>
                                    <td>{Math.round(data.amount)}</td>
                                    <td>{Math.round(data.closingBalance)}</td>
                                    <td>{Math.round(data.winAmount)}</td>
                                    <td>{Math.round(data.commissionAmount)}</td>
                                    <td>{Math.round(data.refundAmount)}</td>
                                    <td>{data.winningCommingFromWac}</td>
                                    <td>{data.roundId}</td>
                                    <td>{data.status === "W" ? "Win" : data.status === "P" ? "Loss" : data.status === "R" ? "Roll Back" : ""}</td>
                                </tr>)}
                        </tbody>
                    </table>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 20 }}>
                        <Stack spacing={2}>
                            <Pagination
                                count={totalPages}
                                color="secondary"
                                page={currentPage}
                                onChange={(Event, New_Page) =>
                                    setCurrentPage(New_Page)
                                }
                            />
                        </Stack>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WacHistory