import React, { useState, useEffect, useContext, ChangeEvent } from 'react'
import Sidenav from '../SideNavigation/SideNavigation'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import { API_Endpoint } from '../../Configuration/Settings'
import { encryptData } from '../../utils/encryptData'
import { decryptData } from '../../utils/decryptData'
import axios from 'axios'
import { User_Context } from '../../Contexts/User'
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from 'react-router-dom'
import { formatDate, formatedTime } from '../../utils/utility'
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Loader from '../Loader/Loader'
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material'


function RemoveCustomer() {
    const { User } = useContext(User_Context)
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const [allottedCustomer, setAllottedCustomer] = useState([])
    const [customerName, setCustomerName] = useState<string>("");
    const [customerMob, setCustomerMob] = useState<string>("");
    const [customerAccNo, setCustomerAccNo] = useState<string>("");
    const [customerAadhar, setCustomerAadhar] = useState<string>("");
    const [customerCity, setCustomerCity] = useState<string>("");
    const [customerState, setCustomerState] = useState<string>("");
    const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>({});
    const navigate = useNavigate()

    const getAllottedCustomers = async () => {
        setLoading(true)
        let payload: {
            filter: { [key: string]: string | number },
        }
        payload = {
            filter: {
                _id: location.state.customData
            }
        };

        let config = {
            method: "post",
            url: `${API_Endpoint}/SubAdmin/get-all-customer`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${User.token}`,
            },
            data: {
                token: encryptData(payload)
            },
        };
        axios.request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data);
                setAllottedCustomer(response.data.data.payload)
                setLoading(false)
            }).catch((error) => {
                toast.error(error.response.data.message)
                setLoading(false)
            });
    }

    const onSearch = () => {
        setLoading(true)
        let payload: {
            filter: { [key: string]: string | number },
        }
        payload = {
            filter: {
                _id: location.state.customData
            }
        };
        if (customerName) {
            payload.filter["name"] = (customerName);
        }
        if (customerMob) {
            payload.filter["mobile"] = (customerMob);
        }
        if (customerAccNo) {
            payload.filter["accountNumber"] = (customerAccNo);
        }
        if (customerAadhar) {
            payload.filter["aadhaarNumber"] = (customerAadhar);
        }
        if (customerCity) {
            payload.filter["city"] = (customerCity);
        }
        if (customerState) {
            payload.filter["state"] = (customerState);
        }
        let config = {
            method: "post",
            url: `${API_Endpoint}/SubAdmin/get-all-customer`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${User.token}`,
            },
            data: {
                token: encryptData(payload)
            },
        };
        axios.request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data);
                setAllottedCustomer(response.data.data.payload)
                setLoading(false)
            }).catch((error) => {
                toast.error(error.response.data.message)
                setLoading(false)
            });
    }

    useEffect(() => {
        getAllottedCustomers()
    }, [])

    const handleCustomerName = (event: ChangeEvent<HTMLInputElement>) => {
        setCustomerName(event.target.value);
    };

    const handleCustomerMob = (event: ChangeEvent<HTMLInputElement>) => {
        setCustomerMob(event.target.value);
    };

    const handleCustomerAcc = (event: ChangeEvent<HTMLInputElement>) => {
        setCustomerAccNo(event.target.value);
    };

    const handleCustomerAadhar = (event: ChangeEvent<HTMLInputElement>) => {
        setCustomerAadhar(event.target.value);
    };

    const handleCustomerCity = (event: ChangeEvent<HTMLInputElement>) => {
        setCustomerCity(event.target.value);
    };

    const handleCustomerState = (event: ChangeEvent<HTMLInputElement>) => {
        setCustomerState(event.target.value);
    };

    const handleChange = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedItems(prevState => ({
            ...prevState,
            [id]: event.target.checked,
        }));
    };

    const handleSubmit = () => {
        setLoading(true)
        let data = {
            _id: location.state.id,
            type: "remove",
            allotedCustomer: Object.keys(checkedItems)
        }
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/SubAdmin/update-customer`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${User.token}`,
            },
            data: { token: encryptData(data) },
        };

        axios.request(config).then((response) => {
            setLoading(false);
            navigate("/customer-allotment")
        }).catch((error) => {
            console.log(error);
            setLoading(false)
            toast.error(error.response.data.message)
        });
    }

    return (
        <>
            {loading && (<Loader />)}
            <div className="g-sidenav-show  bg-gray-100">
                <Sidenav />
                <ToastContainer autoClose={2000} position="top-center" />
                <main className="main-content position-relative">
                    <div style={{ background: "#f8f9fa" }}>
                        <Breadcrumbs tab={"Remove Customers"} button={<Button
                            onClick={handleSubmit}
                            className="btn-withdraw"
                            variant="contained"
                        >
                            Remove
                        </Button>} />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 mt-2">
                                    <div className="table-responsive">
                                        <table className="table table-view">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Mobile No</th>
                                                    <th>Account Number</th>
                                                    <th>Aadhar Number</th>
                                                    <th>City</th>
                                                    <th>State</th>
                                                    <th>Email</th>
                                                    <th>Device Type</th>
                                                    <th>Balance</th>
                                                    <th>Created On</th>
                                                    <th>Last Activity</th>
                                                    <th>Bonus Balance</th>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th className="thdr"></th>
                                                    <th className="thdr">
                                                        <div className='iconbtn-div'>
                                                            <input value={customerName} onChange={handleCustomerName} placeholder=" Search name" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th className="thdr">
                                                        <div className='iconbtn-div'>
                                                            <input value={customerMob} onChange={handleCustomerMob} placeholder=" Search mobile" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th className="thdr">
                                                        <div className='iconbtn-div'>
                                                            <input value={customerAccNo} onChange={handleCustomerAcc} placeholder=" Search acc no" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th className="thdr">
                                                        <div className='iconbtn-div'>
                                                            <input value={customerAadhar} onChange={handleCustomerAadhar} placeholder=" Search aadhar" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th className="thdr">
                                                        <div className='iconbtn-div'>
                                                            <input value={customerCity} onChange={handleCustomerCity} placeholder=" Search city" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th className="thdr">
                                                        <div className='iconbtn-div'>
                                                            <input value={customerState} onChange={handleCustomerState} placeholder=" Search state" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th className="thdr"></th>
                                                    <th className="thdr"></th>
                                                    <th className="thdr"></th>
                                                    <th className="thdr"></th>
                                                    <th className="thdr"></th>
                                                    <th className="thdr"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allottedCustomer.map((item: any, index: number) => {
                                                    return (
                                                        <tr id={item._id}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <Checkbox
                                                                    checked={checkedItems[item._id]}
                                                                    onChange={handleChange(item._id)}
                                                                    color="primary"
                                                                    key={item._id}
                                                                />
                                                                {item.name}
                                                            </td>
                                                            <td>{item.mobile}</td>
                                                            <td>{item.accountNumber}</td>
                                                            <td>{item.aadhaarNumber}</td>
                                                            <td>{item.city}</td>
                                                            <td>{item.state}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.deviceType}</td>
                                                            <td>{Math.floor(item.balance)}</td>
                                                            <td>{`${formatDate(item.createdOn)} ${formatedTime(item.createdOn)}`}</td>
                                                            <td>{`${formatDate(item.updatedOn)} ${formatedTime(item.updatedOn)}`}</td>
                                                            <td>{Math.floor(item.bonusWalletBalance)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default RemoveCustomer