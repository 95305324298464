import React from "react";

const DashboardCard = ({ title, data }: any) => {
  return (
    <div className="col-md-4 mb-4">
      <div className="card card-dashboard">
        <div className="card-body p-3">
          <h4 className="font-weight-bolder">{title}</h4>
          {data.map((item:any) => (
            <h6 key={item.label} className="mb-1 pt-2 text-bold">
              {item.label}:
              <span className="betamount">{Math.floor(item.value)}</span>
            </h6>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
