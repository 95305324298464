import React from 'react'
import NodataImage from "../../Assets/Images/nodata.png"
import './Not_Found.css';

function Not_Found() {
  return (
    <div className='Not_Found_Page'>
        <img src={NodataImage}></img>
    </div>
  )
}

export default Not_Found