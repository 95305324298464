import React, { ChangeEvent, useState, useEffect, useContext } from 'react'
import "./WalletHistory.css"
import TableFiltersSelectBox from '../../../../Components/TableFilters/TableFiltersSelectBox';
import TableFilterDatePicker from '../../../../Components/TableFilters/TableFilterDatePicker';
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { API_Endpoint } from '../../../../Configuration/Settings';
import { User_Context } from '../../../../Contexts/User';
import axios from 'axios';
import { encryptData } from '../../../../utils/encryptData';
import { decryptData } from '../../../../utils/decryptData';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from '../../../../Components/Loader/Loader';
import { useParams } from 'react-router-dom';
import { Pagination, Stack } from '@mui/material';
import { formatDate, formatedTime } from '../../../../utils/utility';

function StarLineHistory() {
    const [transactionId, setTransactionId] = useState<string>("");
    const [bazarName, setBazarName] = useState<string>("");
    const [gameName, setGameName] = useState<string>("");
    const [starlineGame, setStarlineGame] = useState<string>("");
    const [point, setPoint] = useState<string>("");
    const [winningPoint, setWinningPoint] = useState<string>("");
    const [commission, setCommission] = useState<string>("");
    const [selectedType, setSelectedType] = useState('');
    const [status, setStatus] = useState('');
    const { User } = useContext(User_Context)
    const typeArray = [{ id: 'P', value: 'Pending' }, { id: 'W', value: 'Win' }, { id: 'L', value: 'Loss' }]
    const [loading, setLoading] = useState<boolean>(false);
    const [itemsPerPage, setItemsPerPage] = useState<number>(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { User_ID, userName } = useParams();
    const [historyData, setHistoryData] = useState([]);



    const handleTransactionId = (event: ChangeEvent<HTMLInputElement>) => {
        setTransactionId(event.target.value);
    };

    const handleBazarName = (event: ChangeEvent<HTMLInputElement>) => {
        setBazarName(event.target.value);
    };

    const handleGameName = (event: ChangeEvent<HTMLInputElement>) => {
        setGameName(event.target.value);
    };

    const handleStarlineGame = (event: ChangeEvent<HTMLInputElement>) => {
        setStarlineGame(event.target.value);
    };

    const handlePoint = (event: ChangeEvent<HTMLInputElement>) => {
        setPoint(event.target.value);
    };

    const handleWinningPoint = (event: ChangeEvent<HTMLInputElement>) => {
        setWinningPoint(event.target.value);
    };

    const handleCommission = (event: ChangeEvent<HTMLInputElement>) => {
        setCommission(event.target.value);
    };


    const getStarLineHistory = () => {
        setLoading(true)
        let payload: {
            customer_id?: string, amount?: number, status?: string,
            transaction_id?: string, bazar_name?: string,
            game_name?: string, point?: string, game?: string,
            game_id?: string, time?: string, itemsPerPage?: number,
            winning_point?: string, bazar_id?: string, pageNo?: number,
            filter: { [key: string]: string | number },
        }
        payload = {
            itemsPerPage: itemsPerPage,
            pageNo: currentPage,
            filter: {
                customer_id: User_ID?.toString() ?? ''
            }
        };
        let config = {
            method: "post",
            url: `${API_Endpoint}/User/get-starline-history`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${User.token}`,
            },
            data: {
                token: encryptData(payload)
            },
        };
        axios.request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data);
                setHistoryData(response.data.data.payload.items);
                setTotalPages(response.data.data.payload.totalPages);
                setLoading(false)
            }).catch((error) => {
                toast.error(error.response.data.message)
                setLoading(false)
            });
    }

    useEffect(() => {
        getStarLineHistory()
    }, [currentPage])

    const onSearch = (e?: any) => {
        setLoading(true)
        let payload: {
            customer_id?: string, amount?: number, status?: string,
            transaction_id?: string, bazar_name?: string, result_date?: any,
            game_name?: string, point?: string, game?: string,
            game_id?: string, time?: string, itemsPerPage?: number,
            winning_point?: string, bazar_id?: string, pageNo?: number,
            filter: { [key: string]: string | number },
        }
        payload = {
            itemsPerPage: itemsPerPage,
            pageNo: currentPage,
            filter: {
                customer_id: User_ID?.toString() ?? ''
            }
        };
        if (transactionId) {
            payload.filter["transaction_id"] = (transactionId);
        }
        if (bazarName) {
            payload.filter["bazar_name"] = (bazarName);
        }
        if (gameName) {
            payload.filter["game_name"] = (gameName);
        }
        if (starlineGame) {
            payload.filter["game"] = (starlineGame);
        }
        if (selectedType) {
            payload.filter["result_date"] = (selectedType);
        }
        if (point) {
            payload.filter["point"] = (point);
        }
        if (winningPoint) {
            payload.filter["winning_point"] = (winningPoint);
        }
        if (e?.target?.value == 'Select') {
            setStatus('Select')
        } else if (e?.target?.value) {
            setStatus(e?.target?.value);
            payload.filter["status"] = (e?.target?.value);
        }
        if (commission) {
            payload.filter["commission"] = (commission);
        }
        let config = {
            method: "post",
            url: `${API_Endpoint}/User/get-starline-history`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${User.token}`,
            },
            data: {
                token: encryptData(payload)
            },
        };
        axios.request(config)
            .then((response) => {
                response.data.data = decryptData(response.data.data);
                setHistoryData(response.data.data.payload.items);
                setTotalPages(response.data.data.payload.totalPages);
                setLoading(false)
            }).catch((error) => {
                toast.error(error.response.data.message)
                setLoading(false)
            });
    }


    return (
        <>
            {loading && (<Loader />)}
            <div className="g-sidenav-show bg-gray-100">
                <ToastContainer autoClose={2000} position="top-center" />
                <main>
                    <div style={{ background: "#f8f9fa" }}>
                        <div className="container-fluid-starline">
                            <div className="row">
                                <div className="col-12 mt-4">
                                    <div className="table-responsive">
                                        <table className="table table-view">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Transaction ID</th>
                                                    <th>Bazar Name</th>
                                                    <th>Game Name</th>
                                                    <th>Game</th>
                                                    <th>Time</th>
                                                    <th>Game Date</th>
                                                    <th>Point</th>
                                                    <th>Winning Point</th>
                                                    <th>Status</th>
                                                    <th>Commission</th>
                                                    <th>Betting Time</th>
                                                    <th>Wallet Time</th>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>
                                                        <div className='iconbtn-div'>
                                                            <input value={transactionId} onChange={handleTransactionId} placeholder=" Search transaction id" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='iconbtn-div'>
                                                            <input value={bazarName} onChange={handleBazarName} placeholder=" Search bazar name" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='iconbtn-div'>
                                                            <input value={gameName} onChange={handleGameName} placeholder=" Search game name" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='iconbtn-div'>
                                                            <input value={starlineGame} onChange={handleStarlineGame} placeholder=" Search game" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th></th>
                                                    <th>
                                                        <div className='iconbtn-div'>
                                                            <TableFilterDatePicker
                                                                onChange={(e: any) => setSelectedType(e.target.value)}
                                                                classname={"table-filters-select-starline"}
                                                            />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='iconbtn-div'>
                                                            <input value={point} onChange={handlePoint} placeholder=" Search point" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='iconbtn-div'>
                                                            <input value={winningPoint} onChange={handleWinningPoint} placeholder=" Search winning point" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <TableFiltersSelectBox
                                                            value={status}
                                                            options={typeArray}
                                                            onChange={onSearch}
                                                            classname={"table-filters-select-starline"}
                                                        />
                                                    </th>
                                                    <th>
                                                        <div className='iconbtn-div'>
                                                            <input value={commission} onChange={handleCommission} placeholder=" Search commission" />
                                                            <IconButton
                                                                onClick={onSearch}
                                                                type="button"
                                                                sx={{ p: '10px 0px' }}
                                                                aria-label="search"
                                                                className='icon-button'
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </div>
                                                    </th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {historyData.map((item: any, index: number) => {
                                                    return (
                                                        <tr key={item._id}>
                                                            <td>{index + 1 + (currentPage && itemsPerPage ? (currentPage - 1) * itemsPerPage : 0)}</td>
                                                            <td>{item.transaction_id}</td>
                                                            <td>{item.bazar_name}</td>
                                                            <td>{item.game_name}</td>
                                                            <td>{item.game}</td>
                                                            <td>{item.time}</td>
                                                            <td>{item.result_date}</td>
                                                            <td>{item.point}</td>
                                                            <td>{item.winning_point}</td>
                                                            <td>{item.status}</td>
                                                            <td>{item.commission}</td>
                                                            <td>{`${formatDate(item.createdOn)} ${formatedTime(item.createdOn)}`}</td>
                                                            <td>{`${formatDate(item.updatedOn)} ${formatedTime(item.updatedOn)}`}</td>
                                                        </tr>
                                                    )

                                                })}
                                            </tbody>
                                        </table>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 20 }}>
                                            <Stack spacing={2}>
                                                <Pagination
                                                    count={totalPages}
                                                    color="secondary"
                                                    page={currentPage}
                                                    onChange={(Event, New_Page) =>
                                                        setCurrentPage(New_Page)
                                                    }
                                                />
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default StarLineHistory