import React from 'react';
import '../../Css/style.css';

function Breadcrumbs({ tab, button, link }: any) {
  return (
    <div className="container-fluid py-1 px-3">
      <nav aria-label="breadcrumb" className="breadcrumbs">
        <ol className="breadcrumb bg-transparent mb-0 px-0">
          <li className="breadcrumb-item">
            <span className="opacity-5 text-dark">Pages</span>
          </li>
          <li className="breadcrumb-item active text-dark" aria-current="page">
            <b>{link ? link : tab }</b>
          </li>
          {button && <div>{button}</div>}
        </ol>
      </nav>

     
    </div>
  );
}

export default Breadcrumbs;
