import { Navigate, Outlet } from 'react-router-dom';
import { ReactElement, useContext } from 'react';
import { User_Context } from '../Contexts/User';

interface Props
{
    children: ReactElement;
    Redirection_Path?: string;
    Responsibility?: string;
}

const Private_Route = ({ children, Redirection_Path, Responsibility }: Props) => 
{
	const { User, Set_User } = useContext (User_Context);
	if (!User.token || User.token === '' || (Responsibility && !User.data.Responsibilities.includes (Responsibility)))
	{
		Set_User({ token: "", data: { _id: '', name: '', mobile: '', email: '', balance: 0, profitLoss: 0, createdOn: '', updatedOn: '', Role_ID: '',Responsibilities: [] } });
        localStorage.removeItem("user");
        localStorage.removeItem("token");
		return <Navigate to={Redirection_Path || '/'} replace />;
	}
	return children ? children : <Outlet />;
};

export default Private_Route;