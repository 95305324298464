import React, { useState } from 'react'
import Reusable_Input from '../../../../Components/InputField/InputField';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
    DialogActions,
} from "@material-ui/core";
import Button from "@mui/material/Button";

function ScannerDeposit() {
    const [amount, setAmount] = useState<string>("")
    const [amountError, setAmountError] = useState<boolean>(false)
    const [amountHelperText, setAmountHelperText] = useState<string>("")
    const [utr, setUtr] = useState<string>("")
    const [utrError, setUtrError] = useState<boolean>(false)
    const [utrHelperText, setUtrHelperText] = useState<string>("")
    const [remark, setRemark] = useState<string>("")
    const [remarkError, setRemarkError] = useState<boolean>(false)
    const [remarkHelperText, setRemarkHelperText] = useState<string>("")
    const handleAmount = () => { }
    const handleMessage = () => { }
    const handleUtr = () => { }
    return (
        <div className='parent-container'>
            <div className='centered-div'>
                <div className='mt-2 text-inp'>
                    <label>Amount</label>
                    <Reusable_Input label={"Enter Amount"} value={amount} error={amountError} helperText={amountHelperText} onChange={handleAmount} type={"number"} fullWidth={true} />
                </div>
                <div className='mt-2 text-inp'>
                    <label>UTR</label>
                    <Reusable_Input label={"Enter UTR"} value={utr} error={utrError} helperText={utrHelperText} onChange={handleUtr} type={"number"} fullWidth={true} />
                </div>
                <div className='mt-1 text-inp'>
                    <label>Message</label>
                    <Reusable_Input label={"Enter Remark"} value={remark} error={remarkError} helperText={remarkHelperText} onChange={handleMessage} type={"text"} fullWidth={true} />
                </div>
                <div className='d-flex justify-content-center mt-2 mb-2'>
                    <DialogActions>
                        <Button
                            className="btn-popup"
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                    </DialogActions>
                </div>
            </div>
        </div>
    )
}

export default ScannerDeposit