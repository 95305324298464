import React from 'react';

function TableFiltersSelectBox({ onChange, value, options, classname, searchVal }: TableFiltersSelectBoxProps) {
    return (
        <select
            name="cr-dr"
            id="cr-dr"
            className={ classname ? classname :'table-filters-select-box'}
            onChange={(e) => onChange(e)}
            value={value}
        >
            {!searchVal && (
                <option value="">Select</option>
            )}
            {options.map((item: any, index: number) => (
                <option key={index} value={item.id}>{item.value}</option>
            ))}

        </select>
    )
}

export default TableFiltersSelectBox;

interface TableFiltersSelectBoxProps {
    onChange: any
    value: string
    options: any
    classname?: any
    searchVal?:boolean
}
