import React, { useState, useContext, useEffect, ChangeEvent } from 'react'
import { Pagination, Stack } from '@mui/material'
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useParams } from 'react-router-dom'
import { API_Endpoint } from '../../../../Configuration/Settings';
import { encryptData } from '../../../../utils/encryptData';
import { decryptData } from '../../../../utils/decryptData';
import axios from 'axios';
import { User_Context } from '../../../../Contexts/User';
import { ToastContainer, toast } from "react-toastify";
import Loader from '../../../../Components/Loader/Loader';
import { formatDate, formatedTime } from '../../../../utils/utility';
import TableFiltersSelectBox from '../../../../Components/TableFilters/TableFiltersSelectBox';


function QtechHistory() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { User_ID } = useParams();
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const [loading, setLoading] = useState(false)
  const { User } = useContext(User_Context)
  const [historyData, setHistoryData] = useState([]);
  const [transactionId, setTransactionId] = useState<string>("");
  const [searchRoundId, setSearchRoundId] = useState<string>("");
  const [searchGameId, setSearchGameId] = useState<string>("");
  const [searchCategory, setSearchCategory] = useState<string>("");
  const [searchAmount, setSearchAmount] = useState<string>("");
  const [searchWinning, setSearchWinning] = useState<string>("");
  const [searchStatus, setSearchStatus] = useState('');
  const typeArray = [{ id: 'Select', value: 'Select' }, { id: 'L', value: 'Loss' }, { id: 'W', value: 'Win' }, { id: 'R', value: 'Roll Back' }]



  const getQtechHistory = async () => {
    setLoading(true)
    let payload: {
      amount?: number, status?: string, pageNo?: number,
      transactionId?: string, gameReference?: string,
      roundId?: string, winAmount?: number, itemsPerPage?: number,
      filter: { [key: string]: string | number },
    }
    payload = {
      itemsPerPage: itemsPerPage,
      pageNo: currentPage,
      filter: {
        userId: User_ID?.toString() ?? ''
      }
    };
    let config = {
      method: "post",
      url: `${API_Endpoint}/User/get-qtech-history`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${User.token}`,
      },
      data: {
        token: encryptData(payload)
      },
    };
    axios.request(config)
      .then((response) => {
        response.data.data = decryptData(response.data.data);
        setHistoryData(response.data.data.payload.items);
        setTotalPages(response.data.data.payload.totalPages);
        setLoading(false)
      }).catch((error) => {
        toast.error(error.response.data.message)
        setLoading(false)
      });
  }

  const onSearch = (e?: any) => {
    setLoading(true)
    let payload: {
      amount?: number, status?: string, pageNo?: number,
      transactionId?: string, roundId?: string, itemsPerPage?: number,
      filter: { [key: string]: string | number },
    }
    payload = {
      itemsPerPage: itemsPerPage,
      pageNo: currentPage,
      filter: {
        userId: User_ID?.toString() ?? ''
      }
    };
    if (transactionId) {
      payload.filter["transactionId"] = (transactionId);
    }
    if (searchRoundId) {
      payload.filter["roundId"] = (searchRoundId);
    }
    if (searchGameId) {
      payload.filter["gameId"] = (searchGameId);
    }
    if (searchCategory) {
      payload.filter["category"] = (searchCategory);
    }
    if (searchAmount) {
      payload.filter["amount"] = (searchAmount);
    }
    if (searchWinning) {
      payload.filter["winning"] = (searchWinning);
    }
    if (e?.target?.value == 'Select') {
      setSearchStatus('Select')
    } else if (e?.target?.value) {
      setSearchStatus(e?.target?.value);
      payload.filter["status"] = (e?.target?.value);
    }
    let config = {
      method: "post",
      url: `${API_Endpoint}/User/get-qtech-history`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${User.token}`,
      },
      data: {
        token: encryptData(payload)
      },
    };
    axios.request(config)
      .then((response) => {
        response.data.data = decryptData(response.data.data);
        console.log(response.data.data);
        setHistoryData(response.data.data.payload.items);
        setTotalPages(response.data.data.payload.totalPages);
        setLoading(false)
      }).catch((error) => {
        toast.error(error.response.data.message)
        setLoading(false)
      });
  }

  useEffect(() => {
    getQtechHistory()
  }, [])

  useEffect(() => {
    onSearch();
  }, [currentPage]);

  const handleTransactionId = (event: ChangeEvent<HTMLInputElement>) => {
    setTransactionId(event.target.value);
  };

  const handleRoundId = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchRoundId(event.target.value);
  };

  const handleGameId = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchGameId(event.target.value);
  };


  const handleCategory = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchCategory(event.target.value);
  };


  const handleAmount = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchAmount(event.target.value);
  };

  const handleWinning = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchWinning(event.target.value);
  };


  const getBackgroundStyle = (action: any) => {
    switch (action) {
      case "W":
        return "rgba(0, 225, 21, 0.31)";
      case "L":
        return "rgba(255, 0, 149, 0.22)";
      case "R":
        return "rgba(255, 255, 0, 0.36)";
      default:
        return "";
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="row mt-4">
        <ToastContainer autoClose={2000} position="top-center" />
        <div className="table-responsive">
          <table className="table table-view">
            <thead>
              <tr>
                <th>#</th>
                <th>Transaction Id</th>
                <th>Round Id</th>
                <th>Game Id</th>
                <th>Category</th>
                <th>Amount</th>
                <th>Winning</th>
                <th>Roll Back Amount</th>
                <th>Commission Amount</th>
                <th>Amount After Commission</th>
                <th>Bet Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th></th>
                <th>
                  <div className='iconbtn-div'>
                    <input value={transactionId} onChange={handleTransactionId} placeholder=" Search transaction id" />
                    <IconButton
                      onClick={onSearch}
                      type="button"
                      sx={{ p: '10px 0px' }}
                      aria-label="search"
                      className='icon-button'
                    >
                      <SearchIcon />
                    </IconButton>
                  </div>
                </th>
                <th>
                  <div className='iconbtn-div'>
                    <input value={searchRoundId} onChange={handleRoundId} placeholder=" Search round id" />
                    <IconButton
                      onClick={onSearch}
                      type="button"
                      sx={{ p: '10px 0px' }}
                      aria-label="search"
                      className='icon-button'
                    >
                      <SearchIcon />
                    </IconButton>
                  </div>
                </th>
                <th>
                  <div className='iconbtn-div'>
                    <input value={searchGameId} onChange={handleGameId} placeholder=" Search game id" />
                    <IconButton
                      onClick={onSearch}
                      type="button"
                      sx={{ p: '10px 0px' }}
                      aria-label="search"
                      className='icon-button'
                    >
                      <SearchIcon />
                    </IconButton>
                  </div>
                </th>
                <th>
                  <div className='iconbtn-div'>
                    <input value={searchCategory} onChange={handleCategory} placeholder=" Search category" />
                    <IconButton
                      onClick={onSearch}
                      type="button"
                      sx={{ p: '10px 0px' }}
                      aria-label="search"
                      className='icon-button'
                    >
                      <SearchIcon />
                    </IconButton>
                  </div>
                </th>
                <th>
                  <div className='iconbtn-div'>
                    <input value={searchAmount} onChange={handleAmount} placeholder=" Search amount" />
                    <IconButton
                      onClick={onSearch}
                      type="button"
                      sx={{ p: '10px 0px' }}
                      aria-label="search"
                      className='icon-button'
                    >
                      <SearchIcon />
                    </IconButton>
                  </div>
                </th>
                <th>
                  <div className='iconbtn-div'>
                    <input value={searchWinning} onChange={handleWinning} placeholder=" Search winning" />
                    <IconButton
                      onClick={onSearch}
                      type="button"
                      sx={{ p: '10px 0px' }}
                      aria-label="search"
                      className='icon-button'
                    >
                      <SearchIcon />
                    </IconButton>
                  </div>
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>
                  <TableFiltersSelectBox
                    value={searchStatus}
                    options={typeArray}
                    onChange={onSearch}
                    classname={"table-filters-select-starline"}
                    searchVal={true}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {historyData.map((item: any, index: number) => {
                return (
                  <tr style={{ background: getBackgroundStyle(item.status), color: "black" }} key={item._id}>
                    <td>{index + 1}</td>
                    <td>{item.transactionId}</td>
                    <td>{item.roundId}</td>
                    <td>{item.gameId}</td>
                    <td>{item.category}</td>
                    <td>{Math.round(item.amount)}</td>
                    <td>{Math.round(item.wining)}</td>
                    <td>{Math.round(item.rollBackAmount)}</td>
                    <td>{Math.round(item.commissionAmount)}</td>
                    <td>{Math.round(item.amountAfterCommission)}</td>
                    <td>{`${formatDate(item.createdOn)} ${formatedTime(item.createdOn)}`}</td>
                    <td>{item.status === "W" ? "Win" : item.status === "L" ? "Loss" : item.status === "R" ? "Roll Back" : ""}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 20 }}>
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                color="secondary"
                page={currentPage}
                onChange={(Event, New_Page) =>
                  setCurrentPage(New_Page)
                }
              />
            </Stack>
          </div>
        </div>
      </div>
    </>

  )
}

export default QtechHistory