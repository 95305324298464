import { useState, useEffect, useContext } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { API_Endpoint } from "../../Configuration/Settings";
import { User_Context } from "../../Contexts/User";
import { useParams } from "react-router-dom";
import { decryptData } from "../../utils/decryptData";
import { encryptData } from "../../utils/encryptData";

// Components
import Sidenav from "../../Components/SideNavigation/SideNavigation";
import Loader from "../../Components/Loader/Loader";

// Views
import UserReportData from "./Views/UserReportData/UserReportData";
import WalletHistory from "./Views/WalletHistory/WalletHistory";
import GameHistory from "./Views/GameHistory/GameHistory";
import UserReportButtons from "../../Components/UserReportButtons/UserReportButtons";
import Coin from "../../Components/Coins/Coin";
import WacHistory from "./Views/WacHistory/WacHistory";
import ScannerDeposit from "./Views/ScannerDeposit/ScannerDeposit";
import BonusWalletCoins from "../../Components/BonusWalletCoins/BonusWalletCoins";
import StarLineHistory from "./Views/WalletHistory/StarLineHistory";
import KingBazarHistory from "./Views/WalletHistory/KingBazarHistory";
import InstantWorliHistory from "./Views/WalletHistory/InstantWorliHistory";
import QtechHistory from "./Views/WalletHistory/QtechHistory";
import JetFairHistory from "./Views/WalletHistory/JetFairHistory";
import RemoveBonusWalletCoins from "../../Components/BonusWalletCoins/RemoveBonusWalletCoins";

// Styles
import './User_Report.css'
import FalconHistory from "./Views/WalletHistory/FalconHistory";
import FundRequestHistory from "./Views/FundRequestHistory/FundRequestHistory";

interface User_Report_Data {
	_id: string,
	pendingWithdrawals: any[],
	pendingWithdrawal_sum: number,
	profit_and_loss: number,
	exposure: number,
	balance: number,
	betHistory: any[],
	userGgr: number
}

export default function User_Report() {
	const [Financial_Report, Set_Financial_Report] = useState<User_Report_Data>({
		_id: '',
		pendingWithdrawals: [],
		pendingWithdrawal_sum: 0,
		profit_and_loss: 0,
		exposure: 0,
		balance: 0,
		betHistory: [],
		userGgr: 0
	});
	const [loading, setLoading] = useState<boolean>(false);
	const { User_ID, userName } = useParams();

	const { User } = useContext(User_Context)

	const [selectedComponent, setSelectedComponent] = useState('wallet_history')

	// useEffect(() => {
	// 	setLoading(true);
	// 	let config = {
	// 		method: "post",
	// 		maxBodyLength: Infinity,
	// 		url: `${API_Endpoint}/User/userProfitLoss`,
	// 		headers: { Authorization: `Bearer ${User.token}` },
	// 		data: { token: encryptData({ _id: User_ID }) },
	// 	};
	// 	axios.request(config).then((response) => {
	// 		response.data.data = decryptData(response.data.data);
	// 		Set_Financial_Report(response.data.data.payload);
	// 		setLoading(false);
	// 	}).catch((error) => { console.log(error); });
	// }, []);

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<div className="g-sidenav-show  bg-gray-100">
					<ToastContainer autoClose={2000} position="top-center" />
					<Sidenav />
					<main className="main-content position-relative">
						<div style={{ background: "#f8f9fa" }}>
							<div className="container-fluid py-1 px-3">
								<nav aria-label="breadcrumb" className="breadcrumbs">
									<ol className="breadcrumb bg-transparent mb-0 px-0">
										<li className="breadcrumb-item">
											<span className="opacity-5 text-dark">User :</span>
										</li>
										<li className="breadcrumb-item active text-dark" aria-current="page">{userName}</li>
									</ol>
								</nav>
							</div>
							<div className="container-fluid">
								<div className="user-detail-button-container">
									<UserReportButtons title={'Wallet History'} onClick={() => setSelectedComponent('wallet_history')} />
									{/* <UserReportButtons title={'User Report'} onClick={() => setSelectedComponent('user_report')} /> */}
									<UserReportButtons title={'Game History'} onClick={() => setSelectedComponent('game_history')} />
									<UserReportButtons title={'Starline History'} onClick={() => setSelectedComponent('starline_history')} />
									<UserReportButtons title={'King Bazar History'} onClick={() => setSelectedComponent('king_bazar_history')} />
									<UserReportButtons title={'Instant Worli History'} onClick={() => setSelectedComponent('worli_history')} />
									<UserReportButtons title={'Wac History'} onClick={() => setSelectedComponent('wac_history')} />
									<UserReportButtons title={'Qtech History'} onClick={() => setSelectedComponent('qtech_history')} />
									<UserReportButtons title={'JetFair History'} onClick={() => setSelectedComponent('jetfair_history')} />
									{/* <UserReportButtons title={'Fund Request'} /> */}
									{/* <UserReportButtons title={'PNL'} /> */}
									<UserReportButtons title={'falcon History'} onClick={() => setSelectedComponent('falcon_history')} />
									<UserReportButtons title={'Coins'} onClick={() => setSelectedComponent('coins')} />
									{/* <UserReportButtons title={'Scanner Deposit'} onClick={() => setSelectedComponent('scanner_deposit')} /> */}
									{/* <UserReportButtons title={'Exchange History'} /> */}
									{/* <UserReportButtons title={'User Apps'} /> */}
									<UserReportButtons title={'Add Bonus Coins'} onClick={() => setSelectedComponent('add_bonus_coins')} />
									<UserReportButtons title={'Remove Bonus Coins'} onClick={() => setSelectedComponent('remove_bonus_coins')} />
									<UserReportButtons title={'Fund Request'} onClick={() => setSelectedComponent('fund_request')} />


								</div>

								{selectedComponent === "add_bonus_coins" &&
									<BonusWalletCoins />
								}
								{selectedComponent === "remove_bonus_coins" &&
									<RemoveBonusWalletCoins />
								}

								{selectedComponent === "user_report" &&
									<UserReportData
										data={Financial_Report}
									/>
								}
								{selectedComponent === "scanner_deposit" &&
									<ScannerDeposit
									/>
								}
								{selectedComponent === "wac_history" &&
									<WacHistory
									/>
								}
								{selectedComponent === "qtech_history" &&
									<QtechHistory
									/>
								}
								{selectedComponent === "jetfair_history" &&
									<JetFairHistory
									/>
								}
								{selectedComponent === "falcon_history" &&
									<FalconHistory
									/>
								}
								{selectedComponent === "coins" &&
									<Coin />
								}


								{selectedComponent === "wallet_history" &&
									<WalletHistory
										type={'wallet'}
									/>
								}

								{selectedComponent === "game_history" &&
									<GameHistory
									/>
								}

								{selectedComponent === "starline_history" &&
									<StarLineHistory />
								}

								{selectedComponent === "king_bazar_history" &&
									<KingBazarHistory />
								}


								{selectedComponent === "worli_history" &&
									<InstantWorliHistory
									/>
								}
								{
									selectedComponent === "fund_request" &&
									<FundRequestHistory />
								}



								{/* <div className="row">
									<h2>Pending Withdrawals</h2>
									<div className="table-responsive">
										<table className="table table-view">
											<thead>
												<tr>
													<th>Withdrawal ID</th>
													<th>User ID</th>
													<th>Amount</th>
													<th>IFSC</th>
													<th>Mobile</th>
													<th>Account Number</th>
													<th>Account Holder Name</th>
													<th>Transaction ID</th>
													<th>Status</th>
													<th>Created On</th>
													<th>Updated On</th>
												</tr>
											</thead>
											<tbody>
												{Financial_Report.pendingWithdrawals.map(Pending_Withdrawal =>
													<tr key={Pending_Withdrawal._id}>
														<td>{Pending_Withdrawal._id}</td>
														<td>{Pending_Withdrawal.dp_id}</td>
														<td>{Pending_Withdrawal.amount}</td>
														<td>{Pending_Withdrawal.ifscCode}</td>
														<td>{Pending_Withdrawal.mobile}</td>
														<td>{Pending_Withdrawal.accountNo}</td>
														<td>{Pending_Withdrawal.accountHolderName}</td>
														<td>{Pending_Withdrawal.transactionId}</td>
														<td>{Pending_Withdrawal.status}</td>
														<td>{formatDate(Pending_Withdrawal.createdOn)}</td>
														<td>{formatDate(Pending_Withdrawal.updatedOn)}</td>
													</tr>)}
											</tbody>
										</table>
									</div>
								</div>
								<div className="row">
									<h2>Jetfair Bet History</h2>
									<div className="table-responsive">
										<table className="table table-view">
											<thead>
												<tr>
													<th>Bet ID</th>
													<th>Agent</th>
													<th>Client</th>
													<th>Amount</th>
													<th>Currency</th>
													<th>Transaction Type</th>
													<th>Transaction Code</th>
													<th>Transaction ID</th>
													<th>Market ID</th>
													<th>Market Name</th>
													<th>Runner Name</th>
													<th>Rate</th>
													<th>Stake</th>
													<th>Bet Type</th>
													<th>Site Code</th>
													<th>Commission</th>
													<th>Payable amount</th>
													<th>Net Profit & Loss</th>
													<th>Created</th>
													<th>Updated</th>
												</tr>
											</thead>
											<tbody>
												{Financial_Report.betHistory && Financial_Report.betHistory.length > 0 && Financial_Report.betHistory[0].Jetfair.map((Jetfair_Bet: any) =>
													<tr key={Jetfair_Bet._id}>
														<td>{Jetfair_Bet._id}</td>
														<td>{Jetfair_Bet.agentUsername}</td>
														<td>{Jetfair_Bet.clientUsername}</td>
														<td>{Jetfair_Bet.amount}</td>
														<td>{Jetfair_Bet.currency}</td>
														<td>{Jetfair_Bet.transactionType}</td>
														<td>{Jetfair_Bet.transactionCode}</td>
														<td>{Jetfair_Bet.transactionId}</td>
														<td>{Jetfair_Bet.marketId}</td>
														<td>{Jetfair_Bet.marketName}</td>
														<td>{Jetfair_Bet.runnerName}</td>
														<td>{Jetfair_Bet.rate}</td>
														<td>{Jetfair_Bet.stake}</td>
														<td>{Jetfair_Bet.betType}</td>
														<td>{Jetfair_Bet.siteCode}</td>
														<td>{Jetfair_Bet.commissionAmount}</td>
														<td>{Jetfair_Bet.payableAmount}</td>
														<td>{Jetfair_Bet.netpl}</td>
														<td>{`${formatDate(Jetfair_Bet.createdOn)} ${formatedTime(Jetfair_Bet.createdOn)}`}</td>
														<td>{`${formatDate(Jetfair_Bet.updatedOn)} ${formatedTime(Jetfair_Bet.updatedOn)}`}</td>
													</tr>)}
											</tbody>
										</table>
									</div>
								</div>
								<div className="row">
									<h2>Satta Matka Bet History</h2>
									<div className="table-responsive">
										<table className="table table-view">
											<thead>
												<tr>
													<th>Bet ID</th>
													<th>Market Code</th>
													<th>Bazar Name</th>
													<th>Transaction ID</th>
													<th>Game Name</th>
													<th>Result Date</th>
													<th>Point</th>
													<th>Game Number</th>
													<th>Customer ID</th>
													<th>Status</th>
													<th>Bazar ID</th>
													<th>Game ID</th>
													<th>Created</th>
												</tr>
											</thead>
											<tbody>
												{Financial_Report.betHistory && Financial_Report.betHistory.length > 0 && Financial_Report.betHistory[0].SattaMatka.map((SattaMatka_Bet: any) =>
													<tr key={SattaMatka_Bet._id}>
														<td>{SattaMatka_Bet._id}</td>
														<td>{SattaMatka_Bet.market_code}</td>
														<td>{SattaMatka_Bet.bazar_name}</td>
														<td>{SattaMatka_Bet.transaction_id}</td>
														<td>{SattaMatka_Bet.game_name}</td>
														<td>{SattaMatka_Bet.result_date}</td>
														<td>{SattaMatka_Bet.point}</td>
														<td>{SattaMatka_Bet.game}</td>
														<td>{SattaMatka_Bet.customer_id}</td>
														<td>{SattaMatka_Bet.status}</td>
														<td>{SattaMatka_Bet.bazar_id}</td>
														<td>{SattaMatka_Bet.game_id}</td>
														<td>{SattaMatka_Bet.created}</td>
													</tr>)}
											</tbody>
										</table>
									</div>
								</div>
								<div className="row">
									<h2>Casino Bet History</h2>
									<div className="table-responsive">
										<table className="table table-view">
											<thead>
												<tr>
													<th>Bet ID</th>
													<th>User ID</th>
													<th>Game Description</th>
													<th>Status</th>
													<th>Transaction ID</th>
													<th>Round ID</th>
													<th>Action</th>
													<th>Currency</th>
													<th>Opening Balance</th>
													<th>Amount</th>
													<th>Closing Balance</th>
													<th>Created</th>
													<th>Updated</th>
												</tr>
											</thead>
											<tbody>
												{Financial_Report.betHistory && Financial_Report.betHistory.length > 0 && Financial_Report.betHistory[0].Wacs.map((Wacs_Bet: any) =>
													<tr key={Wacs_Bet._id}>
														<td>{Wacs_Bet._id}</td>
														<td>{Wacs_Bet.userId}</td>
														<td>{Wacs_Bet.gameReference}</td>
														<td>{Wacs_Bet.status}</td>
														<td>{Wacs_Bet.transactionId}</td>
														<td>{Wacs_Bet.roundId}</td>
														<td>{Wacs_Bet.action}</td>
														<td>{`INR`}</td>
														<td>{Wacs_Bet.openingBalance}</td>
														<td>{Wacs_Bet.amount}</td>
														<td>{Wacs_Bet.closingBalance}</td>
														<td>{`${formatDate(Wacs_Bet.createdOn)} ${formatedTime(Wacs_Bet.createdOn)}`}</td>
														<td>{`${formatDate(Wacs_Bet.updatedOn)} ${formatedTime(Wacs_Bet.updatedOn)}`}</td>
													</tr>)}
											</tbody>
										</table>
									</div>
								</div> */}
							</div>
							<footer className="footer">
								<div className="container-fluid">
									<div className="row align-items-center justify-content-lg-between">
										<div className="col-lg-6 mb-lg-0  ">
											<div className="copyright text-center text-sm text-muted text-lg-start">
												© fairbets.co
											</div>
										</div>
										<div className="col-lg-6">
											<ul className="nav nav-footer justify-content-center justify-content-lg-end">
												<li className="nav-item">
													<a className="nav-link text-muted" target="_blank">
														Home
													</a>
												</li>
												<li className="nav-item">
													<a className="nav-link text-muted" target="_blank">
														About Us
													</a>
												</li>
												<li className="nav-item">
													<a className="nav-link text-muted" target="_blank">
														Blog
													</a>
												</li>
												<li className="nav-item">
													<a
														className="nav-link pe-0 text-muted"
														target="_blank"
													>
														License
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</footer>
						</div>
					</main>
				</div>
			)}
		</>
	);
}