import React, { useState, useEffect, useContext, ChangeEvent, FormEvent } from 'react'
import Sidenav from '../../../../Components/SideNavigation/SideNavigation'
import Breadcrumbs from '../../../../Components/Breadcrumbs/Breadcrumbs'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";
import axios from 'axios'
import { API_Endpoint } from '../../../../Configuration/Settings'
import { encryptData } from '../../../../utils/encryptData';
import { decryptData } from '../../../../utils/decryptData';
import { Button } from '@mui/material'
import { User_Context } from '../../../../Contexts/User';
import Loader from '../../../../Components/Loader/Loader';
import { ToastContainer, toast } from "react-toastify";
import Reusable_Input from '../../../../Components/InputField/InputField';
import { faTrashCan, faPencilSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Data {
    name: string;
    link: string;
    _id: string;
}
function SocialMedia() {
    const [openPopup, setOpenPopup] = useState(false);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState<boolean>(false);
    const [nameHelperText, setNameHelperText] = useState<string>("");
    const [editName, setEditName] = useState("");
    const [editNameError, setEditNameError] = useState<boolean>(false);
    const [editNameHelperText, setEditNameHelperText] = useState<string>("");
    const [link, setLink] = useState("");
    const [linkError, setLinkError] = useState<boolean>(false);
    const [linkHelperText, setLinkHelperText] = useState<string>("");
    const [editLink, setEditLink] = useState("");
    const [editLinkError, setEditLinkError] = useState<boolean>(false);
    const [editLinkHelperText, setEditLinkHelperText] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [id, setId] = useState<string>("");
    const [editId, setEditId] = useState<string>("");
    const [editPopup, setEditPopup] = useState<boolean>(false);
    const { User } = useContext(User_Context)
    const [socialMediaData, setSocialMediaData] = useState<Array<Data>>([])


    const getAllData = () => {
        setLoading(true)
        axios.request({
            method: "post",
            url: `${API_Endpoint}/socialMedia/getAll`,
            headers: { Authorization: `Bearer ${User.token}` },
            data: { token: encryptData({}) },
        }).then((response) => {
            response.data.data = decryptData(response.data.data)
            setSocialMediaData(response.data.data.payload)
            setLoading(false)
        }).catch((error) => {
            toast.error(error.message)
            setLoading(false)
        });
    }

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (!name) {
            setNameError(true);
            setNameHelperText("Enter Name");
        } else if (!link) {
            setLinkError(true);
            setLinkHelperText("Enter Link");
        } else {
            setOpenPopup(false);
            setLoading(true);
            const token = localStorage.getItem("token");
            axios
                .post(
                    `${API_Endpoint}/socialMedia`,
                    {
                        token: encryptData({
                            name: name,
                            link: link,
                        })
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response) => {
                    setName("");
                    setLink("");
                    getAllData()
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    };
    const handleUpdate = (event: FormEvent) => {
        event.preventDefault();
        if (!editName) {
            setEditNameError(true);
            setEditNameHelperText("Enter Name");
        } else if (!editLink) {
            setEditLinkError(true);
            setEditLinkHelperText("Enter Link");
        } else {
            setEditPopup(false);
            setLoading(true);
            const token = localStorage.getItem("token");
            const data = {
                name: editName,
                link: editLink,
                _id: editId,
            }
            axios
                .post(
                    `${API_Endpoint}/socialMedia/update`,
                    {
                        token: encryptData(data)
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response) => {
                    setSocialMediaData(
                        socialMediaData.map(element => {
                        if (element._id == editId) {
                            element.name = editName;
                            element.link = editLink
                        }
                        return element
                    })
                    )
                    setEditName("");
                    setEditLink("");
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    };

    const handleName = (e: ChangeEvent<HTMLInputElement>) => {
        setNameError(false);
        setName(e.target.value);
    };

    const handleLink = (e: ChangeEvent<HTMLInputElement>) => {
        setLinkError(false);
        setLink(e.target.value);
    };
    const handleEditName = (e: ChangeEvent<HTMLInputElement>) => {
        setEditNameError(false);
        setEditName(e.target.value);
    };

    const handleEditLink = (e: ChangeEvent<HTMLInputElement>) => {
        setEditLinkError(false);
        setEditLink(e.target.value);
    };

    const openDeletePopup = (id: string) => {
        setDeletePopup(true);
        setId(id);
    };

    const openEditPopup = (item: any) => {
        setEditPopup(true);
        setEditName(item.name)
        setEditLink(item.link)
        setEditId(item._id)
    };

    const deleteMedia = () => {
        setLoading(true);
        setDeletePopup(false);
        const token = localStorage.getItem("token");
        axios
            .post(`${API_Endpoint}/socialMedia/delete`, { token: encryptData({ _id: id }) }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(() => {
                const updatedUpiData = socialMediaData.filter((item) => item._id !== id);
                setSocialMediaData(updatedUpiData);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log("error", error);
            });
    };

    useEffect(() => {
        getAllData()
    }, [])
    
    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className="g-sidenav-show  bg-gray-100">
                    <ToastContainer autoClose={2000} position="top-center" />
                    <Sidenav />
                    <main className="main-content position-relative">
                        <div style={{ background: "#f8f9fa" }}>
                            <Breadcrumbs tab={"Social Media"} button={<Button
                                onClick={() => setOpenPopup(true)}
                                className="btn-payment"
                                variant="contained"
                            >
                                Add
                            </Button>} />
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="table-responsive">
                                            <table className="table table-view">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Name</th>
                                                        <th>Link</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {socialMediaData.map((item, index) => (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.link}</td>
                                                            <td>
                                                                <FontAwesomeIcon
                                                                    id={item._id}
                                                                    className="fa fa-pencil-square icon-home icon-trash "
                                                                    icon={faTrashCan}
                                                                    onClick={() => openDeletePopup(item._id)}
                                                                    style={{ cursor: "pointer" }}
                                                                />
                                                                <FontAwesomeIcon
                                                                    id={item._id}
                                                                    className="fa fa-pencil-square icon-home icon-trash "
                                                                    icon={faPencilSquare}
                                                                    onClick={() => openEditPopup(item)}
                                                                    style={{ cursor: "pointer" }}
                                                                />
                                                            </td>
                                                        </tr>))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
                                    <DialogContent>
                                        <form onSubmit={handleSubmit}>
                                            <div>
                                                <Reusable_Input type={"text"} label={"Name"} fullWidth={true} value={name} error={nameError} helperText={nameHelperText} onChange={handleName} />
                                            </div>

                                            <div className="mt-3">
                                                <Reusable_Input type={"text"} label={"Link"} fullWidth={true} value={link} error={linkError} helperText={linkHelperText} onChange={handleLink} />
                                            </div>

                                            <DialogActions className='mt-2'>
                                                <Button
                                                    className="btn-popup"
                                                    variant="outlined"
                                                    onClick={() => setOpenPopup(false)}
                                                    color="primary"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    className="btn-popup"
                                                    variant="outlined"
                                                    type="submit"
                                                    color="primary"
                                                >
                                                    Submit
                                                </Button>
                                            </DialogActions>
                                        </form>
                                    </DialogContent>
                                </Dialog>
                            </div>
                            <div>
                                <Dialog open={deletePopup} onClose={() => setDeletePopup(false)}>
                                    <DialogTitle>Are You Sure ?</DialogTitle>
                                    <DialogContent>
                                        <DialogActions className="mt-n3">
                                            <Button
                                                className="btn-popup"
                                                variant="outlined"
                                                onClick={() => setDeletePopup(false)}
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className="btn-popup"
                                                variant="outlined"
                                                type="submit"
                                                color="primary"
                                                onClick={() => deleteMedia()}
                                            >
                                                Delete
                                            </Button>
                                        </DialogActions>
                                    </DialogContent>
                                </Dialog>
                            </div>
                            <div>
                                <Dialog open={editPopup} onClose={() => setEditPopup(false)}>
                                    <DialogContent>
                                        <form onSubmit={handleUpdate}>
                                            <div>
                                                <Reusable_Input type={"text"} label={"Name"} fullWidth={true} value={editName} error={editNameError} helperText={editNameHelperText} onChange={handleEditName} />
                                            </div>

                                            <div className="mt-3">
                                                <Reusable_Input type={"text"} label={"Link"} fullWidth={true} value={editLink} error={editLinkError} helperText={editLinkHelperText} onChange={handleEditLink} />
                                            </div>

                                            <DialogActions className='mt-2'>
                                                <Button
                                                    className="btn-popup"
                                                    variant="outlined"
                                                    onClick={() => setEditPopup(false)}
                                                    color="primary"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    className="btn-popup"
                                                    variant="outlined"
                                                    type="submit"
                                                    color="primary"
                                                >
                                                    Submit
                                                </Button>
                                            </DialogActions>
                                        </form>
                                    </DialogContent>
                                </Dialog>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </>

    )
}

export default SocialMedia